import React, { useState } from "react";
import { Box, Typography, Button, Grid2 } from "@mui/material";
import { styled } from "@mui/system";

const StyledButton = styled(Button)({
  "width": "180px",
  "height": "40px",
  "backgroundColor": "#368137",
  "borderRadius": "12px",
  "color": "#FFFFFF",
  "fontWeight": "600",
  "textTransform": "capitalize",
  "&:hover": {
    backgroundColor: "#285527",
  },
});

const PrivacyPolicy = () => {
  const [language, setLanguage] = useState("mn"); // Default to Mongolian

  const content = {
    mn: {
      title: "RE+ Апп-ын Нууцлалын Бодлого",
      text: "Энэхүү нууцлалын бодлого нь RE+ апп-ыг ашиглаж буй хэрэглэгчдийн хувийн мэдээллийг хэрхэн цуглуулж, ашиглаж, хамгаалж байгааг тайлбарлах зорилготой. Бид таны хувийн мэдээллийг хууль ёсны дагуу, найдвартай хамгаалж, зөвхөн заасан зорилгод ашиглана.",
      sections: [
        {
          heading: "1. Цуглуулж буй мэдээлэл",
          text: "Бид дараах төрлийн мэдээллийг цуглуулж болно:<br>- Хувийн мэдээлэл: Овог нэр, утасны дугаар, имэйл хаяг, байршлын мэдээлэл.<br>- Апп ашиглалтын мэдээлэл: Төхөөрөмжийн загвар, үйлдлийн систем, апп-д хандсан огноо, цаг.<br>- Хог хаягдлын мэдээлэл: Таны өгсөн дахивар материалыг төрөл, хэмжээ.",
        },
        {
          heading: "2. Мэдээллийг хэрхэн ашиглах вэ?",
          text: "Цуглуулсан мэдээллийг дараах зорилгоор ашиглана:<br>- Үйлчилгээг үзүүлэх, сайжруулах.<br>- Таны захиалгыг хүлээн авч, дахивар тээвэрлэлтийг зохицуулах.<br>- Хэрэглэгчдэд мэдэгдэл болон сурталчилгаа илгээх.<br>- Апп-ын гүйцэтгэл, найдвартай ажиллагааг үнэлэх, сайжруулах.",
        },
        {
          heading: "3. Мэдээллийг хамгаалах",
          text: "Бид таны мэдээллийг хамгаалах зорилгоор:<br>- Орчин үеийн шифрлэлт, мэдээллийн хамгаалалтын технологи ашиглана.<br>- Зөвшөөрөлгүй хандалтаас сэргийлэх, мэдээллийг хянах журмыг мөрдөнө.",
        },
        {
          heading: "4. Мэдээллийг гуравдагч этгээдэд дамжуулах",
          text: "Бид таны мэдээллийг зөвшөөрөлгүйгээр гуравдагч этгээдэд дамжуулахгүй. Гэхдээ дараах нөхцөлд дамжуулж болно:<br>- Хууль тогтоомжийн шаардлагаар.<br>- Үйлчилгээг үзүүлэхэд шаардлагатай гэрээт байгууллагад (жишээ нь: тээврийн үйлчилгээ үзүүлэгч).",
        },
        {
          heading: "5. Хэрэглэгчийн эрх",
          text: "Та өөрийн мэдээлэлд дараах эрхтэй:<br>- Хувийн мэдээллээ шинэчлэх, засварлах.<br>- Мэдээллээ устгуулахыг хүсэх.<br>- Нууцлалын бодлогын талаар тодруулга авах.",
        },
        {
          heading: "6. Нууцлалын бодлогын өөрчлөлт",
          text: "Бид энэхүү нууцлалын бодлогод шаардлагатай үед өөрчлөлт оруулах эрхтэй. Өөрчлөлт орсон тохиолдолд апп-аар дамжуулан урьдчилан мэдээлнэ.",
        },
        {
          heading: "7. Холбоо барих",
          text: "Хэрэв танд нууцлалын бодлоготой холбоотой асуулт, санал байвал дараах хаягаар холбогдоно уу:<br>- Имэйл: info@replus.mn<br>- Утас: +976-77740555<br><br>",
        },
      ],
    },
    en: {
      title: "RE+ App Privacy Policy",
      text: "This Privacy Policy explains how the RE+ app collects, uses, and protects the personal information of its users. We are committed to safeguarding your personal information lawfully, securely, and using it solely for the purposes specified.",
      sections: [
        {
          heading: "1. Information We Collect",
          text: "We may collect the following types of information:<br>- Personal Information: Name, phone number, email address, location information.<br>- App Usage Information: Device model, operating system, date and time of app access.<br>- Waste Information: Type and amount of recyclable materials you provide.",
        },
        {
          heading: "2. How We Use Your Information",
          text: "The collected information is used for the following purposes:<br>- To provide and improve our services.<br>- To process your orders and manage recyclable transportation.<br>- To send notifications and promotional messages to users.<br>- To evaluate and enhance app performance and reliability.",
        },
        {
          heading: "3. How We Protect Your Information",
          text: "To protect your information, we:<br>- Use modern encryption and data protection technologies.<br>- Implement policies to prevent unauthorized access and monitor information usage.",
        },
        {
          heading: "4. Sharing Information with Third Parties",
          text: "We do not share your information with third parties without your consent. However, it may be shared under the following conditions:<br>- As required by law.<br>- With contracted organizations necessary to provide services (e.g., transportation providers).",
        },
        {
          heading: "5. User Rights",
          text: "You have the following rights regarding your information:<br>- To update and correct your personal information.<br>- To request the deletion of your information.<br>- To inquire about this privacy policy.",
        },
        {
          heading: "6. Changes to the Privacy Policy",
          text: "We reserve the right to make changes to this privacy policy as necessary. Any updates will be communicated through the app in advance.",
        },
        {
          heading: "7. Contact Us",
          text: "If you have questions or feedback about this privacy policy, please contact us at:<br>- Email: info@replus.mn<br>- Phone: +976-77740555<br><br>",
        },
      ],
    },
  };

  const switchLanguage = () => {
    setLanguage((prev) => (prev === "mn" ? "en" : "mn"));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "flex-start", // Align content from the top
        alignItems: "center",
        padding: "24px",
      }}
    >
      {/* Header with Logo */}
      <Box
        sx={{
          width: "100%",
          maxWidth: "800px",
          paddingBottom: "24px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Replace with your logo image */}
        <img src="/images/logo.svg" alt="RE+ Logo" style={{ height: "50px" }} />

        {/* Language switch button */}
        <StyledButton onClick={switchLanguage}>{language === "mn" ? "Switch to English" : "Монгол хэл рүү буцах"}</StyledButton>
      </Box>

      {/* Privacy Policy Content */}
      <Box
        sx={{
          width: "100%",
          maxWidth: "800px",
          padding: "48px",
          borderRadius: "24px",
          border: "solid 1px #A9DBAA",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "800",
            color: "#153216",
            textAlign: "center",
            marginBottom: "24px",
          }}
        >
          {content[language].title}
        </Typography>
        <Typography variant="body1" sx={{ color: "#153216" }} dangerouslySetInnerHTML={{ __html: content[language].text }} />
        <br />
        {content[language].sections.map((section, index) => (
          <Box key={index} sx={{ marginBottom: "16px" }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", color: "#368137" }}>
              {section.heading}
            </Typography>
            <Typography variant="body1" sx={{ color: "#153216" }} dangerouslySetInnerHTML={{ __html: section.text }} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
