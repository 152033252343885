import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
// import { makeStyles } from "@mui/styles";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Pagination } from "@mui/material";
import { FormInputDropdown } from "../../form/form-input-dropdown";

import { useForm } from "react-hook-form";
import { descFilter, getColorByFeedbackStatus } from "../../../utils/method";
import { StatusSpan } from "../../form/span";
import { FeedbackStatusSpan } from "../../form/span/FeedbackStatusSpan";

import { BasicButton } from "../../form/buttons/basic-button";
import { ToggleButton } from "../../form/buttons/toggle-button";
import { tableBodyCellStyle, tableHeaderStyle } from "../../../utils/styleMethod";
import { mainApi } from "../../../api/main";

import NewsDetail from "../../../pages/news/detail";

export const FeedbackTable = ({ data, query, setQuery, pageInfo, setPageInfo, onDetailClick }) => {
  const navigate = useNavigate();
  const { control } = useForm({});

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const classessCell = tableBodyCellStyle();
  const classessHeader = tableHeaderStyle();
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [asignModalOpen, setAsignModalOpen] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [filteredFactoryDrivers, setFilteredFactoryDrivers] = useState([]);

  // Pagination handlers
  const handleChangePage = (event, newPage) => setPageInfo({ ...pageInfo, page: newPage });

  const onChangeSort = (values) => setQuery({ ...query, sort: values });

  const handleButtonClick = (row) => {
    navigate(`/news/detail/${row.id}`);
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        padding: "24px 32px 24px 32px",
        marginTop: "24px",
        marginBottom: "64px",
        boxShadow: "none",
        borderRadius: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "36px",
        }}
      >
        <p className="HeadlineHeadline---Small" style={{ color: "var(--on-surface-high)" }}>
          Санал хүсэлт <span> | </span> <span className="BodyBody---Medium"> Нийт {query.total} санал хүсэлт байна</span>{" "}
        </p>
        <FormInputDropdown
          control={control}
          name={`asc`}
          options={descFilter}
          sx={{ minWidth: "250px", marginRight: "12px" }}
          onChange={(selectedValue) => onChangeSort(selectedValue)}
        />
      </Box>
      <Table aria-label="order table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
            {["Огноо", "Илгээгч", "Гомдлын төрөл", "Нэмэлт мэдээлэл", "Төлөв"].map((header, index) => (
              <TableCell key={index} className={classessHeader.tableHeader}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={row.callId || index} className={index % 2 === 1 ? "table-row-odd" : ""}>
              <TableCell className={classessCell.tableBodyCell}>{row.sendDateStr}</TableCell>
              <TableCell className={classessCell.tableBodyCell}>{row.userName}</TableCell>
              <TableCell className={classessCell.tableBodyCell}>{row.feedbackTypeName}</TableCell>
              <TableCell className={classessCell.tableBodyCell}>{row.description}</TableCell>
              <TableCell className={classessCell.tableBodyCell}>
                <FeedbackStatusSpan
                  label={row.statusName}
                  statusColor={getColorByFeedbackStatus(row.statusId)}
                  handleClick={() => {
                    onDetailClick(row);
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* Custom Pagination */}
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={Math.ceil(query.total / pageInfo.limit)}
          page={pageInfo.page}
          onChange={handleChangePage}
          siblingCount={1}
          boundaryCount={1}
        />
      </Box>
    </TableContainer>
  );
};
