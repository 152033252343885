import { useState } from "react";
import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { DotsThreeVertical, UserMinus, UserPlus } from "@phosphor-icons/react";
import {
    BodyBodyMedium,
    HeadlineHeadlineSmall,
    tableBodyCellStyle,
    tableHeaderStyle,
} from "../../utils/styleMethod";
import AddEmployeeModal from "../modals/AddEmployee";
import AddOrRemoveModal from "../modals/AddOrRemoveEmployee";

const TABLE_HEADERS = [
    "Зэрэг",
    "Бүртгэлтэй дугаар",
    "Бүргтэгдсэн огноо",
    "Сүүлд нэвтрэсэн",
    "Түүх харах",
];

export default function UserList({ data, type, organizationId ,findByEnterpriseId}) {
    const classesCell = tableBodyCellStyle();
    const classesHeader = tableHeaderStyle();
    const [anchorElUsers, setAnchorElUsers] = useState(null);
    const [open, setOpen] = useState(false);
    const [removeEmployeeModal, setRemoveEmployeeModal] = useState(false);

    const handleMenuOpen = (event) => {
        setAnchorElUsers(event.currentTarget);
    };

    const handleMenuClose = (action) => {
        setRemoveEmployeeModal(true);
        setAnchorElUsers(null);
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <>
            <TableContainer
                component={Paper}
                sx={{
                    padding: "24px 32px",
                    marginTop: "24px",
                    marginBottom: "24px",
                    boxShadow: "none",
                    borderRadius: "12px",
                }}
            >
                <Box
                    display="flex"
                    justifyContent={"space-between"}
                    alignItems="center"
                    sx={{ paddingBottom: "24px" }}
                >
                    <HeadlineHeadlineSmall color="var(--on-surface-high)">
                        Бүртгэлтэй ажилтан
                    </HeadlineHeadlineSmall>
                    <IconButton
                        onClick={handleMenuOpen}
                        sx={{ "&:hover": { color: "#4CAF50" } }}
                    >
                        <DotsThreeVertical size={24} weight="bold" />
                    </IconButton>
                </Box>

                <Table aria-label="user list table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                            {TABLE_HEADERS.map((header) => (
                                <TableCell
                                    key={header}
                                    className={classesHeader.tableHeader}
                                >
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow
                                key={index}
                                className={
                                    row.id % 2 === 1 ? "table-row-odd" : ""
                                }
                            >
                                <TableCell
                                    className={classesCell.tableBodyCell}
                                >
                                    {row.role}
                                </TableCell>
                                <TableCell
                                    className={classesCell.tableBodyCell}
                                >
                                    {row.phoneNumber}
                                </TableCell>
                                <TableCell
                                    className={classesCell.tableBodyCell}
                                >
                                    {row.createdDate}
                                </TableCell>
                                <TableCell
                                    className={classesCell.tableBodyCell}
                                >
                                    {row.lastLoginDate}
                                </TableCell>
                                <TableCell
                                    className={classesCell.tableBodyCell}
                                >
                                    дэлгэрэнгүй
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Menu
                anchorEl={anchorElUsers}
                open={Boolean(anchorElUsers)}
                // onClose={() => handleMenuClose(null)}
            >
                <MenuItem onClick={() =>{ handleOpen(); setAnchorElUsers(false)}}>
                    <UserMinus
                        style={{ marginRight: "12px" }}
                        color="var(--on-surface-high)"
                        size={16}
                        weight="light"
                    />
                    <BodyBodyMedium color="var(--on-surface-high)" >
                        Ажилтан нэмэх
                    </BodyBodyMedium>
                </MenuItem>

                <MenuItem onClick={() => handleMenuClose("remove")}>
                    <UserPlus
                        style={{ marginRight: "12px" }}
                        color="var(--on-surface-high)"
                        size={16}
                        weight="light"
                    />
                    <BodyBodyMedium color="var(--on-surface-high)">
                        Ажилтан хасах
                    </BodyBodyMedium>
                </MenuItem>
            </Menu>

            {open ? (
                <AddEmployeeModal
                    open={open}
                    handleClose={handleClose}
                    type={type}
                    organizationId={organizationId}
                    addToList={findByEnterpriseId}
                />
            ) : null}

            {removeEmployeeModal ? (
                <AddOrRemoveModal
                    open={removeEmployeeModal}
                    handleClose={() => setRemoveEmployeeModal(false)}
                    organizationId={organizationId}
                    title={false ? "Ажилтан нэмэх" : "Ажилтан хасах"}
                    buttonText={false ? "Системд нэмэх" : "Системээс хасах"}
                    setEmployeeUser={null}
                    type={type}
                    removeFromList={findByEnterpriseId}
                />
            ) : null}
        </>
    );
}
