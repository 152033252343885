import React, { useState } from "react";
import { Box, Typography, Button, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { useForm, Controller } from "react-hook-form";
import SuccessModal from "../components/modals/Success";

const StyledButton = styled(Button)({
  "width": "180px",
  "height": "40px",
  "backgroundColor": "#368137",
  "borderRadius": "12px",
  "color": "#FFFFFF",
  "fontWeight": "600",
  "textTransform": "capitalize",
  "&:hover": {
    backgroundColor: "#285527",
  },
});

const UserDeletion = () => {
  const [language, setLanguage] = useState("mn"); // Default to Mongolian
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const content = {
    mn: {
      title: "Хэрэглэгчийн Мэдээлэл Устгах",
      instruction: "Хэрэглэгчийн бүртгэл болон өгөгдлийг устгах хүсэлтийг доорх маягтаар илгээнэ үү.",
      emailLabel: "Утасны дугаар эсвэл имэйл хаяг",
      reasonLabel: "Устгах шалтгаан",
      submitButton: "Хүсэлт илгээх",
      successText: "Таны мэдээллийг хүлээж авлаа. Бид ажлын 7-10 хоногт таны мэдээллийг устгах болно.",
      successButtonText: "Ойлголоо",
      switchLanguage: "Switch to English",
      emailError: "Имэйл эсвэл утасны дугаар заавал шаардлагатай.",
      reasonError: "Шалтгаанаа оруулна уу.",
    },
    en: {
      title: "Delete User Data",
      instruction: "Please use the form below to request the deletion of your account and data.",
      emailLabel: "Phone number or email address",
      reasonLabel: "Reason for Deletion",
      submitButton: "Submit Request",
      successText: "We have received your information. We will delete your data within 7-10 business days.",
      successButtonText: "Got it",
      switchLanguage: "Монгол хэл рүү буцах",
      emailError: "Email or phone number is required.",
      reasonError: "Please provide a reason.",
    },
  };

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    setOpenSuccessModal(true);
    reset(); // Reset the form after submission
  };

  const switchLanguage = () => {
    setLanguage((prev) => (prev === "mn" ? "en" : "mn"));
  };

  const closeSuccessModal = () => setOpenSuccessModal(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "24px",
      }}
    >
      {/* Header with Logo */}
      <Box
        sx={{
          width: "100%",
          maxWidth: "800px",
          paddingBottom: "24px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <img src="/images/logo.svg" alt="RE+ Logo" style={{ height: "50px" }} />

        {/* Language switch button */}
        <StyledButton onClick={switchLanguage}>{content[language].switchLanguage}</StyledButton>
      </Box>

      {/* User Deletion Content */}
      <Box
        sx={{
          width: "100%",
          maxWidth: "800px",
          padding: "48px",
          borderRadius: "24px",
          border: "solid 1px #A9DBAA",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "800",
            color: "#153216",
            textAlign: "center",
            marginBottom: "24px",
          }}
        >
          {content[language].title}
        </Typography>
        <Typography variant="body1" sx={{ color: "#153216", marginBottom: "24px" }}>
          {content[language].instruction}
        </Typography>

        {/* Form */}
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
          noValidate
        >
          {/* Email Field */}
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label={content[language].emailLabel}
                variant="outlined"
                fullWidth
                error={!!errors.email}
                helperText={errors.email && content[language].emailError}
                InputProps={{ sx: { height: "45px" } }}
              />
            )}
          />

          {/* Reason Field */}
          <Controller
            name="reason"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label={content[language].reasonLabel}
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                error={!!errors.reason}
                helperText={errors.reason && content[language].reasonError}
              />
            )}
          />

          {/* Submit Button */}
          <StyledButton type="submit">{content[language].submitButton}</StyledButton>
        </Box>
      </Box>

      {/* Success Modal */}
      <SuccessModal
        title=""
        content={content[language].successText}
        open={openSuccessModal}
        handleClose={closeSuccessModal}
        buttonText={content[language].successButtonText}
      />
    </Box>
  );
};

export default UserDeletion;
