
import { UserContext } from "../../../context/user";

import React, { useContext, useEffect, useState } from "react";
import { Box, Grid2 } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { mainApi } from "../../../api/main";
import { toast } from "react-toastify";
import { rows } from "../../../utils/method";
import OrganizationDetail from "../../../components/org-detail";
import UserList from "../../../components/user-list";
import { HeadlineHeadlineMedium } from "../../../utils/styleMethod";
const Prepare = () => {
  const { user, setUser } = useContext(UserContext);
  // const { id } = useParams();
  const id = 4
  const [dropOffData, setDropOffData] = useState({});
  const [filteredDropOffUsers, setFilteredDropOffUsers] = useState([]);

  useEffect(() => {
    if (id) {
      findByDropOffId();
    }
  }, []);

  const findByDropOffId = async () => {
    try {
      const res = await mainApi(`organization/getById/${id}`, "GET", null);
      setDropOffData(res);
      const dropOffusers = res.users.filter(
        (user) => user.role === "DropOffCenterUser"
      );
      setFilteredDropOffUsers(dropOffusers);
    } catch (error) {
      toast.warning("Өгөгдөл олдсонгүй");
    }
  };
  return (
    <Grid2>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginBottom: "12px",
      }}
    >
      <HeadlineHeadlineMedium color="var(--on-surface-high)">
        Цэгийн дэлгэрэнгүй
      </HeadlineHeadlineMedium>
    </Box>
    {id ? <OrganizationDetail data={dropOffData} /> : "lodading.."}
    {id ? <UserList type={'DropOff'} data={filteredDropOffUsers} organizationId = {dropOffData.organizationId} /> : "loading..."}
  </Grid2>
  );
};

export default Prepare;
