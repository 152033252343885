import { useEffect, useState } from "react";
import "./styles.css";
import { BodyBodySmall } from "../../utils/styleMethod";
import { CloudArrowUp, Trash, TrashSimple, Image, Play } from "@phosphor-icons/react";
import { mainApi } from "../../api/main";
import { Box, Typography } from "@mui/material";

const VideoUpload = ({ id, setVideoUrl, type, suggestText, videoUrl, onChange, showError }) => {
  const [video, setVideo] = useState(null);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const allowedVideoTypes = ["video/mp4", "video/avi", "video/mkv", "video/webm", "video/mov"];
  const maxFileSize = 150 * 1024 * 1024; // 150MB limit for videos

  useEffect(() => {
    if (videoUrl) {
      setVideo(videoUrl);
    }
  }, [videoUrl]);

  useEffect(() => {
    if (showError && showError?.isError && showError?.message) {
      setIsError(true);
      setErrorMsg(showError.message);
    }
  }, [showError]);

  // Video upload handler
  const handleVideoUpload = async (file) => {
    if (!file) return;

    if (!allowedVideoTypes.includes(file.type)) {
      setIsError(true);
      setErrorMsg("Only MP4, AVI, MKV, WebM, and MOV videos are allowed.");
      return;
    }

    if (file.size > maxFileSize) {
      setIsError(true);
      setErrorMsg("File size exceeds 50MB limit.");
      return;
    }

    setIsError(false);
    setIsUploading(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("videoType", type || "Reels");

    try {
      const response = await mainApi("image/upload/video", "POST", formData);
      if (response.statusCode === 200) {
        setVideo(response.data);
        if (setVideoUrl) setVideoUrl(response.data);
        if (onChange) onChange(response.data, id);
      }
    } catch (error) {
      console.error("Video upload failed: ", error);
      setIsError(true);
      setErrorMsg("Failed to upload video. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  // Handle file change events
  const handleVideoFileChange = (e) => {
    const selectedFile = e.target.files[0];
    handleVideoUpload(selectedFile);
  };

  // Delete video or background image
  const handleDelete = (e, type) => {
    e.preventDefault();
    if (type === "video") {
      setVideo(null);
      if (setVideoUrl) setVideoUrl(null);
    }
    if (onChange) onChange(null, id);
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Prevent default behavior to allow drop
    setIsDragging(true); // Set the dragging state to true to trigger drag styles
  };

  // Handle when the dragged file leaves the drop area
  const handleDragLeave = () => {
    setIsDragging(false); // Set the dragging state to false to reset the styles
  };

  // Handle the file drop event
  const handleDrop = (e) => {
    e.preventDefault(); // Prevent default behavior to allow drop
    setIsDragging(false); // Reset dragging state when file is dropped

    const droppedFile = e.dataTransfer.files[0]; // Get the first dropped file

    // Check if the dropped file is a video or image based on the MIME type
    if (droppedFile.type.startsWith("video/")) {
      handleVideoUpload(droppedFile); // Handle video upload
    } else {
      setIsError(true);
      setErrorMsg("Invalid file type. Only video or image files are allowed.");
    }
  };

  return (
    <div className="video-container">
      {video ? (
        <div className="video-box">
          <div className="video-preview-wrapper">
            <video className="preview-video" controls>
              <source src={video} type="video/mp4" />
            </video>
          </div>
        </div>
      ) : (
        <label
          className={`video-box ${isDragging ? "dragging" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            document.getElementById("videoupload-" + id).click();
          }}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div className="video-upload-prompt">
            <CloudArrowUp size={32} />
            <p>Drag and drop your video here</p>
          </div>
        </label>
      )}

      {/* Info text */}
      <div className="video-info-text">
        <BodyBodySmall color="var(--color-neutral-50)">Max video size: 150MB</BodyBodySmall>
        <BodyBodySmall color="var(--color-neutral-50)">{suggestText ?? "Recommended video format: MP4"}</BodyBodySmall>

        <button
          className="video-delete-button"
          onClick={(e) => {
            handleDelete(e, "video");
          }}
        >
          <Trash size={24} />
        </button>
      </div>

      {/* Error and uploading messages */}
      {isError && <p className="video-error-message">{errorMsg}</p>}
      {isUploading && <p className="video-uploading-message">Uploading...</p>}

      {/* Hidden file inputs */}
      <input type="file" accept="video/*" id={"videoupload-" + id} style={{ display: "none" }} onChange={handleVideoFileChange} />
    </div>
  );
};

export default VideoUpload;
