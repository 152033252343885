import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { Plus } from "@phosphor-icons/react";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid2, Pagination } from "@mui/material";
import { FormInputDropdown } from "../../form/form-input-dropdown";

import { useForm } from "react-hook-form";
import { languageFilter } from "../../../utils/method";
import { rows } from "../../../utils/method";
import { StatusSpan } from "../../form/span";
import { ToggleButton } from "../../form/buttons/toggle-button";
import { tableBodyCellStyle, tableHeaderStyle } from "../../../utils/styleMethod";
import { mainApi, getErrorMessage } from "../../../api/main";
import FaqDetail from "../../../pages/help/detail/faqDetail";
import YesNoDialog from "../../modals/YesNoDialog";
import { toast } from "react-toastify";

const initQuery = {
  language: "mn",
  total: 0,
};

const initPage = {
  page: 1,
  limit: 8,
};

export const FaqTable = ({}) => {
  const navigate = useNavigate();
  const { control } = useForm({});
  const classessCell = tableBodyCellStyle();
  const classessHeader = tableHeaderStyle();

  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState(initQuery);
  const [pageInfo, setPageInfo] = useState(initPage);
  const [data, setData] = useState([]);

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [acceptableModalOpen, setAcceptableModalOpen] = useState(false);

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPageInfo({ ...pageInfo, page: newPage });
  };

  const onChangeSort = (values) => setQuery({ ...query, language: values });

  const handleButtonClick = (row) => {
    setCurrentData(row);
    setIsEditMode(true);
    setShowFeedbackModal(true);
  };

  const handleDeleteButtonClick = (row) => {
    setCurrentData(row);
    setAcceptableModalOpen(true);
  };

  const addButtonClick = () => {
    setCurrentData({});
    setIsEditMode(false);
    setShowFeedbackModal(true);
  };

  const getList = async () => {
    if (isLoading) return;
    try {
      setData([]);
      setIsLoading(true);
      let params = { ...query, ...pageInfo };
      const res = await mainApi(`settings/mobile/faq/list/${params.language}`, "GET", params);
      setData(res.content);
      setQuery((prevQuery) => ({
        ...prevQuery,
        total: res.totalElements,
      }));
      setIsLoading(false);
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    if (!isLoaded) getList();
    setIsLoaded(true);
  }, [isLoaded]);

  useEffect(() => {
    getList();
  }, [pageInfo.page, query.language]);

  const closeFeedbackModal = (isRefresh) => {
    setShowFeedbackModal(false);
    if (isRefresh) {
      getList();
    }
  };

  const acceptableModalClose = () => {
    setAcceptableModalOpen(false);
  };

  const deleteData = async () => {
    try {
      const res = await mainApi(`settings/admin/delete/faq/${currentData?.id}`, "DELETE", null);

      acceptableModalClose();
      toast.success("Амжилттай устгалаа");
      getList();
    } catch (error) {
      let errorMessage = getErrorMessage(error);
      toast.error("Устгах явцад алдаа гарлаа. " + errorMessage);
    }
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        padding: "24px 32px 24px 32px",
        marginTop: "24px",
        marginBottom: "64px",
        boxShadow: "none",
        borderRadius: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "36px",
        }}
      >
        <p className="HeadlineHeadline---Small" style={{ color: "var(--on-surface-high)" }}>
          Түгээмэл асуулт хариултууд <span> | </span> <span className="BodyBody---Medium"> Нийт {query.total} асуулт хариулт байна</span>{" "}
        </p>
        <Box
          sx={{
            display: "flex", // Enable flexbox
            flexDirection: "row", // Align items horizontally
            alignItems: "center", // Vertically center items
          }}
        >
          <FormInputDropdown
            control={control}
            name={`mn`}
            options={languageFilter}
            sx={{ minWidth: "250px", marginRight: "12px" }}
            onChange={(selectedValue) => onChangeSort(selectedValue)}
          />
          <Button
            variant="contained"
            sx={{
              "backgroundColor": "#2e7d32",
              "borderRadius": "24px",
              "padding": "8px 24px",
              "textTransform": "none",
              "fontWeight": "bold",
              "display": "flex",
              "alignItems": "center",
              "&:hover": {
                backgroundColor: "#1b5e20",
              },
            }}
            // onClick={() => setOpenMapModal(true)}
            onClick={addButtonClick}
            startIcon={<Plus />}
          >
            Нэмэх
          </Button>
        </Box>
      </Box>
      <Table aria-label="order table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
            {["№", "Асуулт", "Хариулт", "Хэл", "Тухай", "Устгах"].map((header) => (
              <TableCell key={header} className={classessHeader.tableHeader}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index} className={index % 2 === 1 ? "table-row-odd" : ""}>
              <TableCell className={classessCell.tableBodyCell}>{(pageInfo.page - 1) * pageInfo.limit + index + 1}</TableCell>
              <TableCell className={classessCell.tableBodyCell}>{row.question}</TableCell>
              <TableCell className={classessCell.tableBodyCell}>{row.answer}</TableCell>
              <TableCell className={classessCell.tableBodyCell}>{row.language}</TableCell>
              <TableCell className={classessCell.tableBodyCell}>
                <ToggleButton label={"Дэлгэрэнгүй"} onClickButton={() => handleButtonClick(row)} />
              </TableCell>
              <TableCell className={classessCell.tableBodyCell}>
                <button
                  className="LabelLabel---Small"
                  variant={"outlined"}
                  onClick={() => handleDeleteButtonClick(row)}
                  style={{
                    "color": "red",
                    "backgroundColor": "#fff",
                    "borderRadius": "24px",
                    "border": "0",
                    "padding": "4px 16px",
                    "textTransform": "none",
                    "minWidth": "120px",
                    "height": "36px",
                    "&:hover": {
                      backgroundColor: "#1b5e20",
                    },
                  }}
                >
                  Устгах
                </button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Custom Pagination */}
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={Math.ceil(query.total / pageInfo.limit)}
          page={pageInfo.page}
          onChange={handleChangePage}
          siblingCount={1}
          boundaryCount={1}
        />
      </Box>

      <FaqDetail open={showFeedbackModal} handleClose={closeFeedbackModal} data={currentData} isEditMode={isEditMode}></FaqDetail>
      <YesNoDialog
        title={"Та устгахдаа итгэлтэй байна уу?"}
        content={""}
        open={acceptableModalOpen}
        handleClose={acceptableModalClose}
        handleConfirm={deleteData}
      />
    </TableContainer>
  );
};
