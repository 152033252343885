import { Box } from "@mui/material";

import { BodyBodyLarge, BodyBodySmall, HeadlineHeadlineSmall, TitleTitleMedium, TitleTitleMid } from "../../../utils/styleMethod";

export default function DonationInfo({ data }) {
  return (
    <Box
      sx={{
        // border: "1px solid #E0E0E0",
        borderRadius: "10px",
        p: "24px 32px",
        backgroundColor: "var(--surface-white)",
        width: "100%",
        maxWidth: "100vw",
        margin: "0 auto",
        mt: 2,
        boxSizing: "border-box",
      }}
    >
      <Box mt={2} style={{ paddingTop: "16px" }}>
        <div
          style={{
            width: "342px",
            height: "209px", // Fixed height for consistent appearance
            overflow: "hidden",
            border: "1px solid #ccc",
            borderRadius: "12px",
          }}
        >
          <img
            src={data?.imageUrl}
            alt={"Хандивын зураг"}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "fill", // Ensures the image fills the area with cropping
            }}
          />
        </div>
      </Box>
      {/* About Section */}
      <Box mt={2} sx={{ width: "780px", pb: 4 }} mb={4} style={{ paddingTop: "16px" }}>
        <HeadlineHeadlineSmall color="var(--on-surface-high)">{data?.name}</HeadlineHeadlineSmall>
      </Box>
      <Box gap={24}>
        <TitleTitleMid color="var(--on-surface-high)" style={{ marginBottom: "16px" }}>
          {"Тухай"}
        </TitleTitleMid>
        <BodyBodyLarge
          color="var(--on-surface-high)"
          dangerouslySetInnerHTML={{
            __html: data?.description?.replace(/\r\n/g, "<br />"), // Replace newlines with <br />
          }}
        />
      </Box>
      <Box gap={10} style={{ paddingTop: "16px" }}>
        <BodyBodySmall color="var(--on-surface-high)" style={{ marginBottom: "8px" }}>
          {"Хандивын данс"}
        </BodyBodySmall>
        <TitleTitleMedium color="var(--on-surface-high)" style={{ marginBottom: "16px" }}>
          {`${data?.bankName} ${data?.bankAccountNumber} ${data?.bankAccountName}`}
        </TitleTitleMedium>
      </Box>

      <Box mt={2} sx={{ width: "780px", pb: 4 }} mb={4} style={{ paddingTop: "32px" }}>
        <HeadlineHeadlineSmall color="var(--on-surface-high)">{data?.nameEn}</HeadlineHeadlineSmall>
      </Box>
      <Box gap={24}>
        <TitleTitleMid color="var(--on-surface-high)" style={{ marginBottom: "16px" }}>
          {"About"}
        </TitleTitleMid>
        <BodyBodyLarge
          color="var(--on-surface-high)"
          dangerouslySetInnerHTML={{
            __html: data?.descriptionEn?.replace(/\r\n/g, "<br />"), // Replace newlines with <br />
          }}
        />
      </Box>
      <Box gap={10} style={{ paddingTop: "16px" }}>
        <BodyBodySmall color="var(--on-surface-high)" style={{ marginBottom: "8px" }}>
          {"Donation account"}
        </BodyBodySmall>
        <TitleTitleMedium color="var(--on-surface-high)" style={{ marginBottom: "16px" }}>
          {`${data?.bankName} ${data?.bankAccountNumber} ${data?.bankAccountName}`}
        </TitleTitleMedium>
      </Box>
    </Box>
  );
}
