import React, { useEffect, useState } from "react";
import { Modal, Box, Button, TextField, MenuItem } from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { X, UserCircle, User } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BodyBodyLarge,
  BodyBodyMedium,
  BodyBodySmall,
  DisplayMedium,
  DisplaySmall,
  HeadlineHeadlineSmall,
  TitleTitleLarge,
  TitleTitleMedium,
} from "../../../utils/styleMethod";
import { mainApi, getErrorMessage } from "../../../api/main";
import { getColorByFeedbackStatus } from "../../../utils/method";
import { FeedbackStatusSpan } from "../../../components/form/span/FeedbackStatusSpan";

const FeedbackSubmit = ({ open, handleClose, data }) => {
  const navigate = useNavigate();
  const [feedbackTypes, setFeedbackTypes] = useState([]);

  const {
    control,
    handleSubmit,
    register,
    clearErrors,
    setError,
    reset,
    watch,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      feedbackType: "",
      answer: "",
    },
  });

  useEffect(() => {
    if (open) {
      getTypes();
    }
  }, [open]);

  const getTypes = async () => {
    try {
      const res = await mainApi(`settings/admin/feedback/types`, "GET", null);
      setFeedbackTypes(res.content);
    } catch (error) {
      console.log("error");
    }
  };

  const handleValidation = async () => {
    try {
      const values = getValues();

      const isValid = await trigger();

      if (!isValid) {
        toast.warning("Мэдээллээ бүрэн зөв бөглөн үү.");
        return;
      }
      debugger;

      let params = {
        feedbackId: data?.id,
        feedbackTypeId: values.feedbackType,
        answerText: values.answer,
      };

      const res = await mainApi("settings/admin/save/feedback/answer", "POST", params);

      reset();

      data.feedbackTypeId = params.feedbackTypeId;

      let feedbackType = feedbackTypes.find((item) => item.id == params.feedbackTypeId);
      data.feedbackTypeName = feedbackType?.name;
      data.answer = params.answerText;

      data.statusId = "COMPLETED";
      data.statusName = "Шийдвэрлэгдсэн";
      handleClose(true, data);
    } catch (error) {
      let errorMessage = getErrorMessage(error);
      toast.error("Хадгалах явцад алдаа гарлаа. " + errorMessage);
    }
  };

  return (
    <>
      {data && (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Box display="flex" justifyContent="center" alignItems="center" mb={2} width={"100%"}>
              <TitleTitleLarge color="var(--on-surface-high)">Санал хүсэлтийн хариулт</TitleTitleLarge>
              <Button onClick={handleClose} sx={{ position: "absolute", right: "16px", minWidth: "auto" }}>
                <X size={24} color="#4caf50" />
              </Button>
            </Box>

            <Box>
              <Box display="flex" alignItems="center">
                <Box>
                  <BodyBodySmall color="var(--on-surface-high)" sx={{ mb: 2 }}>
                    Гомдлын төрөл:
                  </BodyBodySmall>
                  <TextField
                    select
                    defaultValue={feedbackTypes[0]?.id}
                    {...register("feedbackType", { required: "Гомдлын төрлийг сонгоно уу" })}
                    error={!!errors.feedbackType}
                    helperText={errors.feedbackType?.message}
                    sx={{ width: 200 }}
                    InputProps={{ sx: { height: "45px" } }}
                  >
                    {feedbackTypes.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box>
                {/* Left Section */}
                <Box>
                  <BodyBodySmall color="var(--on-surface-high)" sx={{ mb: 2 }}>
                    Хариулт:
                  </BodyBodySmall>
                  <TextField
                    {...register("answer", { required: "Хариултын мэдээллийг заавал оруулна уу" })}
                    fullWidth
                    error={!!errors?.answer}
                    helperText={errors?.answer?.message}
                    multiline
                    rows={3}
                  />
                </Box>
              </Box>
            </Box>
            {/* Centered Button */}
            <Box display="flex" justifyContent="center" mt={4}>
              <Button
                variant="contained"
                sx={{
                  "backgroundColor": "#2e7d32",
                  "borderRadius": "24px",
                  "padding": "8px 24px",
                  "textTransform": "none",
                  "fontWeight": "bold",
                  "display": "flex",
                  "alignItems": "center",
                  "&:hover": {
                    backgroundColor: "#1b5e20",
                  },
                }}
                // onClick={() => setOpenMapModal(true)}
                onClick={handleValidation}
                // startIcon={<Plus />}
              >
                Хариулт хадгалах
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default FeedbackSubmit;
