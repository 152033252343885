import React, { useState } from "react";
import {
    Modal,
    Box,
    Button,
    Divider,
    TextField,
    Card,
    CardMedia,
    CardContent,
    Typography,
    Grid2,
} from "@mui/material";
import { X } from "@phosphor-icons/react";
import { mainApi } from "../../../api/main";
import { toast } from "react-toastify";
import { BodyBodyLarge, BodyBodyMedium } from "../../../utils/styleMethod";

const AddPriceModal = ({ open, handleClose, itemList, itemIds, id }) => {
    const [prices, setPrices] = useState({});

    const handlePriceChange = (id, value) => {
        setPrices((prevPrices) => ({
            ...prevPrices,
            [id]: value,
        }));
    };

    // Filter items that match itemIds
    const filteredItems = itemList?.filter((item) =>
        itemIds?.includes(item.id)
    );

    const savePrice = async () => {
        console.log("org id -> ", id);
        console.log("prices  ===> ", prices);

        const jsonArray = Object.entries(prices).map(([key, value]) => ({
            id: Number(key),
            price: Number(value),
        }));

        try {
            const res = await mainApi(
                `organization/add-price/${id}`,
                "PUT",
                jsonArray
            );
            toast.success("Үнэ нэмэгдээ");
            handleClose()
        } catch (error) {
            toast.warning("Үнэ нэмэхэд алдаа гарлаа.");
        }
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    borderRadius: 2,
                    width: "700px",
                    maxHeight: "90vh", // Limit modal height
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden", // Prevent content from overflowing outside
                }}
            >
                {/* Header Section */}
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={2}
                >
                    <BodyBodyLarge >
                        Үнэ нэмэх
                    </BodyBodyLarge>
                    <Button
                        aria-label="Close"
                        onClick={handleClose}
                        sx={{ minWidth: "unset", padding: 0 }}
                    >
                        <X size={24} />
                    </Button>
                </Box>

                <Box
                    sx={{
                        overflowY: "auto", // Enable vertical scrolling
                        p: 2,
                    }}
                >
                    <Grid2 container spacing={2}>
                        {filteredItems.map((item) => (
                            <Grid2 xs={12} sm={6} md={4} key={item.id}>
                                <Card sx={{ textAlign: "center", p: 2 }}>
                                    <CardMedia
                                        component="img"
                                        image={item.imageUrl}
                                        alt={item.name}
                                        sx={{
                                            height: 85,
                                            width: 86,
                                            objectFit: "cover",
                                            margin: "0 auto",
                                        }}
                                    />
                                    <CardContent>
                                        <BodyBodyMedium color="var(--on-surface-high)">
                                            {item.name}
                                        </BodyBodyMedium>
                                        <TextField
                                            placeholder="үнэ"
                                            type="number"
                                            value={prices[item.id] || ""}
                                            onChange={(e) =>
                                                handlePriceChange(
                                                    item.id,
                                                    e.target.value
                                                )
                                            }
                                            fullWidth
                                            InputProps={{
                                                inputProps: { min: 0 },
                                            }}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid2>
                        ))}
                    </Grid2>
                </Box>

                {/* Footer Section */}
                <Box p={2}>
                    
                    <Button
                        onClick={() => savePrice()}
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{
                            height: 42,
                            borderRadius: 2,
                        }}
                    >
                        Нэмэх
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default AddPriceModal;
