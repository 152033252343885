import { useForm, Controller, useFieldArray } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Grid2,
  Checkbox,
  IconButton,
  Card,
  List,
  ListItem,
  ListItemText,
  Divider,
  Fab,
  MenuItem,
} from "@mui/material";
import { Plus, Trash, Check } from "@phosphor-icons/react";
import { toast } from "react-toastify";
import {
  BodyBodyLarge,
  BodyBodySmall,
  HeadlineHeadlineMedium,
  HeadlineHeadlineSmall,
  LabelLabelSmall,
  TitleTitleMedium,
} from "../../../utils/styleMethod";
import ImageUpload from "../../../components/image-upload";
import { mainApi, getErrorMessage } from "../../../api/main";

const answerPrefix = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N"];

const ExamEntry = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    control,
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      nameEn: "",
      questionCount: 0,
      correctCount: 0,
      correctGivePoint: 0,
      timeInfo: "",
      startDate: new Date().toISOString().split("T")[0],
      posted: false,
      questions: [],
    },
  });

  const {
    fields: questionFields,
    append: appendQuestion,
    remove: removeQuestion,
  } = useFieldArray({
    control,
    name: "questions",
  });

  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(-1); // -1 for General Info
  const [currentData, setCurrentData] = useState({});

  useEffect(() => {
    if (id) {
      getDetail();
    }
  }, [id]);

  const getDetail = async () => {
    try {
      const res = await mainApi(`exams/admin/detail/${id}`, "GET", null);
      setCurrentData(res);
      fillData(res);
    } catch (error) {
      toast.warning("Өгөгдөл олдсонгүй");
    }
  };

  const fillData = (data) => {
    debugger;
    setValue("name", data.name);
    setValue("nameEn", data.nameEn);
    setValue("questionCount", data.questionCount);
    setValue("correctCount", data.correctCount);
    setValue("correctGivePoint", data.correctGivePoint);
    setValue("timeInfo", data.timeInfo);
    setValue("startDate", data.startDate.split("T")[0]);
    setValue("posted", data.posted);
    setValue("questions", data.questions);
  };

  const handleNext = () => {
    let size = 0;
    if (questionFields != null && questionFields !== undefined) size = questionFields.length;
    if (selectedQuestionIndex < size - 1) {
      setSelectedQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleBack = () => {
    if (selectedQuestionIndex > -1) {
      setSelectedQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };

  const changeImageUrl = (url, id) => {
    //"imageImageList-0"
    const currentIndex = parseInt(id.substring(15));

    setValue(`questions[${currentIndex}].imageUrl`, url);
  };

  const handleQuestionTypeChange = (event, index) => {
    const newQuestionType = event.target.value;
    const currentFields = getValues("questions");
    const updatedFields = currentFields.map((item, i) => {
      if (i === index) {
        return { ...item, questionType: newQuestionType };
      }
      return item;
    });
    setValue("questions", updatedFields, { shouldValidate: true });
    console.log(questionFields[index]);
  };

  const handleAnswerCorrectChange = (event, index, answerIndex) => {
    debugger;
    const newCorrectAnswer = event.target.checked;
    const currentFields = getValues("questions");
    const updatedFields = currentFields.map((item, i) => {
      if (i === index) {
        const updatedAnswers = item.choices.map((answer, aIndex) => ({
          ...answer,
          correctAnswer: aIndex === answerIndex ? newCorrectAnswer : false,
        }));
        return { ...item, choices: updatedAnswers };
      }
      return item;
    });
    setValue("questions", updatedFields, { shouldValidate: true });
  };

  const handleQuestionNameChange = (event, index) => {
    const newName = event.target.value;
    const currentFields = getValues("questions");
    const updatedFields = currentFields.map((item, i) => {
      if (i === index) {
        return { ...item, name: newName };
      }
      return item;
    });
    setValue("questions", updatedFields, { shouldValidate: true });
    console.log(questionFields[index]);
  };

  const handleAnswerDelete = (index, answerIndex) => {
    const currentFields = getValues("questions");
    const updatedFields = currentFields.map((item, i) => {
      if (i === index) {
        const updatedAnswers = item.choices.filter((_, aIndex) => aIndex !== answerIndex);
        return { ...item, choices: updatedAnswers };
      }
      return item;
    });
    setValue("questions", updatedFields, { shouldValidate: true });
  };

  const appendAnswer = () => {
    const currentFields = getValues("questions");
    const updatedFields = currentFields.map((item, i) => {
      if (i === selectedQuestionIndex) {
        let answers = item.choices;
        answers.push({ correctAnswer: false, name: "", nameEn: "" });
        return { ...item, choices: answers };
      }
      return item;
    });
    setValue("questions", updatedFields, { shouldValidate: true });
  };

  const handleValidation = async () => {
    try {
      const values = getValues();

      console.log(values);
      const isValid = await trigger();

      if (!isValid) {
        toast.warning("Мэдээллээ бүрэн зөв бөглөн үү.");
        return;
      }

      let questions = values.questions;

      if (Array.isArray(questions) && questions.length > 0) {
        if (parseInt(values.questionCount) !== questions.length) {
          toast.warning(
            "Ерөнхий мэдээлэл дээрх асуултын тоо нийт асуултын тоотой зөрж байна. Адилхан болгон уу!" + values.questionCount + " " + questions.length
          );
          return;
        }
        if (values.questionCount < values.correctQuestionCount) {
          toast.warning("Шалгалтанд тэнцэх асуултын тоо ");
          return;
        }

        for (let i = 0; i < questions.length; i++) {
          const question = questions[i];
          if (question.questionType === "Choice") {
            const answers = question.choices;
            if (Array.isArray(answers) && answers.length < 2) {
              toast.warning("Асуулт: " + question.name + "-д доод тал нь 2 сонголтын мэдээлэл оруулна уу!");
              return;
            }

            const correctCount = answers.filter((answer) => answer.correctAnswer).length;
            if (correctCount === 0) {
              toast.warning("Асуулт: " + question.name + "-д зөв хариултын мэдээллийг оруулна уу!");
              return;
            }
          }
        }
      } else {
        toast.warning("Дор хаяж 1-2 асуултын мэдээлэл оруулна уу!");
        return;
      }

      console.log(values);
      if (id) {
        values.id = id;
      }

      const res = await mainApi("exams/admin/save", "POST", values);

      reset();
      navigate("/exam");
    } catch (error) {
      let errorMessage = getErrorMessage(error);
      toast.error("Хадгалах явцад алдаа гарлаа. " + errorMessage);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        padding: 2,
        borderRadius: 2,
        boxShadow: 1,
        minHeight: "100vh",
      }}
    >
      <form>
        <Grid container spacing={2}>
          {/* Left Panel: Question List */}
          <Grid item xs={3}>
            <Box borderRight={1} borderColor="var(--on-surface-low)" height="100%" sx={{ paddingRight: 2 }}>
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <TitleTitleMedium>Асуултууд</TitleTitleMedium>
                {/* Floating Action Button */}
                <Fab
                  color="primary"
                  size="small"
                  onClick={() => appendQuestion({ name: "Жишээ", nameEn: "Example", imageUrl: "", questionType: "Choice", choices: [] })}
                >
                  <Plus size={20} />
                </Fab>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Button
                  key={"General-1"}
                  variant="outlined"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    textTransform: "none",
                    padding: "8px 16px",
                    borderColor: -1 === selectedQuestionIndex ? "var(--stroke-primary)" : "var(--stroke-primary-soft", // Use CSS variable for border color
                    color: -1 === selectedQuestionIndex ? "var(--stroke-primary)" : "inherit", // Update color conditionally
                  }}
                  onClick={async () => {
                    await trigger();
                    setSelectedQuestionIndex(-1);
                  }}
                >
                  <LabelLabelSmall sx={{ textAlign: "left", flex: 1, marginLeft: 2 }}>{"Ерөнхий мэдээлэл"}</LabelLabelSmall>
                  {-1 === selectedQuestionIndex && <Check sx={{ color: "var(--stroke-primary-soft" }} size={18} />}{" "}
                  {/* Show Check icon conditionally */}
                </Button>
                <Divider sx={{ borderColor: "var(--on-surface-high)" }} />
                {questionFields.map((question, index) => (
                  <Button
                    key={`question.id-${index}`}
                    variant="outlined"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      textTransform: "none",
                      padding: "8px 16px",
                      borderColor: index === selectedQuestionIndex ? "var(--stroke-primary)" : "var(--stroke-primary-soft", // Use CSS variable for border color
                      color: index === selectedQuestionIndex ? "var(--stroke-primary)" : "inherit", // Update color conditionally
                    }}
                    onClick={async () => {
                      await trigger();
                      setSelectedQuestionIndex(index);
                    }}
                  >
                    <BodyBodySmall>{index + 1}</BodyBodySmall>
                    <LabelLabelSmall sx={{ textAlign: "left", flex: 1, marginLeft: 2 }}>{question.name}</LabelLabelSmall>
                    {index === selectedQuestionIndex && <Check sx={{ color: "var(--stroke-primary-soft" }} size={18} />}{" "}
                  </Button>
                ))}
              </Box>
            </Box>
          </Grid>

          {/* Right Panel */}
          <Grid item xs={9}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <HeadlineHeadlineMedium>
                {selectedQuestionIndex === -1 ? "Шалгалтын ерөнхий мэдээлэл" : `Асуулт ${selectedQuestionIndex + 1}`}
              </HeadlineHeadlineMedium>

              <Button variant="contained" color="primary" onClick={handleValidation}>
                Хадгалах
              </Button>
            </Box>
            <Box hidden={selectedQuestionIndex !== -1}>
              <>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box>
                    <BodyBodySmall color="var(--on-surface-high)">Шалгалтын нэр /Монгол/</BodyBodySmall>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <TextField
                        {...register("name", { required: "Шалгалтын монгол нэр оруулна уу" })}
                        fullWidth
                        InputProps={{ sx: { height: "45px" } }}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <BodyBodySmall color="var(--on-surface-high)">Шалгалтын нэр /Англи/</BodyBodySmall>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <TextField
                        {...register("nameEn", { required: "Шалгалтын англи нэр оруулна уу" })}
                        fullWidth
                        InputProps={{ sx: { height: "45px" } }}
                        error={!!errors.nameEn}
                        helperText={errors.nameEn?.message}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <BodyBodySmall color="var(--on-surface-high)">Нийт асуулт</BodyBodySmall>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <TextField
                        {...register("questionCount", { required: "Нийт асуултын тоог оруулна уу" })}
                        fullWidth
                        type="number"
                        InputProps={{ sx: { height: "45px" } }}
                        error={!!errors.questionCount}
                        helperText={errors.questionCount?.message}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <BodyBodySmall color="var(--on-surface-high)">Шалгалтанд тэнцэх асуултын тоо</BodyBodySmall>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <TextField
                        {...register("correctCount", { required: "Шалгалтанд тэнцэх асуултын тоо оруулна уу" })}
                        fullWidth
                        type="number"
                        InputProps={{ sx: { height: "45px" } }}
                        error={!!errors.correctCount}
                        helperText={errors.correctCount?.message}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <BodyBodySmall color="var(--on-surface-high)">Шалгалтанд тэнцээд авах урамшууллын оноо</BodyBodySmall>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <TextField
                        {...register("correctGivePoint", { required: "Шалгалтанд тэнцээд авах урамшууллын оноо оруулна уу" })}
                        fullWidth
                        type="number"
                        InputProps={{ sx: { height: "45px" } }}
                        error={!!errors.correctGivePoint}
                        helperText={errors.correctGivePoint?.message}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <BodyBodySmall color="var(--on-surface-high)">Шалгалтын хугацаа /Текстээр/</BodyBodySmall>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <TextField
                        {...register("timeInfo", { required: "Шалгалтын хугацаа оруулна уу" })}
                        fullWidth
                        InputProps={{ sx: { height: "45px" } }}
                        error={!!errors.timeInfo}
                        helperText={errors.timeInfo?.message}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <BodyBodySmall color="var(--on-surface-high)">Шалгалтын огноо</BodyBodySmall>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <TextField
                        type="date"
                        {...register("startDate", { required: "Шалгалтын огноо оруулна уу" })}
                        error={!!errors.startDate}
                        helperText={errors.startDate?.message}
                      />

                      <Checkbox
                        {...register("posted")}
                        checked={watch("posted")}
                        onChange={(e) => setValue("posted", e.target.checked)} // Update form state on change
                      />
                      <BodyBodySmall color="var(--on-surface-high)">Нийтлэх эсэх</BodyBodySmall>
                    </Box>
                  </Box>
                </Box>
              </>
            </Box>
            {/* Map over questions */}
            <Box hidden={selectedQuestionIndex === -1}>
              {questionFields.map((question, index) => (
                <Box key={index} hidden={selectedQuestionIndex !== index}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", gap: 2 }}>
                    {/* Question specific fields */}
                    <BodyBodySmall color="var(--on-surface-high)">Асуултын зураг</BodyBodySmall>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <ImageUpload
                        id={`imageImageList-${index}`}
                        type="ExamQuestion"
                        suggestText={"Санал болгох зургийн харьцаа: 318x188"}
                        onChange={changeImageUrl}
                        imageUrl={question?.imageUrl}
                      />
                    </Box>
                    <IconButton
                      color="error"
                      onClick={() => {
                        removeQuestion(index); // Remove question by index
                        setSelectedQuestionIndex(-1); // Reset to General Info
                      }}
                    >
                      <Trash />
                    </IconButton>
                  </Box>

                  <Box>
                    <BodyBodySmall color="var(--on-surface-high)">Асуулт /Монгол/</BodyBodySmall>
                    <TextField
                      {...register(`questions.${index}.name`, { required: "Асуултын монгол нэр оруулна уу" })}
                      fullWidth
                      error={!!errors?.questions?.[index]?.name}
                      helperText={errors?.questions?.[index]?.name?.message}
                      InputProps={{ sx: { height: "45px" } }}
                      onChange={(e) => handleQuestionNameChange(e, index)}
                    />
                  </Box>

                  <Box>
                    <BodyBodySmall color="var(--on-surface-high)">Асуулт /Англи/</BodyBodySmall>
                    <TextField
                      {...register(`questions.${index}.nameEn`, { required: "Асуултын англи нэр оруулна уу" })}
                      fullWidth
                      error={!!errors?.questions?.[index]?.nameEn}
                      helperText={errors?.questions?.[index]?.nameEn?.message}
                      InputProps={{ sx: { height: "45px" } }}
                    />
                  </Box>

                  <Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <BodyBodySmall color="var(--on-surface-high)">Асуултын төрөл</BodyBodySmall>
                      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                        <TextField
                          select
                          value={question?.questionType || "Choice"}
                          {...register(`questions.${index}.questionType`, { required: "Төрөл сонгоно уу" })}
                          error={!!errors?.questions?.[index]?.questionType}
                          helperText={errors?.questions?.[index]?.questionType?.message}
                          sx={{ width: 120 }}
                          InputProps={{ sx: { height: "45px" } }}
                          onChange={(e) => handleQuestionTypeChange(e, index)}
                        >
                          <MenuItem value="Choice">Сонголт</MenuItem>
                          <MenuItem value="Text">Текст</MenuItem>
                        </TextField>
                      </Box>
                    </Box>
                  </Box>
                  <Divider sx={{ borderColor: "var(--on-surface-high)", marginTop: 2 }} />
                  {questionFields[selectedQuestionIndex]?.questionType === "Choice" && (
                    <Box display="flex" flexDirection="column" gap={2}>
                      <HeadlineHeadlineMedium>Хариулт</HeadlineHeadlineMedium>
                      {Array.isArray(questionFields[index]?.choices) &&
                        questionFields[index]?.choices?.map((_, ansIndex) => (
                          <Box key={ansIndex} display="flex" alignItems="center" mb={1}>
                            <BodyBodyLarge sx={{ marginRight: 1 }}>{answerPrefix[ansIndex]}.</BodyBodyLarge>
                            <TextField
                              {...register(`questions.${index}.choices.${ansIndex}.name`, { required: "Хариулт оруулна уу" })}
                              fullWidth
                              label={ansIndex === 0 ? "Монгол" : ""}
                              InputProps={{ sx: { height: "45px" } }}
                              error={!!errors?.questions?.[index]?.choices?.[ansIndex]?.name}
                              helperText={errors?.questions?.[index]?.choices?.[ansIndex]?.name?.message}
                            />
                            <TextField
                              {...register(`questions.${index}.choices.${ansIndex}.nameEn`, { required: "Хариулт оруулна уу" })}
                              fullWidth
                              label={ansIndex === 0 ? "Англи" : ""}
                              InputProps={{ sx: { height: "45px" } }}
                              error={!!errors?.questions?.[index]?.choices?.[ansIndex]?.nameEn}
                              helperText={errors?.questions?.[index]?.choices?.[ansIndex]?.nameEn?.message}
                            />
                            <Checkbox
                              checked={questionFields[index]?.choices[ansIndex]?.correctAnswer} // Dynamically reflect the value of `isActive`
                              onChange={(e) => handleAnswerCorrectChange(e, index, ansIndex)} // Update form state on change
                            />

                            <IconButton
                              onClick={() => {
                                handleAnswerDelete(index, ansIndex);
                              }}
                            >
                              <Trash />
                            </IconButton>
                          </Box>
                        ))}
                      <Button variant="text" startIcon={<Plus />} onClick={() => appendAnswer()}>
                        Хариулт нэмэх
                      </Button>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>

            {/* Буцах, Үргэлжлүүлэх товч */}
            <Box
              sx={{
                marginTop: "24px",
                display: "flex",
                justifyContent: "center",
                gap: "16px",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                sx={{ textTransform: "none" }}
                onClick={handleBack}
                disabled={selectedQuestionIndex === -1} // Ерөнхий мэдээлэл үед идэвхгүй
              >
                Буцах
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ textTransform: "none" }}
                onClick={handleNext}
                disabled={selectedQuestionIndex === questionFields.length - 1} // Хамгийн сүүлийн асуулт үед идэвхгүй
              >
                Үргэлжлүүлэх
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default ExamEntry;
