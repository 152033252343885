import { Box } from "@mui/material";
import { HeadlineHeadlineSmall } from "../../utils/styleMethod";
import { useEffect, useState } from "react";
import { mainApi } from "../../api/main";

const ItemPrice = (id) => {
    const [items, setItems] = useState([])
    useEffect(() => {
        // getItemPrice()
    }, []);

    const getItemPrice = async () => {
        try {
            const res = mainApi(`organization/item-price/${id}`, "GET", null);
        } catch (error) {
            console.log("error");
        }
    };
    return (
        <>
            <Box
                sx={{
                    // border: "1px solid #E0E0E0",
                    borderRadius: "10px",
                    p: "24px 32px",
                    backgroundColor: "#FFFFFF",
                    width: "100%",
                    maxWidth: "100vw",
                    margin: "0 auto",
                    mt: 2,
                    boxSizing: "border-box",
                }}
            >
                <HeadlineHeadlineSmall color="var(--on-surface-high)">
                    Дахивар худалдан авах үнэ
                </HeadlineHeadlineSmall>
                <Box display={"flex"}>hello</Box>
            </Box>
        </>
    );
};

export default ItemPrice;
