import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from "../pages/Login";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import HomePage from "../pages/Home";
import MainLayout from "../layouts/side-bar";
import Driver from "../pages/driver";
import Call from "../pages/call";
import NotFound from "../pages/not-found";
import { UserContext } from "../context/user";
import Enterprise from "../pages/enterprise";
import EnterpriseRegister from "../pages/enterprise/register-request";
import Customer from "../pages/customer";
import Factory from "../pages/factory";
import DropOff from "../pages/dropoff";
import Prepare from "../pages/dropoff/prepare";
import Finance from "../pages/finance";
import ItemGroup from "../pages/guide/itemGroup";
import ItemGroupDetail from "../pages/guide/itemGroup/detail";
import Barcode from "../pages/guide/barcode";
import News from "../pages/news";
import NewsDetail from "../pages/news/detail";
import Exam from "../pages/exam";
import ExamDetail from "../pages/exam/detail";
import ExamEntry from "../pages/exam/detail/examEntry";
import Help from "../pages/help";
import Settings from "../pages/settings";
import FactoryDetail from "../pages/factory/detail";
import DropOffDetail from "../pages/dropoff/detail";
import EnterpriseDetail from "../pages/enterprise/detail";
import CustomerDetail from "../pages/customer/detail";
import DriverDetail from "../pages/driver/detail";
import DriverLocation from "../pages/driver/driver-location";
import Profile from "../pages/profile";
import ReelsDetail from "../pages/news/detail/reelsDetail";
import UserDeletion from "../pages/UserDeletion";
import DonationDetail from "../pages/settings/detail/donationDetail";

function Routing() {
  const { user, setUser } = useContext(UserContext);

  //   const user = useSelector((state) => state.user);
  useEffect(() => {
    // Check the cookie for user data
    const cookieData = localStorage.getItem("user"); // Assuming you're using localStorage or Cookies to store user data
    if (cookieData) {
      try {
        const parsedData = JSON.parse(cookieData);
        const expiryDate = new Date(parsedData.expiryDate);

        // Check if the session has expired
        if (expiryDate > new Date()) {
          setUser(parsedData); // Session is valid
        } else {
          setUser(null); // Session expired
        }
      } catch (error) {
        setUser(null); // If cookie is corrupted, treat it as expired
      }
    } else {
      setUser(null); // If no cookie, redirect to login
    }
  }, [setUser]);

  return (
    <BrowserRouter>
      {!(user === undefined || user === null) ? (
        <Routes>
          <Route
            path="/"
            element={
              <MainLayout>
                <HomePage />
              </MainLayout>
            }
          />

          <Route
            path="/driver"
            element={
              <MainLayout>
                <Driver />
              </MainLayout>
            }
          />
          <Route
            path="/driver/detail/:id"
            element={
              <MainLayout>
                <DriverDetail />
              </MainLayout>
            }
          />
          <Route
            path="/driver/detail/location/:id"
            element={
              <MainLayout>
                <DriverLocation />
              </MainLayout>
            }
          />
          <Route
            path="/call"
            element={
              <MainLayout>
                <Call />
              </MainLayout>
            }
          />
          <Route
            path="/enterprise"
            element={
              <MainLayout>
                <Enterprise />
              </MainLayout>
            }
          />
          <Route
            path="/enterprise/detail/:id"
            element={
              <MainLayout>
                <EnterpriseDetail />
              </MainLayout>
            }
          />
          <Route
            path="/enterprise/register"
            element={
              <MainLayout>
                <EnterpriseRegister />
              </MainLayout>
            }
          />
          <Route
            path="/customer"
            element={
              <MainLayout>
                <Customer />
              </MainLayout>
            }
          />
          <Route
            path="/customer/detail/:id"
            element={
              <MainLayout>
                <CustomerDetail />
              </MainLayout>
            }
          />
          <Route
            path="/factory"
            element={
              <MainLayout>
                <Factory />
              </MainLayout>
            }
          />
          <Route
            path="/factory/detail/:id"
            element={
              <MainLayout>
                <FactoryDetail />
              </MainLayout>
            }
          />
          <Route
            path="/dropoff"
            element={
              <MainLayout>
                <DropOff />
              </MainLayout>
            }
          />
          <Route
            path="/dropoff/detail/:id"
            element={
              <MainLayout>
                <DropOffDetail />
              </MainLayout>
            }
          />
          <Route
            path="/dropoff/prepare"
            element={
              <MainLayout>
                <Prepare />
              </MainLayout>
            }
          />
          <Route
            path="/finance"
            element={
              <MainLayout>
                <Finance />
              </MainLayout>
            }
          />
          <Route
            path="/guide"
            element={
              <MainLayout>
                <ItemGroup />
              </MainLayout>
            }
          />
          <Route
            path="/guide/:itemGroupId"
            element={
              <MainLayout>
                <ItemGroup />
              </MainLayout>
            }
          />
          <Route
            path="/guide/detail/:id/:type"
            element={
              <MainLayout>
                <ItemGroupDetail />
              </MainLayout>
            }
          />
          <Route
            path="/guide/barcode"
            element={
              <MainLayout>
                <Barcode />
              </MainLayout>
            }
          />
          <Route
            path="/news"
            element={
              <MainLayout>
                <News />
              </MainLayout>
            }
          />

          <Route
            path="/news/detail/:id"
            element={
              <MainLayout>
                <NewsDetail />
              </MainLayout>
            }
          />

          <Route
            path="/news/detail/:id/reels"
            element={
              <MainLayout>
                <ReelsDetail />
              </MainLayout>
            }
          />

          <Route
            path="/exam"
            element={
              <MainLayout>
                <Exam />
              </MainLayout>
            }
          />
          <Route
            path="/exam/detail/:id"
            element={
              <MainLayout>
                <ExamDetail />
              </MainLayout>
            }
          />
          <Route
            path="/exam/edit/:id"
            element={
              <MainLayout>
                <ExamEntry isEditMode={true} />
              </MainLayout>
            }
          />
          <Route
            path="/exam/create"
            element={
              <MainLayout>
                <ExamEntry isEditMode={false} />
              </MainLayout>
            }
          />
          <Route
            path="/help/:type"
            element={
              <MainLayout>
                <Help />
              </MainLayout>
            }
          />
          <Route
            path="/settings/:type"
            element={
              <MainLayout>
                <Settings />
              </MainLayout>
            }
          />
          <Route
            path="/settings/donations/detail/:id"
            element={
              <MainLayout>
                <DonationDetail />
              </MainLayout>
            }
          />
          <Route
            path="/profile"
            element={
              <MainLayout>
                <Profile />
              </MainLayout>
            }
          />
        </Routes>
      ) : (
        <Routes>
          {" "}
          <Route path="/login" element={<Login />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/user/deletion" element={<UserDeletion />} />
          <Route path="*" element={<Login />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default Routing;
