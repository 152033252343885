import React, { useState } from "react";
import { Box, Typography, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";

import { HeadlineHeadlineLarge, TitleTitleLarge, TitleTitleMedium } from "../../../../utils/styleMethod";

export default function ReelsInfo({ data }) {
  const [openModal, setOpenModal] = useState(false);

  const handlePlayButtonClick = () => {
    setOpenModal(true); // Open the modal on play button click
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Close the modal
  };

  return (
    <Box
      sx={{
        borderRadius: "10px",
        p: "24px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        mt: 2,
        boxSizing: "border-box",
        // Remove the following styles to align the content to the left
        // margin: "0 auto",
        // maxWidth: "1024px",
      }}
    >
      <Grid container spacing={2}>
        {/* Image Section */}
        <Grid item xs={3}>
          {data?.mainImage && (
            <Box
              sx={{
                position: "relative",
                width: "100%",
                aspectRatio: "349/ 575", // Enforces 575x349 aspect ratio
                borderRadius: "12px",
                overflow: "hidden",
                backgroundColor: "#f0f0f0",
              }}
            >
              <img
                src={data?.mainImage}
                alt={data?.caption}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: "16px", // Position the caption 16px from the bottom
                  left: "16px", // Position the caption 16px from the left
                  color: "#FFFFFF",
                }}
              >
                <HeadlineHeadlineLarge color="#FFFFFF"> {data?.caption}</HeadlineHeadlineLarge>
              </Box>

              <Box
                sx={{
                  "position": "absolute",
                  "top": "50%", // Center the play button vertically
                  "left": "50%", // Center horizontally
                  "transform": "translate(-50%, -50%)", // Adjust for exact center by offsetting 50% of width/height
                  "backgroundColor": "rgba(0, 0, 0, 0.6)",
                  "borderRadius": "50%",
                  "width": "50px",
                  "height": "50px",
                  "display": "flex",
                  "alignItems": "center",
                  "justifyContent": "center",
                  "cursor": "pointer", // Change cursor on hover to indicate it's clickable
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.8)", // Darken background on hover
                  },
                }}
                onClick={handlePlayButtonClick}
              >
                <Typography variant="h6" sx={{ color: "#FFFFFF", fontSize: "24px", fontWeight: "bold" }}>
                  ▶
                </Typography>
              </Box>
            </Box>
          )}
        </Grid>

        {/* Content Section */}
        <Grid item xs={9}>
          <TitleTitleMedium>Огноо</TitleTitleMedium>
          <TitleTitleLarge sx={{ fontWeight: 700, mt: 2, mb: 1 }}>{data?.postedDateStr} </TitleTitleLarge>

          <TitleTitleMedium sx={{ mb: 2 }}>Гарчиг</TitleTitleMedium>
          <TitleTitleLarge sx={{ fontWeight: 700, mt: 2, mb: 1 }}>{data?.caption} </TitleTitleLarge>

          <TitleTitleMedium sx={{ mb: 2 }}>Хандалт</TitleTitleMedium>
          <TitleTitleLarge sx={{ fontWeight: 700, mt: 2, mb: 1 }}>{data?.views ?? 0} үзсэн</TitleTitleLarge>
        </Grid>
      </Grid>

      {/* Video Player Modal */}
      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="lg">
        <DialogTitle>Видео тоглуулах</DialogTitle>
        <DialogContent>
          {/* Use the appropriate video embedding method */}
          <Box sx={{ width: "100%", height: "600px" }}>
            <iframe
              width="100%"
              height="100%"
              src={data?.reelVideo} // Replace with the actual video URL (YouTube, Vimeo, etc.)
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Video Player"
            ></iframe>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Хаах
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

// Sample Usage
// Pass this `data` prop when using the component.
const sampleData = {
  caption: "Дахивар боловсруулах тойрог",
  date: "2024.05.15 15:30",
  views: 2568,
  images: ["https://via.placeholder.com/647x487"],
  mainText: "This is a description of the reel, providing more information about the content displayed.",
};

// Example: <ReelsInfo data={sampleData} />
