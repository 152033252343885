import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid2, Pagination } from "@mui/material";
import { ChatCircleDots, Question, Phone, Plus } from "@phosphor-icons/react";
import { mainApi } from "../../api/main";
import { UserContext } from "../../context/user";
import { ReelsTable } from "../../components/news/reels-table";
import { FeedbackTable } from "../../components/help/feedback-table";
import { FaqTable } from "../../components/help/faq-table";
import FeedbackDetail from "./detail/feedbackDetail";
import FeedbackSubmit from "./detail/feedbackSubmit";
import { ContactInfo } from "../../components/help/contact-info";

const initQuery = {
  sort: "desc", // createdDate
  // status: null,
  newsType: "news",
  //  "status":"ON_WAY",
  // "status":"SUCCESS_SHIPPED",
  // "status":"TRANSFERRED_TO_DRIVER",
  // "status":"CANCELLED",
  keyWord: null, // "1",
  searchType: null, // "organization.id"
  total: 0,
};

const initPage = {
  page: 1,
  limit: 8,
};
const statuses = [
  {
    id: "feedback",
    label: "Санал хүсэлт",
    value: "feedback",
    color: "var(--primary-primary)",
    bgColor: "var(--primary-soft-primary-soft)",
    startIcon: <ChatCircleDots />,
  },
  {
    id: "faq",
    label: "Түгээмэл асуулт хариултууд",
    value: "faq",
    color: "var(--primary-primary)",
    bgColor: "var(--primary-soft-primary-soft)",
    startIcon: <Question />,
  },
  {
    id: "contacts",
    label: "Холбоо барих",
    value: "contacts",
    color: "var(--primary-primary)",
    bgColor: "var(--primary-soft-primary-soft)",
    startIcon: <Phone />,
  },
];
const HelpPage = () => {
  const { user } = useContext(UserContext);

  const [selectedStatus, setSelectedStatus] = useState("Санал хүсэлт");
  const [selectedStatusId, setSelectedStatusId] = useState("feedback");

  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState(initQuery);
  const [pageInfo, setPageInfo] = useState(initPage);
  const [data, setData] = useState([]);

  const { sort } = query;

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showFeedbackAnswerModal, setShowFeedbackAnswerModal] = useState(false);
  const [feedbackData, setFeedbackData] = useState(null);

  useEffect(() => {
    if (isLoaded) return;
    getList();
    setIsLoaded(true);
  }, [isLoaded]);

  useEffect(() => {
    getList();
  }, [selectedStatusId, pageInfo, sort]);

  const getList = async () => {
    if (isLoading) return;
    try {
      setData([]);
      setIsLoading(true);
      if (selectedStatusId === "feedback") {
        let params = { ...query, ...pageInfo };
        const res = await mainApi(`settings/admin/feedback/list`, "GET", params);
        setData(res.content);
        setQuery((prevQuery) => ({
          ...prevQuery,
          total: res.totalElements,
        }));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error");
    }
  };

  const handleButtonClick = (label, id, value) => {
    setSelectedStatus(label);
    setSelectedStatusId(id);
    setQuery({ ...query, status: value });
    const newUrl = `/help/${id}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  const onFeedbackDetailClick = (row) => {
    setShowFeedbackModal(true);
    setFeedbackData(row);
  };

  const closeFeedbackModal = (isRefresh) => {
    setShowFeedbackModal(false);
    if (isRefresh) {
      getList();
    }
  };

  const closeFaqModal = (isRefresh) => {
    //setShowFaqModal(false);
    if (isRefresh) {
      getList();
    }
  };

  const showAnswerModal = () => {
    setShowFeedbackAnswerModal(true);
  };

  const closeFeedbackAnswerModal = (isRefresh, data) => {
    setShowFeedbackAnswerModal(false);
    if (isRefresh) {
      setFeedbackData(data);
    }
  };

  return (
    <Grid2>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "12px",
          //   flexDirection: isSmallScreen ? "column" : "row",
        }}
      >
        <p
          className="HeadlineHeadline---Small"
          style={{
            color: "var(--on-surface-high)",
            paddingTop: "8px",
          }}
        >
          Тусламж
        </p>
      </Box>

      <Box sx={{ display: "flex", gap: 2 }}>
        {statuses.map((status, index) => (
          <Button
            key={index}
            className="LabelLabel---Small"
            variant="contained"
            sx={{
              "backgroundColor": selectedStatus === status.label ? status.color : status.bgColor,
              "color": selectedStatus === status.label ? "#fff" : "var(--primary-soft-on-primary-soft-h)",
              "borderRadius": "24px",
              "border": "0",
              "padding": "4px 16px",
              "textTransform": "none",
              "minWidth": "120px",
              "height": "36px",
              "&:hover": {
                backgroundColor: selectedStatus === status.label ? "#1b5e20" : status.bgColor,
              },
            }}
            // onClick={() => setOpenMapModal(true)}
            onClick={() => {
              handleButtonClick(status.label, status.id, status.value);
            }}
            startIcon={status.startIcon}
          >
            {status.label}
          </Button>
        ))}
      </Box>

      {selectedStatusId === "feedback" ? (
        <FeedbackTable
          data={data}
          query={query}
          setQuery={setQuery}
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          onDetailClick={onFeedbackDetailClick}
        />
      ) : null}
      {selectedStatusId === "faq" ? <FaqTable /> : null}
      {selectedStatusId === "contacts" ? <ContactInfo /> : null}
      <FeedbackDetail open={showFeedbackModal} handleClose={closeFeedbackModal} data={feedbackData} handleSubmit={showAnswerModal}></FeedbackDetail>
      <FeedbackSubmit open={showFeedbackAnswerModal} handleClose={closeFeedbackAnswerModal} data={feedbackData}></FeedbackSubmit>
    </Grid2>
  );
};

export default HelpPage;
