import React from "react";
import { Modal, Box, Button, Divider } from "@mui/material";
import {
    BodyBodyMedium,
    LabelLabelLarge,
    TitleTitleLarge,
} from "../../../utils/styleMethod";
import { CheckCircle, Warning } from "@phosphor-icons/react";
import { mainApi } from "../../../api/main";
import { toast } from "react-toastify";

const OrganizationCloseOpen = ({
    title,
    content,
    buttonText,
    open,
    handleClose,
    organizationId,
    active,
}) => {
    console.log("organizationId-->", organizationId);
    console.log("active --> ", active);
    const orgonazitionCloseOpen = async () => {
        try {
            const res = await mainApi(
                `organization/close-open/${organizationId}`,
                "PUT",
                null
            );
            toast.success("түр хаагдлаа.");
            handleClose();
        } catch (error) {
            toast.warning("системд алдаа гарлаа");
        }
    };

    return (
        <Modal open={open} onClose={() => handleClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "340px", // Updated width
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Warning weight="fill" size={24} color="orange" />

                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    textAlign={"center"}
                    width="100%"
                    mb={2}
                >
                  
                    <TitleTitleLarge color="var(--on-surface-high)">{title}</TitleTitleLarge>
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    textAlign={"center"}
                    width="100%"
                    mb={5}
                >
                    <BodyBodyMedium>{content}</BodyBodyMedium>
                </Box>
                <Divider />
                <Box width={'100%'} display={'flex'} justifyContent={'space-between'} >
                    <Button
                        sx={{
                            width: "90%",
                            height: "40px",
                            border: "1px solid orange",
                            borderRadius: "16px",
                            padding: "8px",
                            marginRight:"16px",
                            color: "orange",
                        }}
                        
                        onClick={() => handleClose()}
                    >
                        <LabelLabelLarge color="orange">
                            цуцлах
                        </LabelLabelLarge>
                    </Button>
                    <Button
                            sx={{
                              width: "90%",
                              height: "40px",
                              border: "1px solid #368137",
                              borderRadius: "16px",
                              padding: "8px",
                              marginLeft:"16px",
                              color: "#368137",
                          }}
                        variant="contained"
                        onClick={() => orgonazitionCloseOpen()}
                    >
                        <LabelLabelLarge color="white">
                            {buttonText ? buttonText : "Ойлголоо"}
                        </LabelLabelLarge>
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default OrganizationCloseOpen;
