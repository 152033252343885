import { Card, CardContent, Box, Button, Grid2 } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Clock, PlusCircle } from "@phosphor-icons/react";
import { LabelLabelSmall } from "../../utils/styleMethod";

const StyledCard = styled(Card)(({ theme }) => ({
  "width": "342px",
  "height": "226px",
  "padding": "16px 8px 8px",
  "border": "1px solid #A9DBAA ",
  "borderRadius": "10px",
  "boxShadow": "0px 4px 10px rgba(0, 0, 0, 0.1)",
  "transition": "transform 0.2s ease-in-out",
  "cursor": "pointer",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
  },
}));

const StyledCardNotPosted = styled(Card)(({ theme }) => ({
  "width": "342px",
  "height": "226px",
  "padding": "16px 8px 8px",
  "border": "1px solid gray ",
  "borderRadius": "10px",
  "boxShadow": "0px 4px 10px rgba(0, 0, 0, 0.1)",
  "transition": "transform 0.2s ease-in-out",
  "cursor": "pointer",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
  },
}));

const Name = styled("h6")(({ theme }) => ({
  fontSize: "18px",
  fontWeight: "bold",
  color: theme.palette.primary.dark,
  lineHeight: 1.4,
}));

const Info = styled("p")(({ theme }) => ({
  fontSize: "12px",
  color: theme.palette.primary.dark,
  lineHeight: 1.6,
}));

const LabelDisplay = ({ timeInfo, questionCount }) => {
  return (
    <Box display="flex" alignItems="flex-start" style={{ borderBottom: "1px solid #A9DBAA" }}>
      <Clock size={16} color="#A9DBAA" />
      <Info style={{ marginLeft: "4px" }}>{timeInfo}</Info>
      <Info style={{ marginLeft: "16px" }}>{`${questionCount} асуулт`}</Info>
    </Box>
  );
};

export default function GridCardExam(props) {
  const navigate = useNavigate();

  const onClickNavigate = (id) => {
    if (props.noClick === true) return;
    navigate(`detail/${id}`);
  };

  return (
    <Grid2 container spacing={2} alignItems="stretch">
      {props?.data &&
        props.data.map((item, index) => (
          <Grid2 xs={12} sm={6} md={4} lg={3} key={index}>
            {item.posted ? (
              <StyledCard onClick={() => onClickNavigate(item.id)}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Box>
                      <Info>{"Мэдлэг шалгах тест"}</Info>
                      <Name
                        sx={{
                          height: "40px", // Set a fixed height
                          overflow: "hidden", // Prevent overflow if text is long
                          display: "flex",
                          alignItems: "center", // Align text vertically in the middle
                          lineHeight: "20px", // Adjust the line height to ensure it fits two lines
                        }}
                      >
                        {item.name}
                      </Name>
                    </Box>
                  </Box>
                  <LabelDisplay timeInfo={item.timeInfo} questionCount={item.questionCount}></LabelDisplay>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "16px",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "var(--surface-white)",
                        borderRadius: "16px",
                        borderColor: "var(--stroke-primary-soft)",
                        padding: "4px 12px 4px 8px",
                        textTransform: "none",
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "left",
                      }}
                      startIcon={<PlusCircle color="var(--success-success)" />}
                    >
                      <LabelLabelSmall color={"var(--success-success)"}>{item.correctGivePoint}</LabelLabelSmall>
                    </Button>
                  </Box>
                </CardContent>
              </StyledCard>
            ) : (
              <StyledCardNotPosted onClick={() => onClickNavigate(item.id)}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Box>
                      <Info>{"Мэдлэг шалгах тест"}</Info>
                      <Name
                        sx={{
                          height: "40px", // Set a fixed height
                          overflow: "hidden", // Prevent overflow if text is long
                          display: "flex",
                          alignItems: "center", // Align text vertically in the middle
                          lineHeight: "20px", // Adjust the line height to ensure it fits two lines
                        }}
                      >
                        {item.name}
                      </Name>
                    </Box>
                  </Box>
                  <LabelDisplay timeInfo={item.timeInfo} questionCount={item.questionCount}></LabelDisplay>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "16px",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "var(--surface-white)",
                        borderRadius: "16px",
                        borderColor: "var(--stroke-primary-soft)",
                        padding: "4px 12px 4px 8px",
                        textTransform: "none",
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "left",
                      }}
                      startIcon={<PlusCircle color="gray" />}
                    >
                      <LabelLabelSmall color="gray">{item.correctGivePoint}</LabelLabelSmall>
                    </Button>
                  </Box>
                </CardContent>
              </StyledCardNotPosted>
            )}
          </Grid2>
        ))}
    </Grid2>
  );
}
