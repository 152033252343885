import { Card, CardContent, Box, Grid2 } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledCard = styled(Card)(({ theme }) => ({
  "width": "350px",
  "height": "256px",
  "padding": "16px",
  "border": "1px solid #A9DBAA ",
  "borderRadius": "8px",
  "boxShadow": "0px 4px 10px rgba(0, 0, 0, 0.1)",
  "transition": "transform 0.2s ease-in-out",
  "cursor": "pointer",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
  },
}));

const Name = styled("h6")(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "600",
  color: theme.palette.primary.dark,
  lineHeight: "24px",
}));

const Info = styled("p")(({ theme }) => ({
  fontSize: "12px",
  color: theme.palette.primary.dark,
  lineHeight: 1.6,
}));

export default function GridCardStep(props) {
  const onClickNavigate = (item) => {
    if (props.onClick) props.onClick(item);
  };

  return (
    <Grid2 container spacing={2} alignItems="stretch">
      {props.data.map((item, index) => (
        <Grid2 xs={12} sm={6} md={4} lg={3} key={index}>
          <StyledCard onClick={() => onClickNavigate(item)}>
            <CardContent>
              <Box>
                <Name>{item.name}</Name>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={item.imageUrl}
                  alt={item.name}
                  style={{
                    width: 318,
                    height: 188,
                    marginRight: "10px",
                    border: "1px solid #f2f2f2",
                    borderRadius: "4px",
                  }}
                  className="preview-image"
                />
              </Box>
            </CardContent>
          </StyledCard>
        </Grid2>
      ))}
    </Grid2>
  );
}
