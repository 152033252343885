// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.edit-button {
  background: var(--primary-soft-primary-soft);
  border: none;
  border-radius: 50%;
  padding: 8px;
  color: white;
  cursor: pointer;
  width: 48px;
  height: 48px;
}

.edit-button:hover {
  background: var(--primary-soft-on-primary-soft-h);
}

.edit-button:hover svg {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/help/contact-info/styles.css"],"names":[],"mappings":";AACA;EACE,4CAA4C;EAC5C,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,WAAW;EACX,YAAY;AACd;;AAEA;EACE,iDAAiD;AACnD;;AAEA;EACE,YAAY;AACd","sourcesContent":["\n.edit-button {\n  background: var(--primary-soft-primary-soft);\n  border: none;\n  border-radius: 50%;\n  padding: 8px;\n  color: white;\n  cursor: pointer;\n  width: 48px;\n  height: 48px;\n}\n\n.edit-button:hover {\n  background: var(--primary-soft-on-primary-soft-h);\n}\n\n.edit-button:hover svg {\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
