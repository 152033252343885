import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, Button } from "@mui/material";

import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { FormInputDropdown } from "../../../components/form/form-input-dropdown";

import { useForm } from "react-hook-form";
import { roles } from "../../../utils/method";

import { tableBodyCellStyle, tableHeaderStyle } from "../../../utils/styleMethod";
import { mainApi, getErrorMessage } from "../../../api/main";
import { toast } from "react-toastify";

export const RoleList = () => {
  const { control } = useForm({});

  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [query, setQuery] = useState({ roleName: Array.isArray(roles) && roles.length > 0 ? roles[0].value : "" });
  const [data, setData] = useState([]);

  const classessCell = tableBodyCellStyle();
  const classessHeader = tableHeaderStyle();

  const onChangeSort = (values) => setQuery({ ...query, roleName: values });

  useEffect(() => {
    if (isLoaded) return;
    getList();
    setIsLoaded(true);
  }, [isLoaded]);

  useEffect(() => {
    getList();
  }, [query.roleName]);

  const getList = async () => {
    if (isLoading) return;
    try {
      setData([]);
      setIsLoading(true);
      const res = await mainApi(`role/resource/list/${query.roleName}`, "GET", null);
      setData(res.data);
      setIsLoading(false);
    } catch (error) {
      console.log("error");
    }
  };

  const handleCheckboxChange = (systemResourceCode) => {
    setData((prevRows) => prevRows.map((row) => (row.systemResourceCode === systemResourceCode ? { ...row, checked: !row.checked } : row)));
  };

  const saveData = async () => {
    try {
      let params = {
        roleName: query.roleName,
        resourceRoleInfos: data.filter((item) => item.checked),
      };
      const res = await mainApi("role/resource/save", "POST", params);
      getList();
    } catch (error) {
      let errorMessage = getErrorMessage(error);
      toast.error("Хадгалах явцад алдаа гарлаа. " + errorMessage);
    }
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        padding: "24px 32px 24px 32px",
        marginTop: "24px",
        marginBottom: "64px",
        boxShadow: "none",
        borderRadius: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "36px",
        }}
      >
        <p className="HeadlineHeadline---Small" style={{ color: "var(--on-surface-high)" }}>
          Ажилтны тохиргоо
        </p>
        <Box
          sx={{
            display: "flex", // Enable flexbox
            flexDirection: "row", // Align items horizontally
            alignItems: "center", // Vertically center items
          }}
        >
          <FormInputDropdown
            control={control}
            name={`asc`}
            options={roles}
            sx={{ minWidth: "250px", marginRight: "12px" }}
            onChange={(selectedValue) => onChangeSort(selectedValue)}
          />
          <Button
            variant="contained"
            sx={{
              "backgroundColor": "#2e7d32",
              "borderRadius": "24px",
              "padding": "8px 24px",
              "textTransform": "none",
              "fontWeight": "bold",
              "display": "flex",
              "alignItems": "center",
              "&:hover": {
                backgroundColor: "#1b5e20",
              },
            }}
            onClick={saveData}
          >
            Хадгалах
          </Button>
        </Box>
      </Box>
      <Table aria-label="order table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
            {["№", "Үйлдэл", "Боломжтой эсэх"].map((header, index) => (
              <TableCell
                key={index}
                className={classessHeader.tableHeader}
                sx={{
                  width: index === 0 ? "80px" : index === 2 ? "200px" : "auto",
                }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={row.callId || index} className={index % 2 === 1 ? "table-row-odd" : ""}>
              <TableCell className={classessCell.tableBodyCell} sx={{ width: "80px" }}>
                {index + 1}
              </TableCell>
              <TableCell className={classessCell.tableBodyCell}>{row.systemResourceName}</TableCell>
              <TableCell
                className={classessCell.tableBodyCell}
                sx={{
                  width: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={row.checked}
                  onChange={() => {
                    handleCheckboxChange(row.systemResourceCode);
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
