import React, { useContext, useEffect, useState } from "react";

import { UserContext } from "../../context/user";
import { HeadlineHeadlineMedium } from "../../utils/styleMethod";
import { ToggleButton } from "../../components/form/buttons/toggle-button";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Pagination,
    Grid2,
    Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import {
    BodyBodyMedium,
    HeadlineHeadlineSmall,
    tableBodyCellStyle,
    tableHeaderStyle,
    TitleTitleSmall,
} from "../../utils/styleMethod";
import { StatusSpan } from "../../components/form/span";
import { userStatus } from "../../utils/method";
import { FormInputDropdown } from "../../components/form/form-input-dropdown";
import { mainApi } from "../../api/main";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import FinanceModal from "../../components/modals/Finance";

const initialQuery = {
    page: 1,
    limit: 8,
    total: 0,
    type: "FACTORY",
    itemGroupId: 0,
};

const Finance = () => {
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState(dayjs);

    const [category, setCategory] = useState([
        { id: 1, name: "ААН" },
        { id: 2, name: "Хувь хүн" },
        { id: 3, name: "Цэг" },
        { id: 4, name: "Бэлтгэх цэг" },
        { id: 4, name: "Үйлдвэр " },
    ]);

    const [selectedStatus, setSelectedStatus] = useState("Бүгд");
    const [selectedStatusId, setSelectedStatusId] = useState(1);

    const [page, setPage] = useState(0);
    const [query, setQuery] = useState(initialQuery);

    const classessCell = tableBodyCellStyle();
    const classessHeader = tableHeaderStyle();
    const { control } = useForm({});
    const [openFinanceModal, setOpenFinanceModal] = useState(false);
    const [usersData, setUsersData] = useState([
        {
            userId: 1,
            createdDate: "2024-12-01",
            organization: "Байгууллага 1",
            address: "Улаанбаатар, Баянзүрх дүүрэг",
            recyclable: "Хуванцар",
            weight: "15 кг",
            valuation: "25,000 ₮",
            payment: "Төлөгдсөн",
        },
        {
            userId: 2,
            createdDate: "2024-12-02",
            organization: "Байгууллага 2",
            address: "Дархан, Дархан-Уул аймаг",
            recyclable: "Төмөр",
            weight: "50 кг",
            valuation: "80,000 ₮",
            payment: "Хүлээгдэж байна",
        },
        {
            userId: 3,
            createdDate: "2024-12-03",
            organization: "Байгууллага 3",
            address: "Эрдэнэт, Орхон аймаг",
            recyclable: "Цаас",
            weight: "20 кг",
            valuation: "10,000 ₮",
            payment: "Төлөгдсөн",
        },
        {
            userId: 4,
            createdDate: "2024-12-04",
            organization: "Байгууллага 4",
            address: "Ховд, Ховд аймаг",
            recyclable: "Шил",
            weight: "30 кг",
            valuation: "15,000 ₮",
            payment: "Хүлээгдэж байна",
        },
        {
            userId: 5,
            createdDate: "2024-12-05",
            organization: "Байгууллага 5",
            address: "Даланзадгад, Өмнөговь аймаг",
            recyclable: "Хуванцар",
            weight: "25 кг",
            valuation: "35,000 ₮",
            payment: "Төлөгдсөн",
        },
    ]);
    const [zoneNames, setZoneNames] = useState([]);

    useEffect(() => {
        getZoneNames();
    }, []);

    const getZoneNames = async () => {
        const resZoneNames = await mainApi(
            "organization/zone/names",
            "GET",
            null
        );
        setZoneNames(resZoneNames);
    };

    const handleButtonClick = (label, id) => {
        console.log("selected label: ", label, " id : ", id);
        setSelectedStatus(label);
        setSelectedStatusId(id);
        setQuery({ ...query, itemGroupId: id });
    };

    const handleChangePage = (event, newPage) => {
        setQuery({ ...query, page: newPage });
        setPage(newPage - 1);
    };

    const onChangeFilter = (values) => {
        console.log("value -> ", values);
        setQuery({ ...query, type: values });
    };

    return (
        <Grid2>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "12px",
                }}
            >
                <HeadlineHeadlineMedium color="var(--on-surface-high)">
                    Санхүү
                </HeadlineHeadlineMedium>
            </Box>

            <Box sx={{ display: "flex", gap: 2 }}>
                {category.map((status, index) => (
                    <ToggleButton
                        key={index}
                        label={status.name}
                        onClickButton={() => {
                            handleButtonClick(status.name, status.id);
                        }}
                        textColor="var(--primary-primary)"
                        bgColor="var(--primary-soft-primary-soft)"
                        selectedStatus={selectedStatus}
                    />
                ))}
            </Box>

            <TableContainer
                component={Paper}
                sx={{
                    padding: "24px 32px 24px 32px",
                    marginTop: "24px",
                    marginBottom: "64px",
                    boxShadow: "none",
                    borderRadius: "12px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "36px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <HeadlineHeadlineSmall color="var(--on-surface-high)">
                            Хэрэглэгчийн дэлгэрэнгүй
                        </HeadlineHeadlineSmall>
                        <span> | </span>{" "}
                        <BodyBodyMedium color="var(--on-surface-medium-neutral)">
                            Нийт {query.total} Аккоунт
                        </BodyBodyMedium>
                    </Box>

                    <FormInputDropdown
                        control={control}
                        name={`asc`}
                        options={userStatus}
                        sx={{ minWidth: "300px", marginRight: "12px" }}
                        onChange={(selectedValue) =>
                            onChangeFilter(selectedValue)
                        }
                    />

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={selectedDate}
                            onChange={(newDate) => setSelectedDate(newDate)}
                        />
                    </LocalizationProvider>
                </Box>
                <Table aria-label="order table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                            {[
                                "Огноо",
                                "Байгууллага",
                                "Хаяг",
                                "Дахивар",
                                "Жин",
                                "Үнэлгээ",
                                "Төлбөр",
                                "Бүгдийг харах",
                            ].map((header) => (
                                <TableCell
                                    key={header}
                                    className={classessHeader.tableHeader}
                                >
                                    <TitleTitleSmall color="var(--on-surface-high)">
                                        {header}
                                    </TitleTitleSmall>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usersData?.map((row, index) => (
                            <TableRow
                                key={index}
                                className={
                                    index % 2 === 1 ? "table-row-odd" : ""
                                }
                                // onClick={() => }
                                style={{ cursor: "pointer" }}
                            >
                                <TableCell
                                    className={classessCell.tableBodyCell}
                                >
                                    <BodyBodyMedium color="var(--on-surface-high)">
                                        {row.createdDate}
                                    </BodyBodyMedium>
                                </TableCell>
                                <TableCell
                                    className={classessCell.tableBodyCell}
                                >
                                    <BodyBodyMedium color="var(--on-surface-high)">
                                        {row.organization}
                                    </BodyBodyMedium>
                                </TableCell>
                                <TableCell
                                    className={classessCell.tableBodyCell}
                                >
                                    <BodyBodyMedium color="var(--on-surface-high)">
                                        {row.address}
                                    </BodyBodyMedium>
                                </TableCell>
                                <TableCell
                                    className={classessCell.tableBodyCell}
                                >
                                    <BodyBodyMedium color="var(--on-surface-high)">
                                        {row.recyclable}
                                    </BodyBodyMedium>
                                </TableCell>
                                <TableCell
                                    className={classessCell.tableBodyCell}
                                >
                                    <BodyBodyMedium color="var(--on-surface-high)">
                                        {row.weight}
                                    </BodyBodyMedium>
                                </TableCell>
                                <TableCell
                                    className={classessCell.tableBodyCell}
                                >
                                    <BodyBodyMedium color="var(--on-surface-high)">
                                        {row.valuation}
                                    </BodyBodyMedium>
                                </TableCell>
                                <TableCell
                                    className={classessCell.tableBodyCell}
                                >
                                    <BodyBodyMedium color="var(--on-surface-high)">
                                        {row.payment}
                                    </BodyBodyMedium>
                                </TableCell>
                                <TableCell>
                                    <Button onClick={()=> setOpenFinanceModal(true)}> дэлгэрэнгүй</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {/* Custom Pagination */}
                <Box display="flex" justifyContent="center" mt={2}>
                    <Pagination
                        count={Math.ceil(query.total / 8)}
                        page={page + 1}
                        onChange={handleChangePage}
                        siblingCount={1}
                        boundaryCount={1}
                    />
                </Box>
            </TableContainer>

            <FinanceModal
                open={openFinanceModal}
                handleClose={() => setOpenFinanceModal(false)}
                data={usersData}
            />
        </Grid2>
    );
};

export default Finance;
