import React from "react";
import { Modal, Box, Button, Divider } from "@mui/material";
import {
  BodyBodyMedium,
  LabelLabelLarge,
  TitleTitleLarge,
} from "../../utils/styleMethod";
import { XCircle } from "@phosphor-icons/react";

const WarningModal = ({ title, content, buttonText, open, handleClose }) => {
  return (
    <Modal open={open} onClose={() => handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: '340px', // Updated width
          // height: {heightSize}, // Updated height
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          // justifyContent: 'center',
          alignItems: "center",
        }}
      >
        <XCircle weight="fill" size={32} color="red" />

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign={"center"}
          width="100%"
          mb={2}
        >
          <TitleTitleLarge>{title}</TitleTitleLarge>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign={"center"}
          width="100%"
          mb={5}
        >
          <BodyBodyMedium>{content}</BodyBodyMedium>
        </Box>
        <Divider />
        <Button
          sx={{
            width: "100%",
            height: "45px",
            border: "1px solid #368137",
            borderRadius: "16px",
            padding: "16px",
            color: "#368137",
          }}
          variant="contained"
          onClick={handleClose}
        >
          <LabelLabelLarge color="white">
            {buttonText ? buttonText : "Ойлголоо"}
          </LabelLabelLarge>
        </Button>
      </Box>
    </Modal>
  );
};

export default WarningModal;
