import { useState } from "react";
import { Box, Button, Grid2, IconButton, Menu, MenuItem } from "@mui/material";
import { ArrowLeft, DotsThreeVertical, Pencil, Trash, UserMinus, UserPlus } from "@phosphor-icons/react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  BodyBodyLarge,
  BodyBodyMedium,
  BodyBodySmall,
  HeadlineHeadlineSmall,
  tableBodyCellStyle,
  tableHeaderStyle,
  TitleTitleMedium,
  TitleTitleMid,
} from "../../../../../utils/styleMethod";

import { mainApi, getErrorMessage } from "../../../../../api/main";

import GridCardStep from "../../../../../components/grid-card-component/GridCardStep";
import YesNoDialog from "../../../../../components/modals/YesNoDialog";

import ItemGroupModal from "../itemGroupModal";
export default function ItemGroupInfo({ data }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [acceptableModalOpen, setAcceptableModalOpen] = useState(false);

  const closeEditModal = () => setShowEditModal(false);
  const acceptableModalClose = () => {
    setAcceptableModalOpen(false);
    setAnchorEl(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteData = async () => {
    try {
      const res = await mainApi(`itemGroups/admin/type/${data?.id}`, "DELETE", null);

      acceptableModalClose();
      toast.success("Амжилттай устгалаа");
      navigate(`/guide/${data?.itemsGroupId}`);
    } catch (error) {
      let errorMessage = getErrorMessage(error);
      toast.error("Устгах явцад алдаа гарлаа. " + errorMessage);
    }
  };

  return (
    <Box
      sx={{
        // border: "1px solid #E0E0E0",
        borderRadius: "10px",
        p: "24px 32px",
        backgroundColor: "var(--surface-white)",
        width: "100%",
        maxWidth: "100vw",
        margin: "0 auto",
        mt: 2,
        boxSizing: "border-box",
      }}
    >
      <Grid2 container spacing={2} mb={4}>
        {/* Logo Section */}
        <Grid2 xs={12} md={2}>
          <img
            src={data?.imageUrl}
            alt={data?.name}
            style={{
              width: 80,
              height: 80,
              border: "1px solid #ccc",
              borderRadius: "12px",
            }}
          />
        </Grid2>

        {/* Company Information Section */}
        <Grid2 xs={12} md={9} sx={{ flexGrow: 1 }}>
          <Box display="flex" alignItems="center">
            <Box sx={{ flexGrow: 1, paddingTop: "12px", paddingLeft: "12px" }}>
              <HeadlineHeadlineSmall color="var(--on-surface-high)">{data?.nameEn}</HeadlineHeadlineSmall>
              <TitleTitleMedium color="var(--on-surface-high)">{data?.name}</TitleTitleMedium>
            </Box>
            {/* More options icon using Phosphor */}
            <IconButton
              onClick={handleClick}
              sx={{
                "&:hover": { color: "#4CAF50" }, // Change color on hover
              }}
            >
              <DotsThreeVertical size={24} weight="bold" />
            </IconButton>
          </Box>
        </Grid2>
      </Grid2>
      <hr style={{ color: "var(--stroke-secondary-soft)" }}></hr>
      {/* About Section */}
      <Box mt={2} sx={{ width: "100%", pb: 4 }} mb={4} style={{ paddingTop: "16px" }}>
        <TitleTitleMedium color="var(--on-surface-high)">Тухай</TitleTitleMedium>
        <BodyBodyLarge color="var(--on-surface-high)">{data?.description}</BodyBodyLarge>
        <BodyBodyLarge color="var(--on-surface-high)">{data?.descriptionEn}</BodyBodyLarge>
        <hr style={{ color: "var(--stroke-secondary-soft)" }}></hr>
        <TitleTitleMedium color="var(--on-surface-high)" style={{ marginBottom: "16px" }}>
          Хэрхэн ангилах вэ?
        </TitleTitleMedium>
        {data?.steps && <GridCardStep data={data?.steps} />}
      </Box>

      <ItemGroupModal
        key={"itemGroupModal1"}
        open={showEditModal}
        handleClose={closeEditModal}
        groupType={"ItemGroup"}
        isEditMode={true}
        data={data}
      />

      <YesNoDialog
        title={"Та зааврын мэдээллийг устгахдаа итгэлтэй байна уу?"}
        content={""}
        open={acceptableModalOpen}
        handleClose={acceptableModalClose}
        handleConfirm={deleteData}
      />

      {/* Dropdown Menu */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            setShowEditModal(true);
            setAnchorEl(false);
          }}
        >
          {" "}
          <Pencil style={{ marginRight: "12px" }} color="var( --on-surface-high)" size={16} weight="light" />
          <BodyBodyMedium color="var( --on-surface-high)">Засварлах</BodyBodyMedium>
        </MenuItem>
        <MenuItem onClick={() => setAcceptableModalOpen(true)}>
          <Trash style={{ marginRight: "12px" }} color="var( --on-surface-high)" size={16} weight="light" />
          <BodyBodyMedium color="var( --on-surface-high)">Устгах</BodyBodyMedium>
        </MenuItem>
      </Menu>
    </Box>
  );
}
