import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid2, Paper, TableContainer, Typography } from "@mui/material";
import { Box, Button } from "@mui/material";
import { UserContext } from "../../context/user";
import { Plus } from "@phosphor-icons/react";
import { ToggleButton } from "../../components/form/buttons/toggle-button";
import { mainApi } from "../../api/main";
import ArrowLeftBtn from "../../components/form/buttons/arrow-left";
import ArrowRightBtn from "../../components/form/buttons/arrow-right";
import GridCardExam from "../../components/grid-card-exam";
import { HeadlineHeadlineMedium } from "../../utils/styleMethod";
import CreateOrganizationModal from "../../components/modals/CreateOrganization";

const initialQuery = {
  page: 1,
  limit: 8,
  total: 0,
};

const ExamList = () => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  const [query, setQuery] = useState(initialQuery);

  const [list, setList] = useState();

  const [openOrganizationModal, setOpenOrganizationModal] = useState(false);

  useEffect(() => {
    getList();
  }, [query]);

  const getList = useCallback(async () => {
    try {
      const res = await mainApi("exams/admin/list/mn", "GET", query);
      setList(res.content);

      setQuery((prevQuery) => {
        if (prevQuery.total !== res.totalElements) {
          return { ...prevQuery, total: res.totalElements };
        }
        return prevQuery; //
      });
    } catch (err) {
      console.error("Exam fetch error:", err);
    } finally {
      console.log("end");
    }
  }, [query]);

  const onClickButtonLeft = async () => {
    setQuery({ ...query, page: query.page - 1 });
    getList();
  };
  const onClickButtonRight = async () => {
    setQuery({ ...query, page: query.page + 1 });
    getList();
  };

  return (
    <Grid2>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        <HeadlineHeadlineMedium color="var(--on-surface-high)">Шалгалт</HeadlineHeadlineMedium>
        <Button
          variant="contained"
          sx={{
            "backgroundColor": "#2e7d32",
            "borderRadius": "24px",
            "padding": "8px 24px",
            "textTransform": "none",
            "fontWeight": "bold",
            "display": "flex",
            "alignItems": "center",
            "&:hover": {
              backgroundColor: "#1b5e20",
            },
          }}
          startIcon={<Plus />}
          onClick={() => navigate(`/exam/create`)}
        >
          Шинэ шалгалт нэмэх
        </Button>
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          padding: "24px 32px 24px 32px",
          marginTop: "24px",
          marginBottom: "64px",
          boxShadow: "none",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "36px",
          }}
        >
          <p className="HeadlineHeadline---Small" style={{ color: "var(--on-surface-high)" }}>
            Шалгалтын жагсаалт <span> | </span> <span className="BodyBody---Medium"> Нийт {query.total} шалгалт</span>{" "}
          </p>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ArrowLeftBtn onClick={() => onClickButtonLeft()} />
            <ArrowRightBtn onClick={() => onClickButtonRight()} />
          </Box>
        </Box>
        {/* {factoryData ? <CompanyGrid data={factoryData} /> : null} */}
        {list ? <GridCardExam data={list} type="Exam" /> : null}
      </TableContainer>
    </Grid2>
  );
};

export default ExamList;
