import React, { useEffect, useState } from "react";
import {
    Dialog,
    Box,
    Button,
    TextField,
    MenuItem,
    Typography,
    Checkbox,
    InputAdornment,
    IconButton,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { X, MapPin, Camera, Eye, EyeSlash } from "@phosphor-icons/react";
import {
    BodyBodyMedium,
    BodyBodySmall,
    HeadlineHeadlineMedium,
} from "../../utils/styleMethod";
import GoogleMapLocationMapModal from "./GoogleMapLocationModal";
import { mainApi } from "../../api/main";
import { toast } from "react-toastify";
import CameraImageUpload from "../camera-upload";
import { useNavigate } from "react-router-dom";

const bankOptions = [
    { id: "khan", label: "Khan Bank" },
    { id: "golomt", label: "Golomt Bank" },
    { id: "tdb", label: "TDB Bank" },
];

const UpdateOrganizationModal = ({
    open,
    handleClose,
    Title,
    userId,
    setQuery,
    initialQuery,
    update,
    type,
    itemList,
    roleName,
    organizationData, 
}) => {
    const {
        control,
        handleSubmit,
        register,
        clearErrors,
        setError,
        reset,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            registrationNumber: "",
            email: "",
            address: "",
            bank: "",
            accountNumber: "",
            imageUrl: "",
            item: [],
        },
    });
    const navigate = useNavigate();


    const [imageUrl, setImageUrl] = useState(
        "/images/default-organization.png"
    );
    const [openMapModal, setOpenMapModal] = useState(false);
    const [addressInfo, setAddressInfo] = useState();

    useEffect(() => {
        if (update && organizationData) {
            reset({
                registrationNumber: organizationData.registrationNumber,
                email: organizationData.email,
                address: organizationData.address,
                addressDetail: organizationData.address,
                bank: organizationData.bankName,
                accountNumber: organizationData.bankAccount,
                imageUrl:
                    organizationData.profileImage ||
                    "/images/default-organization.png",
                item: organizationData.itemIds || [],
                phoneNumber: organizationData.phoneNumber,
                orgName: organizationData.name,
                description: organizationData.description,
                lat: organizationData.lat,
                lng: organizationData.lng,
            });
            setImageUrl(
                organizationData.profileImage ||
                    "/images/default-organization.png"
            );
            setAddressInfo({
                address: organizationData.address,
                lat: organizationData.lat,
                lng: organizationData.lng,
            });
        }
    }, [update, organizationData, reset]);

    const onSubmit = async (data) => {
        if (!addressInfo) {
            setError("mapMethod", {
                type: "manual",
                message: "Хаяг сонгоно уу",
            });
            return;
        }
      
        const formData = {
            phoneNumber: data.phoneNumber,
            name: data.orgName,
            email: data.email,
            address: data.addressDetail,
            lat: addressInfo.lat,
            lng: addressInfo.lng,
            type: type,
            bankAccount: data.accountNumber,
            bankName: data.bank,
            description: data.description,
            profileImage: imageUrl,
            itemIds: data.item,
            password: data.password,
            role: roleName,
            id: organizationData.organizationId,
        };

        try {
            const res = await mainApi(`organization/update`, "PUT", formData);
            reset();
            setImageUrl("/images/default-organization.png");
            setAddressInfo(null);
            handleClose();
            toast.success("Бүртгэл шинэчлэгдлээ" );
            
            switch (organizationData.type) {
              case 'FACTORY':
                navigate('/factory');
                break;
              case 'SUH':
                navigate('/enterprise');
                break;
              case 'DROPOFF':
                navigate('/dropoff');
                break;
              default:
                console.error(`Unknown type: ${organizationData.type}`);
            }
        
            
        } catch (error) {
            toast.warning("aldaa garlaa")
        }
    };

    return (
        <Dialog
            open={open}
            PaperProps={{
                style: {
                    width: "650px",
                    maxWidth: "95vw",
                },
            }}
        >
            <Box
                sx={{
                    position: "relative",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    borderRadius: 2,
                    pl: 6,
                    pb: 6,
                    pt: 2,
                    pr: 6,
                }}
            >
                <Box
                    display="flex"
                    width="100%"
                    pb={3}
                    pt={1}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                        textAlign="center"
                        flexGrow={1}
                    >
                        <HeadlineHeadlineMedium color="var(--on-surface-high)">
                            {Title}
                        </HeadlineHeadlineMedium>
                    </Box>
                    <Box>
                        <Button onClick={handleClose} sx={{ minWidth: "auto" }}>
                            <X size={24} color="#4caf50" />
                        </Button>
                    </Box>
                </Box>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        display="flex"
                        flexDirection="row"
                        flexWrap="wrap"
                        gap={2}
                        pb={2}
                        justifyContent="space-between"
                    >
                        <Box
                            sx={{
                                position: "relative",
                                width: "45%",
                                height: "150px",
                            }}
                        >
                            {/* Camera Icon */}
                            <div
                                style={{
                                    position: "absolute",
                                    zIndex: 2,
                                    backgroundColor: "#368137",
                                    borderRadius: "50%",
                                    width: "36px",
                                    height: "36px", // Corrected the height from "6px" to "36px"
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    bottom: "4px",
                                    right: "4px",
                                }}
                            >
                                <CameraImageUpload
                                    type={"profile"}
                                    setImageUrl={setImageUrl}
                                />
                            </div>

                            {/* Profile Image */}
                            <img
                                src={
                                    imageUrl ||
                                    "/images/default-organization.png"
                                }
                                alt={imageUrl}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "8px",
                                    objectFit: "contain",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    zIndex: 1,
                                    border: "solid 2px #3F9740",
                                }}
                            />
                        </Box>

                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={2}
                            sx={{ width: "48%" }}
                        >
                            <Box>
                                <BodyBodySmall color="var(--on-surface-high)">
                                    Байгууллагын нэр
                                </BodyBodySmall>
                                <TextField
                                    {...register("orgName", {
                                        required: "Нэр заавал оруулна уу",
                                        minLength: {
                                            value: 2,
                                            message:
                                                "Нэр хамгийн багадаа 2 тэмдэгттэй байх ёстой",
                                        },
                                    })}
                                    error={!!errors.orgName}
                                    helperText={errors.orgName?.message}
                                    fullWidth
                                    InputProps={{ sx: { height: "45px" } }}
                                />
                            </Box>
                            <Box>
                                <BodyBodySmall color="var(--on-surface-high)">
                                    Утасны дугаар
                                </BodyBodySmall>
                                <TextField
                                    {...register("phoneNumber", {
                                        required:
                                            "Утасны дугаарыг заавал оруулна уу",
                                        pattern: {
                                            value: /^[0-9]{8}$/,
                                            message:
                                                "Утасны дугаар 8 оронтой тоо байх ёстой",
                                        },
                                    })}
                                    error={!!errors.phoneNumber}
                                    helperText={errors.phoneNumber?.message}
                                    fullWidth
                                    InputProps={{ sx: { height: "45px" } }}
                                />
                            </Box>
                        </Box>
                    </Box>

                    <Box display="flex" flexDirection="column" gap={2}>
                        {/* Phone Number Field */}
                        <Box
                            display="flex"
                            flexDirection="row"
                            flexWrap="wrap"
                            gap={2}
                        >
                            {/* Email Field */}
                            <Box flex="1 1 45%">
                                <BodyBodySmall color="var(--on-surface-high)">
                                    Имэйл хаяг
                                </BodyBodySmall>
                                <TextField
                                    {...register("email", {
                                        required: "Имэйл хаяг оруулна уу",
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message:
                                                "Зөв имэйл хаяг оруулна уу",
                                        },
                                    })}
                                    error={!!errors.email}
                                    helperText={errors.email?.message}
                                    fullWidth
                                    InputProps={{ sx: { height: "45px" } }}
                                />
                            </Box>
                        </Box>

                        <Box flex="1 1 45%">
                            <BodyBodySmall color="var(--on-surface-high)">
                                Дахивар төрөл (олон сонгох боломжтой)
                            </BodyBodySmall>
                            <Controller
                                name="item"
                                control={control}
                                rules={{ required: "Дахивар төрөл сонгоно уу" }}
                                render={({ field }) => (
                                    <TextField
                                        select
                                        {...field}
                                        value={field.value ?? []}
                                        error={!!errors.item}
                                        helperText={errors.item?.message}
                                        fullWidth
                                        InputProps={{ sx: { height: "45px" } }}
                                        SelectProps={{
                                            multiple: true,
                                            renderValue: (selected) =>
                                                selected
                                                    .map((id) => {
                                                        const item =
                                                            itemList.find(
                                                                (item) =>
                                                                    item.id ===
                                                                    id
                                                            );
                                                        return item
                                                            ? item.name
                                                            : "";
                                                    })
                                                    .join(", "),
                                        }}
                                    >
                                        {itemList.map((item) => (
                                            <MenuItem
                                                key={item.id}
                                                value={item.id}
                                            >
                                                <Checkbox
                                                    checked={field.value?.includes(
                                                        item.id
                                                    )}
                                                />
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />
                        </Box>

                        {/* Map Selection Button */}
                        <Box>
                            <Box>
                                <BodyBodySmall color="var(--on-surface-high)">
                                    Хаяг
                                </BodyBodySmall>
                                <Box
                                    display={"flex"}
                                    flexDirection={"row"}
                                    gap={2}
                                >
                                    <TextField
                                        value={addressInfo?.address || ""}
                                        disabled
                                        fullWidth
                                        InputProps={{ sx: { height: "45px" } }}
                                    />
                                    <Button
                                        variant="outlined"
                                        onClick={() => setOpenMapModal(true)}
                                        startIcon={<MapPin size={20} />}
                                        fullWidth
                                        sx={{ width: "90px" }}
                                    >
                                        Map
                                    </Button>
                                </Box>
                                {errors.mapMethod && (
                                    <Typography color="error" variant="body2">
                                        {errors.mapMethod.message}
                                    </Typography>
                                )}
                            </Box>

                            <BodyBodySmall color="var(--on-surface-high)">
                                Хаягын дэлгэрэнгүй
                            </BodyBodySmall>
                            <TextField
                                {...register("addressDetail")}
                                fullWidth
                                InputProps={{ sx: { height: "45px" } }}
                            />
                        </Box>

                        <Box
                            display="flex"
                            flexDirection="row"
                            flexWrap="wrap"
                            gap={2}
                        >
                            {/* Bank Selection */}
                            <Box flex="1 1 45%">
                                <BodyBodySmall color="var(--on-surface-high)">
                                    Орлого хүлээн авах банк
                                </BodyBodySmall>
                                <TextField
                                    select
                                    {...register("bank", {
                                        required: "Банк сонгоно уу",
                                    })}
                                    error={!!errors.bank}
                                    helperText={errors.bank?.message}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{ sx: { height: "45px" } }}
                                    value={watch("bank") || ""}
                                >
                                    {bankOptions.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.label}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>

                            {/* Account Number Field */}
                            <Box flex="1 1 45%">
                                <BodyBodySmall color="var(--on-surface-high)">
                                    Дансны дугаар
                                </BodyBodySmall>
                                <TextField
                                    {...register("accountNumber", {
                                        required: "Дансны дугаар оруулна уу",
                                        minLength: {
                                            value: 10,
                                            message:
                                                "Дансны дугаар 10 оронтой байх ёстой",
                                        },
                                        maxLength: {
                                            value: 12,
                                            message:
                                                "Дансны дугаар 12 оронтой байх ёстой",
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message:
                                                "Дансны дугаар зөвхөн тооноос бүрдсэн байх ёстой",
                                        },
                                    })}
                                    error={!!errors.accountNumber}
                                    helperText={errors.accountNumber?.message}
                                    fullWidth
                                    InputProps={{ sx: { height: "45px" } }}
                                />
                            </Box>
                        </Box>

                        <Box flex="1 1 100%">
                            <BodyBodySmall color="var(--on-surface-high)">
                                Дэлгэрэнгүй
                            </BodyBodySmall>
                            <TextField
                                {...register("description", {
                                    required: "Дэлгэрэнгүй мэдээлэл оруулна уу",
                                    minLength: {
                                        value: 10,
                                        message:
                                            "Мэдээлэл 10 тэмдэгтээс багагүй байна",
                                    },
                                })}
                                error={!!errors.description}
                                helperText={errors.description?.message}
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                                InputProps={{ sx: { height: "auto" } }}
                            />
                        </Box>
                    </Box>

                    <Box
                        display="flex"
                        gap={1}
                        justifyContent="flex-end"
                        pt={5}
                    >
                        <Button onClick={handleClose} variant="outlined">
                            <BodyBodyMedium color="var(--on-surface-medium)">
                                Болих
                            </BodyBodyMedium>
                        </Button>
                        <Button type="submit" variant="contained">
                            <BodyBodyMedium color="var(--on-primary)">
                                Бүртгэх
                            </BodyBodyMedium>
                        </Button>
                    </Box>
                </form>
            </Box>{" "}
            {openMapModal && (
        <GoogleMapLocationMapModal
          open={openMapModal}
          handleClose={() => setOpenMapModal(false)}
          setAddressInfo={setAddressInfo}
          roleName="FactoryAdmin"
        />
      )} 
        </Dialog>
    );
};

export default UpdateOrganizationModal;
