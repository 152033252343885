import { BodyBodySmall } from "../../../utils/styleMethod";

export const FeedbackStatusSpan = ({ label, statusColor, handleClick }) => {
  return (
    <button
      style={{
        border: `1px solid ${statusColor.borderColor}`,
        backgroundColor: `${statusColor.backgroundColor}`,
        color: statusColor.textColor,
        padding: "4px 12px",
        borderRadius: "24px",
        fontWeight: "bold",
        display: "inline-block",
        minWidth: "150px",
        textAlign: "center",
      }}
      onClick={handleClick}
    >
      <BodyBodySmall color={statusColor.textColor}>{label}</BodyBodySmall>
    </button>
  );
};
