import React, { useRef, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Box, useMediaQuery } from "@mui/material";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import { useForm } from "react-hook-form";
import { FormInputDropdown } from "../../form/form-input-dropdown";
import ArrowLeftBtn from "../../form/buttons/arrow-left";
import ArrowRightBtn from "../../form/buttons/arrow-right";
import { months } from "../../../utils/method";
import { mainApi } from "../../../api/main";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// Register the necessary ChartJS components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const LineChart = () => {
    const { handleSubmit, control } = useForm({});
    const [lineChartData, setLineChartData] = useState([]);
    const [selectDate, setSelectDate] = useState(dayjs());
    const chartRef = useRef(null);
    const isSmallScreen = useMediaQuery("(max-width: 600px)");
    const isMediumScreen = useMediaQuery("(max-width: 960px)");

    useEffect(() => {
        getLineChartData();
    }, [selectDate]);

    useEffect(() => {
        if (chartRef.current) {
            const chart = chartRef.current;
            const ctx = chart.ctx;

            // Create gradient for the chart background
            const gradient = ctx.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, "#10933629"); // Dark green
            gradient.addColorStop(1, "#FFFFFF2D"); // Light green

            chart.data.datasets[0].backgroundColor = gradient;
            chart.update();
        }
    }, [lineChartData]);

    const getLineChartData = async () => {
        try {
            const res = await mainApi(
                `dashboard/line-chart/${selectDate.format("YYYY-MM-DD")}`,
                "GET",
                null
            );
            if (res?.data) {
                setLineChartData(res.data);
            }
        } catch (error) {
            console.error("Error fetching chart data:", error);
        }
    };

    let data = {
        labels: [],
        datasets: [
            {
                label: "Дахиврын дэлгэрэнгүй",
                data: [],
                borderColor: "#2e7d32",
                backgroundColor: "", // Gradient will be set dynamically
                fill: true,
                pointBackgroundColor: "#2e7d32",
                tension: 0, // Straight line
            },
        ],
    };

    if (lineChartData.length > 0) {
        const apiLabels = lineChartData.map((item) => item[0]);
        const apiValues = lineChartData.map((item) => item[1]);

        // Merge API labels and values into the chart
        data.labels = Array.from(new Set([...data.labels, ...apiLabels]));
        data.datasets[0].data = data.labels.map((label) => {
            const index = apiLabels.indexOf(label);
            return index !== -1
                ? apiValues[index]
                : data.datasets[0].data[data.labels.indexOf(label)] || 0;
        });
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: false,
                ticks: {
                    callback: (value) => value + "₮",
                    stepSize: 400000,
                    color: "#153216", // Y-axis label color
                    font: {
                        size: 12,
                        family: "NotoSans",
                    },
                },
            },
            x: {
                ticks: {
                    color: "#153216", // X-axis label color
                    font: {
                        size: 12,
                        family: "NotoSans",
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                titleFont: {
                    size: 14,
                },
                bodyFont: {
                    size: 12,
                },
                borderWidth: 1,
            },
        },
    };

    const handleChange = (newValue) => {
        setSelectDate(newValue);
    };

    const onClickLeft = () => {
        const updatedValue = selectDate.subtract(1, "month");
        setSelectDate(updatedValue);
        console.log("Date after subtracting 1 day:", updatedValue.format("YYYY-MM-DD"));
    };

    const onClickRight = () => {
        const updatedValue = selectDate.add(1, "month");
        setSelectDate(updatedValue);
        console.log("Date after adding 1 day:", updatedValue.format("YYYY-MM-DD"));
    };

    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                padding: "16px 24px 16px 32px",
                marginTop: isSmallScreen ? "16px" : "24px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                width: isSmallScreen
                    ? "350px"
                    : isMediumScreen
                    ? "700px"
                    : "100%",
            }}
        >
            {/* Title and Controls */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: isSmallScreen ? "column" : "row",
                }}
            >
                <p
                    style={{
                        color: "var(--on-surface-high)",
                        paddingTop: "24px",
                        paddingBottom: isSmallScreen ? "16px" : "24px",
                    }}
                >
                    Худалдан авсан дахиврын дэлгэрэнгүй
                </p>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={selectDate}
                            onChange={handleChange}
                            views={["month"]}
                            format="YYYY-MM"
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                },
                            }}
                        />
                    </LocalizationProvider>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ArrowLeftBtn
                            onClick={() => {
                                onClickLeft();
                            }}
                        />
                        <ArrowRightBtn onClick={() => onClickRight()} />
                    </Box>
                </Box>
            </Box>

            {/* Line Chart */}
            <Box
                sx={{
                    height: isSmallScreen ? "250px" : "400px",
                    width: "100%",
                }}
            >
                <Line ref={chartRef} data={data} options={options} />
            </Box>
        </Box>
    );
};

export default LineChart;
