import React from "react";
import { Grid2 } from "@mui/material";
import Dashboard from "../components/home/dashboard/DashBoard";
import LineChart from "../components/home/line-chart/LineChart";
import OrderTable from "../components/home/order-table/OrderTable";


const HomePage = () => {
  return (
    <Grid2>
      <p className="HeadlineHeadline---Medium" style={{color:"var(--on-surface-high)"}}>Нүүр</p>
      <Dashboard />
      <LineChart />
      <OrderTable />
      
    </Grid2>
  );
};

export default HomePage;
