import React, { useEffect, useState } from "react";
import { Box, Grid2 } from "@mui/material";
import { useParams } from "react-router-dom";
import { mainApi } from "../../../api/main";
import { toast } from "react-toastify";
import CustomerBoxDetail from "../../../components/customer/box-detail";
import { HeadlineHeadlineMedium } from "../../../utils/styleMethod";
import { TransferToDriverTable } from "../../../components/call/transfer-to-driver-table";
import { ArrowUpRight, Users } from "@phosphor-icons/react";
import StatCard from "../../../components/home/dashboard/StatCard";

const CustomerDetail = () => {
    const { id } = useParams();
    const initQuery = {
        page: 1,
        limit: 8,
        sort: "asc",
        status: null,
        keyWord: id,
        searchType: "user.id",
        total: 0,
    };
    const [userData, setUserData] = useState({});
    const [query, setQuery] = useState(initQuery);
    const [callData, setCallData] = useState([]);
    const [statData, setStatData] = useState([]);

    useEffect(() => {
        if (id) {
            FindByUser();
            callList();
            statCardData();
        }
    }, []);

    const FindByUser = async () => {
        try {
            const res = await mainApi(`user/info/${id}`, "GET", null);
            setUserData(res.data);
        } catch (error) {
            toast.warning("Өгөгдөл олдсонгүй");
        }
    };

    const callList = async () => {
        try {
            const res = await mainApi("call/list", "POST", query);
            setCallData(res.content);
            setQuery((prevQuery) => {
                if (prevQuery.total !== res.totalElements) {
                    return { ...prevQuery, total: res.totalElements };
                }
                return prevQuery;
            });
        } catch (error) {
            console.log("error");
        }
    };

    const statCardData = async () => {
        try {
            const res = await mainApi(
                `dashboard/user-metrics/${id}`,
                "GET",
                null
            );
            setStatData(res.data);
        } catch (error) {}
    };

    return (
        <Grid2>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "12px",
                }}
            >
                <HeadlineHeadlineMedium color="var(--on-surface-high)">
                    Хэрэглэгчийн дэлгэрэнгүй
                </HeadlineHeadlineMedium>
            </Box>
            {id ? <CustomerBoxDetail data={userData} /> : "уншиж байна..."}
            {
                <Grid2 container spacing={2} sx={{ paddingTop: "24px" }}>
                    {statData?.map((item, index) => {
                        return (
                            <StatCard
                                title={item.name} 
                                value={`${item.initialValue} KG`}
                                icon={{
                                    component: (
                                        <Users
                                            size={24}
                                            weight="duotone"
                                            color="var(--on-surface-high)"
                                        />
                                    ),
                                    bgColor: "#c5e7c6",
                                }}
                                comparison="Өнгөрсөн улирал"
                                comparisonValue={{
                                    text: `${item?.percentage}`,
                                    color: "var(--success-error)",
                                }}
                                comparisonIcon={
                                    <ArrowUpRight
                                        size={22}
                                        weight="regular"
                                        color="var(--success-error)"
                                    />
                                }
                            />
                        );
                    })}
                </Grid2>
            }
            {id ? (
                <TransferToDriverTable
                    data={callData}
                    query={query}
                    setQuery={setQuery}
                />
            ) : (
                "өгөгдөл олдсонгүй"
            )}
            {/* {id ? <UserList data={userData} /> : "уншиж байна..."} */}
        </Grid2>
    );
};

export default CustomerDetail;
