import React, { useEffect, useState } from "react";
import {
  Dialog,
  Box,
  Button,
  Grid2,
  IconButton,
  TextField,
  MenuItem,
  Typography,
  Card,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { X, Plus, DotsThreeVertical, Trash } from "@phosphor-icons/react";

import { BodyBodyMedium, BodyBodySmall, HeadlineHeadlineMedium, TitleTitleLarge, TitleTitleMedium } from "../../../utils/styleMethod";
import ImageUpload from "../../../components/image-upload";
import VideoUpload from "../../../components/video-upload";
import { mainApi, getErrorMessage } from "../../../api/main";
import { toast } from "react-toastify";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

const ReelsModal = ({ open, handleClose, isEditMode, data }) => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const {
    control,
    handleSubmit,
    register,
    clearErrors,
    setError,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      caption: "",
      active: false,
      language: "mn",
      postedDate: tomorrow.toISOString().split("T")[0], // Format to YYYY-MM-DD,
      mainImage: "",
      reelVideo: "",
    },
  });

  const [title, setTitle] = useState("");

  useEffect(() => {
    if (open) {
      let newTitle = "Рийлс";
      newTitle = newTitle + (isEditMode ? " засах" : " нэмэх");
      setTitle(newTitle);

      if (isEditMode && data !== null && data !== undefined) {
        setValue("caption", data?.caption);
        setValue("active", data?.active);
        setValue("language", data?.language);

        setValue("mainImage", data?.mainImage);
        setValue("reelVideo", data?.reelVideo);

        const formattedDate = data?.postedDate.split("T")[0];

        // Set the formatted date value
        setValue("postedDate", formattedDate);
      } else {
        reset();
      }
    }
  }, [open]);

  const changeImageUrl = (url) => {
    setValue("mainImage", url);
  };

  const changeReelVideo = (url) => {
    setValue("reelVideo", url);
  };

  const onSubmit = async (saveData) => {
    try {
      let params = saveData;

      console.log(params);

      params.id = data?.id ?? 0;
      params.newsType = "reels";

      let images = [];

      images.push(params.mainImage);
      images.push(params.reelVideo);

      params.images = images;

      console.log(params);

      const res = await mainApi("news/admin/save", "POST", params);

      reset();
      handleClose(true);
      toast.success("Амжилттай хадгаллаа.");
    } catch (error) {
      let errorMessage = getErrorMessage(error);
      toast.error("Хадгалах явцад алдаа гарлаа. " + errorMessage);
    }
  };

  const closeModal = () => {
    reset();
    handleClose(false);
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          width: "780px",
          maxWidth: "95vw",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          pl: 6,
          pb: 6,
          pt: 2,
          pr: 6,
        }}
      >
        <Box display="flex" width="100%" pb={2} justifyContent="space-between" alignItems="center">
          <Box display="flex" justifyContent="center" textAlign="center" flexGrow={1}>
            <HeadlineHeadlineMedium color="var(--on-surface-high)">{title}</HeadlineHeadlineMedium>
          </Box>
          <Box>
            <Button onClick={closeModal} sx={{ minWidth: "auto" }}>
              <X size={24} color="#4caf50" />
            </Button>
          </Box>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid2
            container
            spacing={2}
            sx={{
              width: "1024px",
            }}
          >
            <Grid2 xs={4}>
              <Box display="flex" justifyContent="space-between">
                <Box flex="1 1 100%">
                  <BodyBodySmall color="var(--on-surface-high)">Рийл бичлэг</BodyBodySmall>
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between" gap={2}>
                <VideoUpload
                  id={"imageImageList-0"}
                  type="Reels"
                  suggestText={"Санал болгох бичлэгийн харьцаа: 487x780"}
                  onChange={changeReelVideo}
                  videoUrl={getValues("reelVideo")}
                  showError={{ isError: errors.reelVideo, message: errors.reelVideo?.message }}
                />
                <input
                  {...register(`reelVideo`, {
                    required: "Рийл бичлэг оруулна уу",
                    pattern: {
                      value: /^(https?:\/\/.*\.(?:mp4|avi|mkv|webm|mov))$/,
                      message: "Бичлэгийн мэдээлэл буруу байна",
                    },
                  })}
                  hidden={true}
                />
              </Box>
            </Grid2>
            <Grid2 xs={4}>
              <Box display="flex" justifyContent="space-between">
                <Box flex="1 1 100%">
                  <BodyBodySmall color="var(--on-surface-high)">Рийл зураг</BodyBodySmall>
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between" gap={2}>
                <ImageUpload
                  id={"imageStep-0"}
                  type="cover"
                  suggestText={"Санал болгох зургийн хэмжээ: 487x780"}
                  onChange={changeImageUrl}
                  imageUrl={getValues("mainImage")}
                  showError={{ isError: errors.mainImage, message: errors.mainImage?.message }}
                />
                <input
                  {...register(`mainImage`, {
                    required: "Рийл зураг оруулна уу",
                    pattern: {
                      value: /^(https?:\/\/.*\.(?:png|jpg|jpeg|svg|gif|webp))$/,
                      message: "Зургын мэдээлэл буруу байна",
                    },
                  })}
                  hidden={true}
                />{" "}
              </Box>
            </Grid2>
          </Grid2>

          <Box display="flex" flexDirection="column" gap={2}>
            <Box>
              <BodyBodySmall color="var(--on-surface-high)">Рийлс тайлбар</BodyBodySmall>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <TextField
                  {...register("caption", { required: "Рийлс тайлбар оруулна уу" })}
                  fullWidth
                  InputProps={{ sx: { height: "45px" } }}
                  error={!!errors.caption}
                  helperText={errors.caption?.message}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 2 }}>
              {/* Checkbox and label (left position) */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Checkbox
                  {...register("active")}
                  checked={watch("active")} // Dynamically reflect the value of `isActive`
                  onChange={(e) => setValue("active", e.target.checked)} // Update form state on change
                />
                <BodyBodySmall color="var(--on-surface-high)">Нийтлэх эсэх</BodyBodySmall>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <BodyBodySmall color="var(--on-surface-high)">Рийлс огноо</BodyBodySmall>
                <TextField
                  type="date"
                  {...register("postedDate", { required: "Рийлс огноо оруулна уу" })}
                  error={!!errors.postedDate}
                  helperText={errors.postedDate?.message}
                  sx={{ width: 180 }}
                />
              </Box>

              {/* Language selector and label (right position) */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <BodyBodySmall color="var(--on-surface-high)">Хэл сонгох</BodyBodySmall>
                <TextField
                  select
                  defaultValue="mn"
                  {...register("language", { required: "Хэл сонгоно уу" })}
                  error={!!errors.language}
                  helperText={errors.language?.message}
                  sx={{ width: 120 }}
                  InputProps={{ sx: { height: "45px" } }}
                >
                  <MenuItem value="mn">Монгол</MenuItem>
                  <MenuItem value="en">Англи</MenuItem>
                </TextField>
              </Box>
            </Box>
            {/* Submit Button */}
            <Box display="flex" justifyContent="flex-end" width="100%">
              <Button type="submit" variant="contained" color="primary" fullWidth mt={2}>
                Хадгалах
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
};

export default ReelsModal;
