import React, { useState } from "react";
import {
    Modal,
    Box,
    Button,
    TableCell,
    TableRow,
    TableBody,
    Table,
    TableHead,
    TableContainer,
} from "@mui/material";
import { X } from "@phosphor-icons/react";
import {
    BodyBodyLarge,
    BodyBodyMedium,
    BodyBodySmall,
    DisplayMedium,
    DisplaySmall,
    TitleTitleLarge,
    TitleTitleMedium,
} from "../../utils/styleMethod";

const FinanceModal = ({ open, handleClose, data }) => {
    const [usersData, setUsersData] = useState([
        {
            userId: 1,
            createdDate: "2024-12-01",
            recyclable: "Хуванцар",
            weight: "15 кг",
            valuation: "25,000 ₮",
            payment: "Төлөгдсөн",
            driver: "Жолооч 1",
            location: "Улаанбаатар, Баянзүрх дүүрэг",
        },
        {
            userId: 2,
            createdDate: "2024-12-02",
            recyclable: "Төмөр",
            weight: "50 кг",
            valuation: "80,000 ₮",
            payment: "Хүлээгдэж байна",
            driver: "Жолооч 2",
            location: "Дархан, Дархан-Уул аймаг",
        },
        {
            userId: 3,
            createdDate: "2024-12-03",
            recyclable: "Цаас",
            weight: "20 кг",
            valuation: "10,000 ₮",
            payment: "Төлөгдсөн",
            driver: "Жолооч 3",
            location: "Эрдэнэт, Орхон аймаг",
        },
        {
            userId: 4,
            createdDate: "2024-12-04",
            recyclable: "Шил",
            weight: "30 кг",
            valuation: "15,000 ₮",
            payment: "Хүлээгдэж байна",
            driver: "Жолооч 4",
            location: "Ховд, Ховд аймаг",
        },
        {
            userId: 5,
            createdDate: "2024-12-05",
            recyclable: "Хуванцар",
            weight: "25 кг",
            valuation: "35,000 ₮",
            payment: "Төлөгдсөн",
            driver: "Жолооч 5",
            location: "Даланзадгад, Өмнөговь аймаг",
        },
    ]);
    return (
        <>
            {data && (
                <Modal open={open} onClose={handleClose}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "80%",
                            height: "50vh",
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            p: 4,
                            borderRadius: 2,
                        }}
                    >
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={2}
                            width={"100%"}
                        >
                            <Box display="flex" mb={2} width={"100%"}>
                                {" "}
                                <img
                                    src={`${
                                        data.imageUrl ||
                                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBcmFc-564fzdVaz-txpjNyohU_YP3S78VAg&s"
                                    } `}
                                    alt="Recycling"
                                    style={{
                                        width: "57px",
                                        height: "47px",
                                        borderRadius: "4px",
                                        marginRight: "16px",
                                    }}
                                />
                                <TitleTitleLarge color="var(--on-surface-high)">
                                    Дуудлагын дэлгэрэнгүй
                                </TitleTitleLarge>
                            </Box>

                            <Button
                                onClick={handleClose}
                                sx={{ minWidth: "auto" }}
                            >
                                <X size={24} color="#4caf50" />
                            </Button>
                        </Box>

                        <Box>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                mb={2}
                                width={"100%"}
                            >
                                <Box display="flex" mb={2} width={"100%"}>
                                    <Box>
                                        <BodyBodySmall>Утас:</BodyBodySmall>
                                        <TitleTitleMedium>
                                            998877744
                                        </TitleTitleMedium>
                                    </Box>
                                </Box>
                                <Box display="flex" mb={2} width={"100%"}>
                                    <Box>
                                        <BodyBodySmall>Имайл хаяг:</BodyBodySmall>
                                        <TitleTitleMedium>
                                        hello@procraft.agency
                                        </TitleTitleMedium>
                                    </Box>
                                </Box>
                                <Box display="flex" mb={2} width={"100%"}>
                                    {" "}
                                    <Box>
                                        <BodyBodySmall>Хаяг:</BodyBodySmall>
                                        <TitleTitleMedium>
                                        Улаанбаатар хот, Баянзүрх дүүрэг, Улаанхуаран 56-3-15
                                        </TitleTitleMedium>
                                    </Box>
                                </Box>
                                <Box display="flex" mb={2} width={"100%"}>
                                    <Button>Гүйлгээ хийх</Button>
                                </Box>
                            </Box>
                        </Box>

                        <TableContainer
                            component={Box}
                            sx={{
                                maxHeight: "300px", // Limits the table's height
                                overflowY: "auto", // Adds vertical scroll if content overflows
                                "&::-webkit-scrollbar": {
                                    width: "8px",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                    backgroundColor: "#c1c1c1",
                                    borderRadius: "4px",
                                },
                                "&::-webkit-scrollbar-thumb:hover": {
                                    backgroundColor: "#a0a0a0",
                                },
                                "&::-webkit-scrollbar-track": {
                                    backgroundColor: "transparent",
                                },
                            }}
                        >
                            <Table aria-label="recyclable data table">
                                <TableHead>
                                    <TableRow
                                        sx={{ backgroundColor: "#f5f5f5" }}
                                    >
                                        {[
                                            "Огноо",
                                            "Дахивар",
                                            "Жин",
                                            "Үнэлгээ",
                                            "Төлбөр",
                                            "Жолооч",
                                            "Тушаасан байрлал",
                                        ].map((header) => (
                                            <TableCell
                                                key={header}
                                                style={{ fontWeight: "bold" }}
                                            >
                                                {header}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {usersData?.map((row, index) => (
                                        <TableRow
                                            key={index}
                                            className={
                                                index % 2 === 1
                                                    ? "table-row-odd"
                                                    : ""
                                            }
                                            onClick={() =>
                                                console.log(
                                                    `Clicked row ${row.userId}`
                                                )
                                            }
                                            style={{ cursor: "pointer" }}
                                        >
                                            <TableCell>
                                                {row.createdDate}
                                            </TableCell>
                                            <TableCell>
                                                {row.recyclable}
                                            </TableCell>
                                            <TableCell>{row.weight}</TableCell>
                                            <TableCell>
                                                {row.valuation}
                                            </TableCell>
                                            <TableCell>{row.payment}</TableCell>
                                            <TableCell>{row.driver}</TableCell>
                                            <TableCell>
                                                {row.location}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Modal>
            )}
        </>
    );
};

export default FinanceModal;
