import React from "react";
import { Modal, Box, Button, Typography, Avatar } from "@mui/material";
import { X, UserCircle, User } from "@phosphor-icons/react";
import {
  BodyBodyLarge,
  BodyBodyMedium,
  BodyBodySmall,
  DisplayMedium,
  DisplaySmall,
  HeadlineHeadlineSmall,
  TitleTitleLarge,
  TitleTitleMedium,
} from "../../../utils/styleMethod";
import { getColorByFeedbackStatus } from "../../../utils/method";
import { FeedbackStatusSpan } from "../../../components/form/span/FeedbackStatusSpan";

const FeedbackDetail = ({ open, handleClose, data, handleSubmit }) => {
  return (
    <>
      {data && (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Box display="flex" justifyContent="center" alignItems="center" mb={2} width={"100%"}>
              <TitleTitleLarge color="var(--on-surface-high)">Санал хүсэлт</TitleTitleLarge>
              <Button onClick={handleClose} sx={{ position: "absolute", right: "16px", minWidth: "auto" }}>
                <X size={24} color="#4caf50" />
              </Button>
            </Box>

            <Box display="flex" mb={4} gap={2}>
              {/* Avatar Section */}
              <Box
                sx={{
                  "display": "flex",
                  "alignItems": "center",
                  "&:hover": {
                    "& .avatar": {
                      transform: "scale(1.1)",
                      transition: "transform 0.2s ease",
                    },
                  },
                }}
              >
                {data.imageUrl ? (
                  <Avatar alt="User" src={data.imageUrl} className="avatar" sx={{ height: "64px", width: "64px" }} />
                ) : (
                  <UserCircle size={96} />
                )}
              </Box>

              {/* Content Section */}
              <Box flex={1}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  {/* Left Section */}
                  <Box>
                    <HeadlineHeadlineSmall color="var(--on-surface-high)" sx={{ mb: 1 }}>
                      {data?.userName}
                    </HeadlineHeadlineSmall>
                    <TitleTitleMedium color="var(--on-surface-high)">{data?.userId ?? "888888"}</TitleTitleMedium>
                  </Box>

                  {/* Right Section */}
                  <Box>
                    <FeedbackStatusSpan label={data?.statusName} statusColor={getColorByFeedbackStatus(data?.statusId)} />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box>
              <Box display="flex" alignItems="center">
                {/* Left Section */}
                <Box>
                  <BodyBodySmall color="var(--on-surface-high)" sx={{ mb: 2 }}>
                    Огноо:
                  </BodyBodySmall>
                  <TitleTitleMedium color="var(--on-surface-high)" sx={{ mb: 2 }}>
                    {data?.sendDateStr}
                  </TitleTitleMedium>
                </Box>
                {/* Right Section */}
                <Box sx={{ marginLeft: 5 }}>
                  <BodyBodySmall color="var(--on-surface-high)" sx={{ mb: 2 }}>
                    Гомдлын төрөл:
                  </BodyBodySmall>
                  <TitleTitleMedium color="var(--on-surface-high)" sx={{ mb: 2 }}>
                    {data?.feedbackTypeName ?? "-"}
                  </TitleTitleMedium>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box display="flex" alignItems="center">
                {/* Left Section */}
                <Box>
                  <BodyBodySmall color="var(--on-surface-high)" sx={{ mb: 2 }}>
                    Нэмэлт мэдээлэл:
                  </BodyBodySmall>
                  <BodyBodyLarge color="var(--on-surface-high)" sx={{ mb: 2 }}>
                    {data?.description}
                  </BodyBodyLarge>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box display="flex" alignItems="center">
                {/* Left Section */}
                <Box>
                  <BodyBodySmall color="var(--on-surface-high)" sx={{ mb: 2 }}>
                    Хариулт:
                  </BodyBodySmall>
                  <BodyBodyLarge color="var(--on-surface-high)" sx={{ mb: 2 }}>
                    {data?.answer}
                  </BodyBodyLarge>
                </Box>
              </Box>
            </Box>
            {/* Centered Button */}
            <Box display="flex" justifyContent="center" mt={4}>
              <Button
                variant="contained"
                sx={{
                  "backgroundColor": "#2e7d32",
                  "borderRadius": "24px",
                  "padding": "8px 24px",
                  "textTransform": "none",
                  "fontWeight": "bold",
                  "display": "flex",
                  "alignItems": "center",
                  "&:hover": {
                    backgroundColor: "#1b5e20",
                  },
                }}
                // onClick={() => setOpenMapModal(true)}
                onClick={handleSubmit}
                // startIcon={<Plus />}
                disabled={data?.statusId !== "OPEN"}
              >
                Хүсэлт шийдвэрлэх
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default FeedbackDetail;
