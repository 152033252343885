import { Grid2 } from "@mui/material";
import {
    Users,
    ArrowUpRight,
    Phone,
    ArrowDownRight,
    Money,
    Wallet,
    Buildings,
    Barcode,
    EnvelopeOpen,
} from "@phosphor-icons/react";

import StatCard from "./StatCard";
import { useEffect, useState } from "react";
import { mainApi } from "../../../api/main";

const Dashboard = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        getDashBoard();
    }, []);

    const getDashBoard = async () => {
        try {
            const res = await mainApi("dashboard/home", "GET", null);
            setData(res.data);
        } catch (error) {}
    };

    return (
        <Grid2 container spacing={2}>
            <StatCard
                title="Шинэ хэрэглэгч"
                value={data?.userMetrics?.totalToday}
                icon={{
                    component: (
                        <Users
                            size={24}
                            weight="duotone"
                            color="var(--on-surface-high)"
                        />
                    ),
                    bgColor: "#c5e7c6",
                }}
                comparison="Өчигдрөөс"
                comparisonValue={{
                    text:`${data?.userMetrics?.percentageChange}`,
                    color: "var(--success-error)",
                }}
                comparisonIcon={
                    <ArrowUpRight
                        size={24}
                        weight="regular"
                        color="var(--success-error)"
                    />
                }
            />
            <StatCard
                title="Дахиврын дуудлага"
                value={data?.callsMetrics?.total}
                icon={{
                    component: (
                        <Phone
                            size={24}
                            weight="duotone"
                            color="var(--brand-2-on-primary-h)"
                        />
                    ),
                    bgColor: "var(--brand-2-soft-primary-soft)",
                }}
                comparison="Сүүлийн 7 хоногт"
                comparisonValue={{
                    text: `${data?.callsMetrics?.percentageChange}`,
                    color: "var(--success-error)",
                }}
                comparisonIcon={
                    <ArrowUpRight
                        size={24}
                        weight="regular"
                        color="var(--success-error)"
                    />
                }
            />
            <StatCard
                title="Худалдаж авсан дахивар"
                value={data?.purchasedMetrics?.total}
                icon={{
                    component: (
                        <Money
                            size={24}
                            weight="duotone"
                            color="var(--secondary-secondary)"
                        />
                    ),
                    bgColor: "var(--color-neutral-95)",
                }}
                comparison="Энэ сард"
                comparisonValue={{ text: `${data?.purchasedMetrics?.percentageChange}`, color: "var(--error-error)" }}
                comparisonIcon={
                    <ArrowDownRight
                        size={24}
                        weight="regular"
                        color="var(--error-error)"
                    />
                }
            />

            <StatCard
                title="Тараагдсан оноо"
                value={`${data?.givePoint?.allTimeTotalPoints} оноо`}
                icon={{
                    component: (
                        <Wallet
                            size={24}
                            weight="duotone"
                            color="var(--error-error)"
                        />
                    ),
                    bgColor: "var(--color-error-95)",
                }}
                comparison="Энэ сард"
                comparisonValue={{ text: `${data?.givePoint?.percentageChange}`, color: "var(--error-error)" }}
                comparisonIcon={
                    <ArrowDownRight
                        size={24}
                        weight="regular"
                        color="var(--error-error)"
                    />
                }
            />
            <StatCard
                title="Байгууллагын хүсэлт"
                value={data?.requestOrganization?.allTimeCount}
                icon={{
                    component: (
                        <Buildings
                            size={24}
                            weight="duotone"
                            color="var(--brand-2-primary)"
                        />
                    ),
                    bgColor: "var(--surface-brand-2)",
                }}
                comparison="Энэ сард"
                comparisonValue={{
                    text: `${data?.requestOrganization?.percentageChange}`,
                    color: "var(--success-error)",
                }}
                comparisonIcon={
                    <ArrowUpRight
                        size={24}ta
                        weight="regular"
                        color="var(--success-error)"
                    />
                }
            />

            <StatCard
                title="Ангилах хүсэлт"
                value={data?.barCodeRequest?.total}
                icon={{
                    component: (
                        <Barcode
                            size={24}
                            weight="duotone"
                            color="var(--success-error)"
                        />
                    ),
                    bgColor: "var(--primary-soft-primary-soft)",
                }}
                comparison="Энэ сард"
                comparisonValue={{
                    text: data?.lastMonthCount?.percentageChange,
                    color: "var(--success-error)",
                }}
                comparisonIcon={
                    <ArrowUpRight
                        size={24}
                        weight="regular"
                        color="var(--success-error)"
                    />
                }
            />
            <StatCard
                title="Санал хүсэлт, гомдол"
                value={data?.feedbackCount?.allTimeCount}
                icon={{
                    component: (
                        <EnvelopeOpen
                            size={24}
                            weight="duotone"
                            color="var(--error-soft-on-error-soft-h)"
                        />
                    ),
                    bgColor: "var(--error-on-error-m)",
                }}
                comparison="Энэ сард"
                comparisonValue={{
                    text: `${data?.feedbackCount?.percentageChange}`,
                    color: "var(--success-error)",
                }}
                comparisonIcon={
                    <ArrowUpRight
                        size={24}
                        weight="regular"
                        color="var(--success-error)"
                    />
                }
            />
        </Grid2>
    );
};

export default Dashboard;
