import React, { useEffect, useState } from "react";
import { Dialog, Box, Button, IconButton, TextField, MenuItem, Typography, Card, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { X, Plus, DotsThreeVertical, Trash } from "@phosphor-icons/react";
import { BodyBodyMedium, BodyBodySmall, HeadlineHeadlineMedium, TitleTitleLarge, TitleTitleMedium } from "../../../../utils/styleMethod";
import ImageUpload from "../../../../components/image-upload";
import { mainApi, getErrorMessage } from "../../../../api/main";
import { toast } from "react-toastify";

const ItemGroupModal = ({ open, handleClose, factoryList, itemList, userId, setQuery, groupType, isEditMode, data }) => {
  const {
    control,
    handleSubmit,
    register,
    clearErrors,
    setError,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      nameEn: "",
      description: "",
      descriptionEn: "",
      imageUrl: "",
      steps: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "steps", // This must match the defaultValues key
  });

  useEffect(() => {
    if (isEditMode) {
      setValue("imageUrl", data?.imageUrl);
      setValue("name", data?.name);
      setValue("nameEn", data?.nameEn);
      setValue("description", data?.description);
      setValue("descriptionEn", data?.descriptionEn);
      setValue("steps", data?.steps);
    } else {
      reset();
    }
  }, [data]);

  const changeImageUrl = (url) => {
    setValue("imageUrl", url);
  };

  const changeImageUrl2 = (url, id) => {
    const index = id.substring(10);
    setValue(`steps[${index}].imageUrl`, url);
  };

  const onSubmit = async (saveData) => {
    try {
      let params = saveData;

      params.id = data?.id;
      params.code = data?.code;
      params.itemsGroupId = data?.itemsGroupId;
      console.log(params);

      const res = await mainApi("itemGroups/admin/type", "PUT", params);

      reset();
      handleClose();
      toast.success("Амжилттай хадгаллаа.");
    } catch (error) {
      let errorMessage = getErrorMessage(error);
      toast.error("Хадгалах явцад алдаа гарлаа. " + errorMessage);
    }
  };

  const addStep = () => {
    append({ name: "" + (fields.length + 1) + ". ", nameEn: "" + (fields.length + 1) + ". ", imageUrl: "" });
  };

  const removeStep = (index) => {
    remove(index);
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          width: "750px",
          maxWidth: "95vw",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          pl: 6,
          pb: 6,
          pt: 2,
          pr: 6,
        }}
      >
        <Box display="flex" width="100%" pb={2} justifyContent="space-between" alignItems="center">
          <Box display="flex" justifyContent="center" textAlign="center" flexGrow={1}>
            <HeadlineHeadlineMedium color="var(--on-surface-high)">Зааврын мэдээлэл {isEditMode ? "засах": "нэмэх"}</HeadlineHeadlineMedium>
          </Box>
          <Box>
            <Button onClick={handleClose} sx={{ minWidth: "auto" }}>
              <X size={24} color="#4caf50" />
            </Button>
          </Box>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" width="100%" justifyContent="space-between">
              <Box flex="1 1 45%">
                <BodyBodySmall color="var(--on-surface-high)">Олон улсын тэмдэглэгээ</BodyBodySmall>
                {/* Image Upload */}
                <ImageUpload
                  id="image1"
                  type="cover"
                  setImageUrl={changeImageUrl}
                  imageUrl={data?.imageUrl}
                  suggestText={"Санал болгох зургийн хэмжээ: 72x90px"}
                />
                {errors?.imageUrl && <p className="error-message">{errors.imageUrl.message}</p>}
                <input
                  {...register(`imageUrl`, {
                    required: "Зааврын зураг оруулна уу",
                    pattern: {
                      value: /^(https?:\/\/.*\.(?:png|jpg|jpeg|svg|gif|webp))$/,
                      message: "Зургын мэдээлэл буруу байна",
                    },
                  })}
                  hidden={true}
                />
              </Box>
            </Box>
            <Box>
              <BodyBodySmall color="var(--on-surface-high)">Ангиллын гадаад нэршил</BodyBodySmall>
              <TextField
                {...register("nameEn", { required: "Гадаад нэршил заавал оруулна уу" })}
                fullWidth
                InputProps={{ sx: { height: "45px" } }}
                error={!!errors.nameEn}
                helperText={errors.nameEn?.message}
              />
            </Box>
            <Box>
              <BodyBodySmall color="var(--on-surface-high)">Ангиллын монгол нэршил</BodyBodySmall>
              <TextField
                {...register("name", { required: "Монгол нэршил заавал оруулна уу" })}
                fullWidth
                InputProps={{ sx: { height: "45px" } }}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            </Box>
            <Box>
              <BodyBodySmall color="var(--on-surface-high)">Тайлбар гадаад нэршил</BodyBodySmall>
              <TextField
                {...register("descriptionEn", { required: "Тайлбарын гадаад нэршил заавал оруулна уу" })}
                fullWidth
                multiline
                rows={3}
                sx={{ height: "auto" }}
                error={!!errors.descriptionEn}
                helperText={errors.descriptionEn?.message}
              />
            </Box>
            <Box>
              <BodyBodySmall color="var(--on-surface-high)">Тайлбар монгол нэршил</BodyBodySmall>
              <TextField
                {...register("description", { required: "Тайлбарын монгол нэршил заавал оруулна уу" })}
                fullWidth
                multiline
                rows={3}
                sx={{ height: "auto" }}
                error={!!errors.description}
                helperText={errors.description?.message}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} width={"100%"}>
              <TitleTitleMedium color="var(--on-surface-high)">Ангилах зааврын алхам</TitleTitleMedium>
              <Button
                variant="contained"
                sx={{
                  "backgroundColor": "#2e7d32",
                  "borderRadius": "24px",
                  "padding": "8px 24px",
                  "textTransform": "none",
                  "fontWeight": "bold",
                  "display": "flex",
                  "alignItems": "center",
                  "&:hover": {
                    backgroundColor: "#1b5e20",
                  },
                }}
                // onClick={() => setOpenMapModal(true)}
                onClick={() => addStep()}
                startIcon={<Plus />}
              >
                Алхам нэмэх
              </Button>
            </Box>

            {fields.map((step, index) => (
              <div key={`step-${index}`}>
                <Box display="flex" width="100%" pb={2} justifyContent="space-between" alignItems="top">
                  <Box display="flex" justifyContent="left" textAlign="top" flexGrow={1}>
                    <ImageUpload
                      id={"imageStep-" + index}
                      type="cover"
                      suggestText={"Санал болгох зургийн хэмжээ: 318x188px"}
                      onChange={changeImageUrl2}
                      imageUrl={step?.imageUrl}
                    />
                    {errors?.steps?.[index]?.imageUrl && <p className="error-message">{errors.steps[index].imageUrl.message}</p>}
                    <input
                      {...register(`steps.${index}.imageUrl`, {
                        required: "Алхамын зураг оруулна уу",
                        pattern: {
                          value: /^(https?:\/\/.*\.(?:png|jpg|jpeg|svg|gif|webp))$/,
                          message: "Зургын мэдээлэл буруу байна",
                        },
                      })}
                      hidden={true}
                    />
                  </Box>
                  <Box textAlign="top">
                    <Button onClick={() => removeStep(index)} sx={{ minWidth: "auto" }}>
                      <Trash size={24} color="red" />
                    </Button>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box>
                    <BodyBodySmall color="var(--on-surface-high)">Зургын тайлбар гадаад нэршил</BodyBodySmall>
                    <TextField
                      {...register(`steps.${index}.nameEn`, {
                        required: "Гадаад тайлбар заавал оруулна уу",
                        minLength: {
                          value: 4,
                          message: "Гадаад тайлбар заавал оруулна уу",
                        },
                      })}
                      fullWidth
                      InputProps={{ sx: { height: "45px" } }}
                      error={!!errors.steps?.[index]?.nameEn}
                      helperText={errors.steps?.[index]?.nameEn?.message}
                    />
                  </Box>

                  <Box>
                    <BodyBodySmall color="var(--on-surface-high)">Зургын тайлбар монгол нэршил</BodyBodySmall>
                    <TextField
                      {...register(`steps.${index}.name`, {
                        required: "Монгол тайлбар заавал оруулна уу",
                        minLength: {
                          value: 4,
                          message: "Монгол тайлбар заавал оруулна уу",
                        },
                      })}
                      fullWidth
                      InputProps={{ sx: { height: "45px" } }}
                      error={!!errors.steps?.[index]?.name}
                      helperText={errors.steps?.[index]?.name?.message}
                    />
                  </Box>
                </Box>
                <hr color="var(--stroke-secondary-soft)" />
              </div>
            ))}

            {/* Submit Button */}
            <Box display="flex" justifyContent="flex-end" width="100%">
              <Button disabled={true} variant="contained" color="success" fullWidth mt={2}>
                Загвар болгон хадгалах
              </Button>
              <Button type="submit" variant="contained" color="primary" fullWidth mt={2}>
                Хадгалах
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
};

export default ItemGroupModal;
