import React, { useEffect } from "react";
import { Modal, Box, Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { X } from "@phosphor-icons/react";
import { toast } from "react-toastify";
import { BodyBodySmall, TitleTitleLarge } from "../../../utils/styleMethod";
import { mainApi, getErrorMessage } from "../../../api/main";

const ContactDetail = ({ open, handleClose, data }) => {
  const {
    register,
    reset,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      question: "",
      answer: "",
      language: "mn",
      viewOrder: 1,
    },
  });

  useEffect(() => {
    if (open && data !== null) {
      setValue("phoneNumberMN", data?.mn?.phoneNumber);
      setValue("emailMN", data?.mn?.email);
      setValue("addressMN", data?.mn?.address);

      setValue("phoneNumberEN", data?.en?.phoneNumber);
      setValue("emailEN", data?.en?.email);
      setValue("addressEN", data?.en?.address);
    } else {
      reset();
    }
  }, [open]);

  const handleValidation = async () => {
    try {
      const values = getValues();

      const isValid = await trigger();

      if (!isValid) {
        toast.warning("Мэдээллээ бүрэн зөв бөглөн үү.");
        return;
      }

      let configMN = {
        phoneNumber: values.phoneNumberMN,
        email: values.emailMN,
        address: values.addressMN,
        language: "mn",
      };

      let configEN = {
        phoneNumber: values.phoneNumberEN,
        email: values.emailEN,
        address: values.addressEN,
        language: "en",
      };

      let saveData = [configMN, configEN];

      const res = await mainApi("settings/admin/save/contactInfo", "POST", saveData);

      reset();
      handleClose(true);
    } catch (error) {
      let errorMessage = getErrorMessage(error);
      toast.error("Хадгалах явцад алдаа гарлаа. " + errorMessage);
    }
  };

  return (
    <>
      {data && (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Box display="flex" justifyContent="center" alignItems="center" mb={2} width={"100%"}>
              <TitleTitleLarge color="var(--on-surface-high)">Холбоо барих</TitleTitleLarge>
              <Button onClick={handleClose} sx={{ position: "absolute", right: "16px", minWidth: "auto" }}>
                <X size={24} color="#4caf50" />
              </Button>
            </Box>

            <Box display="flex" flexDirection="column" gap={2}>
              <Box>
                <BodyBodySmall color="var(--on-surface-high)">Утас</BodyBodySmall>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <TextField
                    {...register("phoneNumberMN", { required: "Утасны дугаар оруулна уу" })}
                    fullWidth
                    InputProps={{ sx: { height: "45px" } }}
                    error={!!errors.phoneNumberMN}
                    helperText={errors.phoneNumberMN?.message}
                  />
                </Box>
              </Box>
              <Box>
                <BodyBodySmall color="var(--on-surface-high)">Имэйл</BodyBodySmall>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <TextField
                    {...register("emailMN", { required: "Мэйл хаяг оруулна уу" })}
                    fullWidth
                    InputProps={{ sx: { height: "45px" } }}
                    error={!!errors.emailMN}
                    helperText={errors.emailMN?.message}
                  />
                </Box>
              </Box>
              <Box>
                <BodyBodySmall color="var(--on-surface-high)">Хаяг</BodyBodySmall>
                <TextField
                  {...register("addressMN", { required: "Хаяг заавал оруулна уу" })}
                  fullWidth
                  error={!!errors?.addressMN}
                  helperText={errors?.addressMN?.message}
                  multiline
                  rows={2}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={2}>
              <Box>
                <BodyBodySmall color="var(--on-surface-high)">Утас /Англи/</BodyBodySmall>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <TextField
                    {...register("phoneNumberEN", { required: "Утасны дугаар оруулна уу" })}
                    fullWidth
                    InputProps={{ sx: { height: "45px" } }}
                    error={!!errors.phoneNumberEN}
                    helperText={errors.phoneNumberEN?.message}
                  />
                </Box>
              </Box>
              <Box>
                <BodyBodySmall color="var(--on-surface-high)">Имэйл /Англи/</BodyBodySmall>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <TextField
                    {...register("emailEN", { required: "Мэйл хаяг оруулна уу" })}
                    fullWidth
                    InputProps={{ sx: { height: "45px" } }}
                    error={!!errors.emailEN}
                    helperText={errors.emailEN?.message}
                  />
                </Box>
              </Box>
              <Box>
                <BodyBodySmall color="var(--on-surface-high)">Хаяг /Англи/</BodyBodySmall>
                <TextField
                  {...register("addressEN", { required: "Хаяг заавал оруулна уу" })}
                  fullWidth
                  error={!!errors?.addressEN}
                  helperText={errors?.addressEN?.message}
                  multiline
                  rows={2}
                />
              </Box>
            </Box>

            {/* Centered Button */}
            <Box display="flex" justifyContent="center" mt={4}>
              <Button
                variant="contained"
                sx={{
                  "backgroundColor": "#2e7d32",
                  "borderRadius": "24px",
                  "padding": "8px 24px",
                  "textTransform": "none",
                  "fontWeight": "bold",
                  "display": "flex",
                  "alignItems": "center",
                  "&:hover": {
                    backgroundColor: "#1b5e20",
                  },
                }}
                // onClick={() => setOpenMapModal(true)}
                onClick={handleValidation}
                // startIcon={<Plus />}
              >
                Хадгалах
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ContactDetail;
