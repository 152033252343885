import { useEffect, useState } from "react";
import "./styles.css";
import { BodyBodySmall } from "../../utils/styleMethod";
import { CloudArrowUp, Trash } from "@phosphor-icons/react";
import { mainApi } from "../../api/main";

const ImageUpload = ({ id, setImageUrl, type, suggestText, imageUrl, onChange, showError }) => {
  const [image, setImage] = useState(null);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const allowedTypes = ["image/jpeg", "image/png", "image/gif", "image/webp", "image/bmp", "image/tiff", "image/svg+xml"];
  const maxFileSize = 20 * 1024 * 1024; // 20MB limit

  useEffect(() => {
    if (imageUrl) {
      setImage(imageUrl);
    }
  }, [imageUrl]);

  useEffect(() => {
    if (showError && showError?.isError && showError?.message) {
      setIsError(true);
      setErrorMsg(showError.message);
    }
  }, [showError]);

  const handleFileUpload = async (file) => {
    if (!file) return;

    if (!allowedTypes.includes(file.type)) {
      setIsError(true);
      setErrorMsg("Only JPEG, PNG, GIF, WebP, BMP, TIFF, and SVG images are allowed.");
      return;
    }

    // Validate file size
    if (file.size > maxFileSize) {
      setIsError(true);
      setErrorMsg("File size exceeds 20MB limit.");
      return;
    }

    setIsError(false);
    setIsUploading(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("imageType", type || "cover");

    try {
      const response = await mainApi("image/upload", "POST", formData);
      if (response.statusCode == 200) {
        setImage(response.data);
        if (setImageUrl) setImageUrl(response.data);
        if (onChange) onChange(response.data, id);
      }
    } catch (error) {
      console.error("Upload failed: ", error);
      setIsError(true);
      setErrorMsg("Failed to upload image. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  const handleUploadImage = (e) => {
    const selectedFile = e.target.files[0];
    handleFileUpload(selectedFile);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    handleFileUpload(droppedFile);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setImage(null);
    if (setImageUrl) setImageUrl(null);
    if (onChange) onChange(null, id);
  };

  return (
    <div className="upload-container">
      <label
        htmlFor={"imageupload-" + id}
        className={`image-box ${isDragging ? "dragging" : ""}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {image ? (
          <div className="image-preview-wrapper">
            <img className="preview-image" src={image} alt="Uploaded" />
          </div>
        ) : (
          <div className="upload-prompt">
            <CloudArrowUp size={32} />
            <p>Чирж оруулж ирнэ үү</p>
          </div>
        )}
      </label>

      <div className="info-text">
        <BodyBodySmall color="var(--color-neutral-50)">Зургийн дээд хэмжээ: 20MB</BodyBodySmall>
        <BodyBodySmall color="var(--color-neutral-50)">{suggestText ?? "Санал болгож зургийн хэмжээ: 318x188px"}</BodyBodySmall>
        <button
          className="delete-button"
          onClick={(e) => {
            handleDelete(e);
          }}
        >
          <Trash size={24} />
        </button>
      </div>

      {isError && <p className="error-message">{errorMsg}</p>}

      <input type="file" accept="image/*" id={"imageupload-" + id} style={{ display: "none" }} onChange={handleUploadImage} />

      {isUploading && <p className="uploading-message">Uploading...</p>}
    </div>
  );
};

export default ImageUpload;
