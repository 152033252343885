import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

export const tableBodyCellStyle = makeStyles({
  tableBodyCell: {
    fontFamily: "NotoSans",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "0.4px",
    color: "#153216",
  },
  tableBodyCellCenter: {
    fontFamily: "NotoSans",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "0.4px",
    color: "#153216",
    textAlign: "center",
  },
  tableBodyCellRight: {
    fontFamily: "NotoSans",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "0.4px",
    color: "#153216",
    textAlign: "right",
  },
});


export const tableHeaderStyle = makeStyles({
  tableHeader: {
    fontFamily: 'NotoSans',
    fontSize: '16px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color:'#153216'
  },
});


export const DisplayLarge = styled('div')(({ theme, color }) => ({
  fontFamily: 'NotoSans',
  fontSize: '57px',
  fontWeight: 800,
  lineHeight: 1.12,
  letterSpacing: '-0.25px',
  color: color || theme.palette.text.primary, // Use provided color or default to theme color
}));

export const DisplayMedium = styled('div')(({ theme, color }) => ({
  fontFamily: 'NotoSans',
  fontSize: '45px',
  fontWeight: 800,
  lineHeight: 1.16,
  color: color || theme.palette.text.primary,
}));

export const DisplaySmall = styled('div')(({ theme, color }) => ({
  fontFamily: 'NotoSans',
  fontSize: '36px',
  fontWeight: 600,
  lineHeight: 1.22,
  color: color || theme.palette.text.primary,
}));

export const HeadlineHeadlineLarge = styled('div')(({ theme, color }) => ({
  fontFamily: 'NotoSans',
  fontSize: '32px',
  fontWeight: 'bold',
  lineHeight: 1.25,
  color: color || theme.palette.text.primary,
}));

export const HeadlineHeadlineMedium = styled('div')(({ theme, color }) => ({
  fontFamily: 'NotoSans',
  fontSize: '28px',
  fontWeight: 800,
  lineHeight: 1.29,
  color: color || theme.palette.text.primary,
}));

export const HeadlineHeadlineSmall = styled('div')(({ theme, color }) => ({
  fontFamily: 'NotoSans',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: 1.33,
  color: color || theme.palette.text.primary,
}));

export const TitleTitleLarge = styled('div')(({ theme, color }) => ({
  fontFamily: 'NotoSans',
  fontSize: '22px',
  fontWeight: 'bold',
  lineHeight: 1.3,
  color: color || theme.palette.text.primary,
}));

export const TitleTitleMid = styled('div')(({ theme, color }) => ({
  fontFamily: 'NotoSans',
  fontSize: '18px',
  fontWeight: 'bold',
  lineHeight: 1.4,
  color: color || theme.palette.text.primary,
}));

export const TitleTitleMedium = styled('div')(({ theme, color }) => ({
  fontFamily: 'NotoSans',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: 1.5,
  color: color || theme.palette.text.primary,
}));

export const LabelLabelLarge = styled('div')(({ theme, color }) => ({
  fontFamily: 'NotoSans',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: 1.25,
  color: color || theme.palette.text.primary,
}));

export const BodyBodyLarge = styled('div')(({ theme, color }) => ({
  fontFamily: 'NotoSans',
  fontSize: '16px',
  fontWeight: 'normal',
  lineHeight: 1.63,
  color: color || theme.palette.text.primary,
}));

export const TitleTitleSmall = styled('div')(({ theme, color }) => ({
  fontFamily: 'NotoSans',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: 1.43,
  color: color || theme.palette.text.primary,
}));

export const LabelLabelSmall = styled('div')(({ theme, color }) => ({
  fontFamily: 'NotoSans-Display',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: 1.14,
  color: color || theme.palette.text.primary,
}));

export const BodyBodyMedium = styled('div')(({ theme, color }) => ({
  fontFamily: 'NotoSans',
  fontSize: '14px',
  fontWeight: 'normal',
  lineHeight: 1.43,
  letterSpacing: '0.4px',
  color: color || theme.palette.text.primary,
}));

export const TitleTitleTiny = styled('div')(({ theme, color }) => ({
  fontFamily: 'NotoSans',
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: 1.33,
  color: color || theme.palette.text.primary,
}));

export const BodyBodySmall = styled('div')(({ theme, color }) => ({
  fontFamily: 'NotoSans',
  fontSize: '12px',
  fontWeight: 'normal',
  lineHeight: 1.6,
  color: color || theme.palette.text.primary,
}));

export const LabelLabelExtraSmall = styled('div')(({ theme, color }) => ({
  fontFamily: 'NotoSans-Display',
  fontSize: '10px',
  fontWeight: 500,
  lineHeight: 1.4,
  color: color || theme.palette.text.primary,
}));

export const BodyBodyExtraSmall = styled('div')(({ theme, color }) => ({
  fontFamily: 'NotoSans',
  fontSize: '10px',
  fontWeight: 'normal',
  lineHeight: 1.4,
  color: color || theme.palette.text.primary,
}));
