import { useEffect, useState } from "react";
import { Box, Button, Grid2, IconButton, Menu, MenuItem, TextField } from "@mui/material";
import { ArrowLeft, DotsThreeVertical, Pencil, Trash, UserMinus, UserPlus } from "@phosphor-icons/react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

import {
  BodyBodyLarge,
  BodyBodyMedium,
  BodyBodySmall,
  HeadlineHeadlineSmall,
  HeadlineHeadlineLarge,
  tableBodyCellStyle,
  tableHeaderStyle,
  TitleTitleMedium,
  TitleTitleMid,
} from "../../../../utils/styleMethod";

import { mainApi, getErrorMessage } from "../../../../api/main";

import GridCardStep from "../../../../components/grid-card-component/GridCardStep";

export default function NewsInfo({ data }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [acceptableModalOpen, setAcceptableModalOpen] = useState(false);

  // const [images, setImages] = useState([
  //   { imageUrl: "https://via.placeholder.com/647x487", name: "Image 1" },
  //   { imageUrl: "https://via.placeholder.com/647x487", name: "Image 2" },
  //   { imageUrl: "https://via.placeholder.com/647x487", name: "Image 3" },
  //   { imageUrl: "https://via.placeholder.com/647x487", name: "Image 4" },
  //   { imageUrl: "https://via.placeholder.com/647x487", name: "Image 5" },
  // ]);

  const closeEditModal = () => setShowEditModal(false);
  const acceptableModalClose = () => {
    setAcceptableModalOpen(false);
    setAnchorEl(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteData = async () => {
    try {
      const res = await mainApi(`itemGroups/admin/type/${data?.id}`, "DELETE", null);

      acceptableModalClose();
      toast.success("Амжилттай устгалаа");
      navigate(`/guide/${data?.itemsGroupId}`);
    } catch (error) {
      let errorMessage = getErrorMessage(error);
      toast.error("Устгах явцад алдаа гарлаа. " + errorMessage);
    }
  };

  return (
    <Box
      sx={{
        // border: "1px solid #E0E0E0",
        borderRadius: "10px",
        p: "24px 32px",
        backgroundColor: "var(--surface-white)",
        width: "100%",
        maxWidth: "100vw",
        margin: "0 auto",
        mt: 2,
        boxSizing: "border-box",
      }}
    >
      <Grid2
        container
        spacing={2}
        sx={{
          width: "1024px",
        }}
      >
        {/* Logo Section */}
        <Grid2 xs={12}>
          {data?.images?.length > 0 && (
            <Swiper
              modules={[Navigation]}
              navigation
              spaceBetween={20}
              slidesPerView={1}
              breakpoints={{
                640: {
                  slidesPerView: data?.images?.length > 2 ? 2 : data?.images?.length, // Number of slides on medium screens
                },
                1024: {
                  slidesPerView: data?.images?.length > 3 ? 3 : data?.images?.length, // Number of slides on large screens
                },
              }}
            >
              {data?.images.map((image, index) => (
                <SwiperSlide key={index}>
                  <div
                    style={{
                      width: "100%",
                      height: "258px", // Fixed height for consistent appearance
                      overflow: "hidden",
                      border: "1px solid #ccc",
                      borderRadius: "12px",
                    }}
                  >
                    <img
                      src={image}
                      alt={"Мэдээний зураг"}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover", // Ensures the image fills the area with cropping
                      }}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </Grid2>
      </Grid2>
      {/* About Section */}
      <Box mt={2} sx={{ width: "780px", pb: 4 }} mb={4} style={{ paddingTop: "16px" }}>
        <HeadlineHeadlineLarge color="var(--on-surface-high)">{data?.caption}</HeadlineHeadlineLarge>
        <BodyBodyLarge color="var(--on-surface-high)">{data?.mainText}</BodyBodyLarge>
        {data?.textInfos &&
          data?.textInfos.map((item, index) => (
            <>
              <img src={item?.imageUrl} alt={item?.name} style={{ width: "100%", height: "auto", marginTop: "16px", marginBottom: "16px" }} />
              <BodyBodyLarge
                color="var(--on-surface-high)"
                dangerouslySetInnerHTML={{
                  __html: item.imageText.replace(/\r\n/g, "<br />"), // Replace newlines with <br />
                }}
              />
            </>
          ))}
      </Box>
    </Box>
  );
}
