import React, { useEffect, useState } from "react";
import {
    Modal,
    Box,
    Button,
    TextField,
    Grid,
    CircularProgress,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    InputAdornment,
    IconButton,
} from "@mui/material";
import { ArrowArcRight, ArrowRight, X } from "@phosphor-icons/react"; // Import the close icon from phosphor-icons
import { useForm, Controller } from "react-hook-form";
import {
    BodyBodyLarge,
    BodyBodySmall,
    HeadlineHeadlineMedium,
    LabelLabelLarge,
} from "../../utils/styleMethod";
import { toast } from "react-toastify";
import AddOrRemoveModal from "./AddOrRemoveEmployee";
import { mainApi } from "../../api/main";

const AddEmployeeModal = ({ open, handleClose, type, organizationId , addToList}) => {
    const {
        control,
        register,
        setValue,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();
    const [loading, setLoading] = useState(false);
    const [openEmployerModal, setOpenEmployerModal] = useState(false);
    const [addOrRemove, setAddOrRemove] = useState(false);
    const [employeeUser, setEmployeeUser] = useState("");

    console.log("TYpe-> ", type);

    useEffect(() => {
        const labels = {
            admin: "Админ",
            assistant_admin_1: "Туслах админ ",
            assistant_admin_2: "Туслах админ ",
            factory_worker: "Үйлдвэрийн ажилтан",
        };
        const label = labels[employeeUser] || "";
        setValue("role", label || "");
    }, [employeeUser, setValue]);

    // Handle form submission
    const onSubmit = async (data) => {
        setLoading(true);
        console.log("employeeUser :", employeeUser);

        let role = "";

        const roleMapping = {
            Factory: {
                assistant_admin_1: "FactoryUser",
                assistant_admin_2: "FactoryUser",
                admin: "FactoryAdmin",
                factory_worker:"FactoryWorker"
            },
            Enterprise: {
                assistant_admin_1: "SUHuser",
                assistant_admin_2: "SUHuser",
                admin: "SUHadmin",
            },
            DropOff: {
                assistant_admin_1: "DropOffCenterUser",
                assistant_admin_2: "DropOffCenterUser",
                admin: "DropOffCenterAdmin",
            },
        };

        if (roleMapping[type] && roleMapping[type][employeeUser]) {
            role = roleMapping[type][employeeUser];
        }

        let json = {
            phoneNumber: data.contact,
            organizationId: organizationId,
            roleName: role,
        };

        const res = await mainApi("organization/add-employee", "PUT", json);

        setTimeout(() => {
            setLoading(false);
            toast.success("Ажилтан амжилттай нэмлээ.");
            reset();
            handleClose();
        }, 2000);
        addToList()
    };

    const changeAddOrRemove = (value) => {
        setOpenEmployerModal(true);
        if (value) {
            setAddOrRemove(true);
        } else {
            setAddOrRemove(false);
        }
    };

    return (
        <>
            (
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 450,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Box
                        display="flex"
                        justifyContent="end"
                        alignItems="center"
                        width="100%"
                    >
                        <Button onClick={handleClose} sx={{ minWidth: "auto" }}>
                            <X size={24} color="#4caf50" />
                        </Button>
                    </Box>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                    >
                        <HeadlineHeadlineMedium>
                            Ажилтан нэмэх
                        </HeadlineHeadlineMedium>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                        width="100%"
                    >
                        <BodyBodyLarge>
                            Та үйлдвэрийн админ, туслах админ, үйлдвэрийн
                            ажилтан гэсэн 3 зэрэглэлээс өөрийн шаардлагатайг
                            сонгоорой
                        </BodyBodyLarge>
                    </Box>

                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{ width: "100%" }}
                    >
                        {/* Dropdown selection */}
                        <Box mt={2}>
                            <Box mt={2}>
                                <BodyBodySmall color="var(--on-surface-high)">
                                    Сонго
                                </BodyBodySmall>
                                <TextField
                                    {...register("role", {
                                        required: "заавал сонго",
                                    })}
                                    disabled
                                    error={!!errors.role}
                                    helperText={errors.role?.message}
                                    fullWidth
                                    // InputProps={{ sx: { height: "45px" } }}
                                    InputProps={{
                                        sx: { height: "45px" },
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() =>
                                                        changeAddOrRemove(true)
                                                    }
                                                >
                                                    <ArrowRight />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                        </Box>

                        {/* Contact input */}
                        <Box mt={2}>
                            <BodyBodySmall color="var(--on-surface-high)">
                                Утас эсвэл имэйл хаяг оруулах
                            </BodyBodySmall>
                            <TextField
                                {...register("contact", {
                                    required:
                                        "Утасны дугаар эсвэл и-мэйл хаягаа оруулна уу",
                                    pattern: {
                                        value: /^(^[89]\d{7}$|^[^\s@]+@[^\s@]+\.[^\s@]+$)/,
                                        message:
                                            "И-мэйл эсвэл 8 оронтой утасны дугаар оруулна уу",
                                    },
                                })}
                                error={!!errors.contact}
                                helperText={errors.contact?.message}
                                fullWidth
                                InputProps={{ sx: { height: "45px" } }}
                            />
                        </Box>

                        {/* Submit button */}
                        <Box mt={2} display="flex" justifyContent="center">
                            <Button
                                type="submit"
                                sx={{
                                    height: "45px",
                                    border: "1px solid #368137",
                                    borderRadius: "16px",
                                    padding: "16px",
                                    color: "#368137",
                                }}
                                variant="contained"
                                disabled={loading}
                            >
                                {loading ? (
                                    <CircularProgress
                                        size={24}
                                        color="inherit"
                                    />
                                ) : (
                                    <LabelLabelLarge color="white">
                                        Админаар нэмэх
                                    </LabelLabelLarge>
                                )}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
            {openEmployerModal ? (
                <AddOrRemoveModal
                    open={openEmployerModal}
                    handleClose={() => setOpenEmployerModal(false)}
                    organizationId={organizationId}
                    title={addOrRemove ? "Ажилтан нэмэх" : "Ажилтан хасах"}
                    buttonText={
                        addOrRemove ? "Системд нэмэх" : "Системээс хасах"
                    }
                    setEmployeeUser={setEmployeeUser}
                    type={type}
                />
            ) : null}
            );
        </>
    );
};

export default AddEmployeeModal;
