import React, { useEffect, useState } from "react";
import { Modal, Box, Button, TextField, MenuItem, Checkbox } from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { X, UserCircle, User } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BodyBodyLarge,
  BodyBodyMedium,
  BodyBodySmall,
  DisplayMedium,
  DisplaySmall,
  HeadlineHeadlineSmall,
  TitleTitleLarge,
  TitleTitleMedium,
} from "../../../utils/styleMethod";
import { mainApi, getErrorMessage } from "../../../api/main";
import { getColorByFeedbackStatus } from "../../../utils/method";
import { FeedbackStatusSpan } from "../../../components/form/span/FeedbackStatusSpan";

const PointDetail = ({ open, handleClose, data, isEditMode }) => {
  const navigate = useNavigate();
  const [feedbackTypes, setFeedbackTypes] = useState([]);

  const {
    control,
    handleSubmit,
    register,
    clearErrors,
    setError,
    reset,
    watch,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: "",
      name: "",
      givePoint: 0,
      active: true,
    },
  });

  useEffect(() => {
    if (open) {
      if (isEditMode && data !== null && data !== undefined) {
        setValue("code", data?.code);
        setValue("name", data?.name);
        setValue("givePoint", data?.givePoint);
        setValue("active", data?.active);
      } else {
        reset();
      }
    }
  }, [open]);

  const handleValidation = async () => {
    try {
      const values = getValues();

      const isValid = await trigger();

      if (!isValid) {
        toast.warning("Мэдээллээ бүрэн зөв бөглөн үү.");
        return;
      }
      debugger;

      let saveData = {
        id: data?.id ?? 0,
        code: values.code,
        name: values.name,
        givePoint: values.givePoint,
        active: values.active,
      };

      let params = saveData;
      const res = await mainApi("settings/admin/point/config/save", "POST", params);

      reset();
      handleClose(true);
    } catch (error) {
      let errorMessage = getErrorMessage(error);
      toast.error("Хадгалах явцад алдаа гарлаа. " + errorMessage);
    }
  };

  return (
    <>
      {data && (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Box display="flex" justifyContent="center" alignItems="center" mb={2} width={"100%"}>
              <TitleTitleLarge color="var(--on-surface-high)">Оноо цуглуулах боломж</TitleTitleLarge>
              <Button onClick={handleClose} sx={{ position: "absolute", right: "16px", minWidth: "auto" }}>
                <X size={24} color="#4caf50" />
              </Button>
            </Box>

            <Box display="flex" flexDirection="column" gap={2}>
              <Box>
                <BodyBodySmall color="var(--on-surface-high)">Код</BodyBodySmall>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <TextField
                    {...register("code", { required: "Код оруулна уу" })}
                    fullWidth
                    InputProps={{ sx: { height: "45px" } }}
                    error={!!errors.code}
                    helperText={errors.code?.message}
                  />
                </Box>
              </Box>
              <Box>
                <BodyBodySmall color="var(--on-surface-high)">Боломж</BodyBodySmall>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <TextField
                    {...register("name", { required: "Боломжийн нэрийг оруулна уу" })}
                    fullWidth
                    InputProps={{ sx: { height: "45px" } }}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 2 }}>
              {/* Date of News (Мэдээний огноо) */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <BodyBodySmall color="var(--on-surface-high)">Урамшууллын оноо</BodyBodySmall>
                <TextField
                  type="number"
                  {...register("givePoint", { required: "Урамшууллын оноо оруулна уу" })}
                  error={!!errors.givePoint}
                  helperText={errors.givePoint?.message}
                  sx={{ width: 120 }}
                />
              </Box>

              {/* Language selector and label (right position) */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Checkbox
                  {...register("active")}
                  checked={watch("active")} // Dynamically reflect the value of `isActive`
                  onChange={(e) => setValue("active", e.target.checked)} // Update form state on change
                />
                <BodyBodySmall color="var(--on-surface-high)">Нээх эсэх</BodyBodySmall>
              </Box>
            </Box>

            {/* Centered Button */}
            <Box display="flex" justifyContent="center" mt={4}>
              <Button
                variant="contained"
                sx={{
                  "backgroundColor": "#2e7d32",
                  "borderRadius": "24px",
                  "padding": "8px 24px",
                  "textTransform": "none",
                  "fontWeight": "bold",
                  "display": "flex",
                  "alignItems": "center",
                  "&:hover": {
                    backgroundColor: "#1b5e20",
                  },
                }}
                // onClick={() => setOpenMapModal(true)}
                onClick={handleValidation}
                // startIcon={<Plus />}
              >
                Хадгалах
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default PointDetail;
