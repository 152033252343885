import React, { useEffect, useState } from "react";
import {
  Dialog,
  Box,
  Button,
  Grid2,
  IconButton,
  TextField,
  MenuItem,
  Typography,
  Card,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { X, Plus, DotsThreeVertical, Trash } from "@phosphor-icons/react";

import { BodyBodyMedium, BodyBodySmall, HeadlineHeadlineMedium, TitleTitleLarge, TitleTitleMedium } from "../../../utils/styleMethod";
import ImageUpload from "../../../components/image-upload";
import { mainApi, getErrorMessage } from "../../../api/main";
import { toast } from "react-toastify";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

const NewsModal = ({ open, handleClose, isEditMode, data }) => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const {
    control,
    handleSubmit,
    register,
    clearErrors,
    setError,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      caption: "",
      mainText: "",
      active: false,
      language: "mn",
      postedDate: tomorrow.toISOString().split("T")[0], // Format to YYYY-MM-DD,
      fieldImages: [],
      textInfos: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "textInfos", // This must match the defaultValues key
  });

  const {
    fields: imageFields,
    append: appendImage,
    remove: removeImage,
  } = useFieldArray({
    control,
    name: "fieldImages", // This must match the defaultValues key
  });

  const [title, setTitle] = useState("");

  useEffect(() => {
    if (isEditMode && data !== null && data !== undefined) {
      debugger;
      setValue("imageUrl", data?.imageUrl);
      setValue("caption", data?.caption);
      setValue("mainText", data?.mainText);
      setValue("active", data?.active);
      setValue("language", data?.language);
      if (data?.images !== null && data?.images !== undefined && data?.images.length > 0) {
        let images =
          data?.images.map((item) => {
            return {
              imageUrl: item,
            };
          }) || [];
        let newImages = [...images, { imageUrl: "" }];
        setValue("fieldImages", newImages);
      } else {
        setValue("fieldImages", [{ imageUrl: "" }]);
      }

      const formattedDate = data?.postedDate.split("T")[0];

      // Set the formatted date value
      setValue("postedDate", formattedDate);

      setValue("textInfos", data?.textInfos);
    } else {
      reset();
    }
  }, [data]);

  useEffect(() => {
    if (open) {
      let newTitle = "Мэдээ";
      newTitle = newTitle + (isEditMode ? " засах" : " нэмэх");
      setTitle(newTitle);
    }
  }, [open]);

  const changeImageUrl = (url) => {
    setValue("imageUrl", url);
  };

  const changeImageUrl2 = (url, id) => {
    const index = id.substring(10);
    setValue(`textInfos[${index}].imageUrl`, url);
  };

  const changeImageUrl3 = (url, id) => {
    const currentIndex = parseInt(id.substring(15), 10);

    let imageCount = 0;
    imageFields.forEach((image, index) => {
      if (index === currentIndex) {
        if (url) imageCount = imageCount + 1;
      } else {
        if (image.imageUrl) imageCount = imageCount + 1;
      }
    });
    setValue(`fieldImages[${currentIndex}].imageUrl`, url);

    if (imageCount === imageFields.length) {
      appendImage({ imageUrl: "" });
    }
  };

  const onSubmit = async (saveData) => {
    try {
      let params = saveData;

      console.log(params);

      params.id = data?.id ?? 0;
      params.newsType = "news";

      let images = params.fieldImages.map((item) => {
        return item.imageUrl;
      });
      params.images = images;
      delete params.fieldImages;
      console.log(params);

      const res = await mainApi("news/admin/save", "POST", params);

      reset();
      handleClose(true);
      toast.success("Амжилттай хадгаллаа.");
    } catch (error) {
      let errorMessage = getErrorMessage(error);
      toast.error("Хадгалах явцад алдаа гарлаа. " + errorMessage);
    }
  };

  const closeModal = () => {
    reset();
    handleClose(false);
  };

  const addTextInfo = () => {
    append({ imageText: "", imageUrl: "" });
  };

  const removeTextInfo = (index) => {
    remove(index);
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          width: "1024px",
          maxWidth: "95vw",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          pl: 6,
          pb: 6,
          pt: 2,
          pr: 6,
        }}
      >
        <Box display="flex" width="100%" pb={2} justifyContent="space-between" alignItems="center">
          <Box display="flex" justifyContent="center" textAlign="center" flexGrow={1}>
            <HeadlineHeadlineMedium color="var(--on-surface-high)">{title}</HeadlineHeadlineMedium>
          </Box>
          <Box>
            <Button onClick={closeModal} sx={{ minWidth: "auto" }}>
              <X size={24} color="#4caf50" />
            </Button>
          </Box>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid2
            container
            spacing={2}
            sx={{
              width: "1024px",
            }}
          >
            <Grid2 xs={12}>
              <Box display="flex" justifyContent="space-between">
                <Box flex="1 1 100%">
                  <BodyBodySmall color="var(--on-surface-high)">Мэдээний зураг</BodyBodySmall>
                </Box>
              </Box>

              {imageFields?.length > 2 ? (
                <Swiper
                  modules={[Navigation]}
                  navigation
                  spaceBetween={20}
                  slidesPerView={1}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                >
                  {imageFields.map((image, index) => (
                    <SwiperSlide key={index}>
                      <div
                        style={{
                          width: "100%",
                          height: "258px",
                          overflow: "hidden",
                          border: "1px solid #ccc",
                          borderRadius: "12px",
                        }}
                      >
                        <ImageUpload
                          id={"imageImageList-" + index}
                          type="cover"
                          suggestText={"Санал болгох зургийн хэмжээ: 487x780"}
                          onChange={changeImageUrl3}
                          imageUrl={image?.imageUrl}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <Grid2 container spacing={2} justifyContent="flex-start">
                  {imageFields.map((image, index) => (
                    <Grid2 key={index} xs="auto">
                      {" "}
                      {/* This makes sure the images stay in one row */}
                      <div
                        style={{
                          width: "100%",
                          height: "258px",
                          overflow: "hidden",
                          border: "1px solid #ccc",
                          borderRadius: "12px",
                        }}
                      >
                        <ImageUpload
                          id={"imageImageList-" + index}
                          type="cover"
                          suggestText={"Санал болгох зургийн хэмжээ: 487x780"}
                          onChange={changeImageUrl3}
                          imageUrl={image?.imageUrl}
                        />
                      </div>
                    </Grid2>
                  ))}
                </Grid2>
              )}
            </Grid2>
          </Grid2>

          <Box display="flex" flexDirection="column" gap={2}>
            <Box>
              <BodyBodySmall color="var(--on-surface-high)">Мэдээний гарчиг</BodyBodySmall>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <TextField
                  {...register("caption", { required: "Мэдээний гарчиг оруулна уу" })}
                  fullWidth
                  InputProps={{ sx: { height: "45px" } }}
                  error={!!errors.caption}
                  helperText={errors.caption?.message}
                />
              </Box>
            </Box>
            <Box>
              <BodyBodySmall color="var(--on-surface-high)">Мэдээний текст</BodyBodySmall>
              <TextField
                {...register("mainText", { required: "Мэдээний текст заавал оруулна уу" })}
                fullWidth
                error={!!errors.mainText}
                helperText={errors.mainText?.message}
                multiline
                rows={3}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 2 }}>
              {/* Checkbox and label (left position) */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Checkbox
                  {...register("active")}
                  checked={watch("active")} // Dynamically reflect the value of `isActive`
                  onChange={(e) => setValue("active", e.target.checked)} // Update form state on change
                />
                <BodyBodySmall color="var(--on-surface-high)">Нийтлэх эсэх</BodyBodySmall>
              </Box>

              {/* Date of News (Мэдээний огноо) */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <BodyBodySmall color="var(--on-surface-high)">Мэдээний огноо</BodyBodySmall>
                <TextField
                  type="date"
                  {...register("postedDate", { required: "Мэдээний огноо оруулна уу" })}
                  error={!!errors.postedDate}
                  helperText={errors.postedDate?.message}
                  sx={{ width: 180 }}
                />
              </Box>

              {/* Language selector and label (right position) */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <BodyBodySmall color="var(--on-surface-high)">Хэл сонгох</BodyBodySmall>
                <TextField
                  select
                  defaultValue="mn"
                  {...register("language", { required: "Хэл сонгоно уу" })}
                  error={!!errors.language}
                  helperText={errors.language?.message}
                  sx={{ width: 120 }}
                  InputProps={{ sx: { height: "45px" } }}
                >
                  <MenuItem value="mn">Монгол</MenuItem>
                  <MenuItem value="en">Англи</MenuItem>
                </TextField>
              </Box>
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} width={"100%"}>
              <TitleTitleMedium color="var(--on-surface-high)">Нэмэлт мэдээлэл</TitleTitleMedium>
              <Button
                variant="contained"
                sx={{
                  "backgroundColor": "#2e7d32",
                  "borderRadius": "24px",
                  "padding": "8px 24px",
                  "textTransform": "none",
                  "fontWeight": "bold",
                  "display": "flex",
                  "alignItems": "center",
                  "&:hover": {
                    backgroundColor: "#1b5e20",
                  },
                }}
                // onClick={() => setOpenMapModal(true)}
                onClick={() => addTextInfo()}
                startIcon={<Plus />}
              >
                Нэмэх
              </Button>
            </Box>

            {fields.map((textInfo, index) => (
              <div key={`step-${index}`}>
                <Box display="flex" width="100%" pb={2} justifyContent="space-between" alignItems="top">
                  <Box display="flex" justifyContent="left" textAlign="top" flexGrow={1}>
                    <ImageUpload
                      id={"imageStep-" + index}
                      type="cover"
                      suggestText={"Санал болгох зургийн хэмжээ: 487x780"}
                      onChange={changeImageUrl2}
                      imageUrl={textInfo?.imageUrl}
                    />
                  </Box>
                  <Box textAlign="top">
                    <Button onClick={() => removeTextInfo(index)} sx={{ minWidth: "auto" }}>
                      <Trash size={24} color="red" />
                    </Button>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Box>
                    <BodyBodySmall color="var(--on-surface-high)">Нэмэлт мэдээлэл</BodyBodySmall>
                    <TextField
                      {...register(`textInfos.${index}.imageText`, {})}
                      fullWidth
                      error={!!errors?.textInfos?.[index]?.textInfo}
                      helperText={errors?.textInfos?.[index]?.textInfo?.message}
                      multiline
                      rows={3}
                    />
                  </Box>
                </Box>
                <hr color="var(--stroke-secondary-soft)" />
              </div>
            ))}

            {/* Submit Button */}
            <Box display="flex" justifyContent="flex-end" width="100%">
              <Button type="submit" variant="contained" color="primary" fullWidth mt={2}>
                Хадгалах
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
};

export default NewsModal;
