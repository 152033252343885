import React, { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Grid2, Paper, TableContainer, Typography } from "@mui/material";
import { Box, Button } from "@mui/material";
import { UserContext } from "../../../context/user";
import { Plus } from "@phosphor-icons/react";
import { ToggleButton } from "../../../components/form/buttons/toggle-button";
import { mainApi } from "../../../api/main";
import ArrowLeftBtn from "../../../components/form/buttons/arrow-left";
import ArrowRightBtn from "../../../components/form/buttons/arrow-right";
import GridCardItemGroup from "../../../components/grid-card-itemGroup";
import { HeadlineHeadlineMedium } from "../../../utils/styleMethod";
import ItemGroupModal from "./detail/itemGroupModal";

const ItemGroupList = () => {
  const { itemGroupId } = useParams();

  const [isLoaded, setIsLoaded] = useState(false);

  const [currentData, setCurrentData] = useState(null);
  const [list, setList] = useState([]);
  const [listTypes, setListTypes] = useState([]);

  const [showAddModal, setShowAddModal] = useState(false);

  const [addData, setAddData] = useState(null);

  useEffect(() => {
    if (!isLoaded) {
      getList();
      setIsLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (itemGroupId) {
      if (list !== undefined) {
        let data = list.find((item) => item.id === parseInt(itemGroupId));
        if (data) {
          setCurrentData(data);
        }
      }
    } else {
      if (list !== undefined) {
        let data = list[0];
        if (data) {
          setCurrentData(data);
        }
      }
    }
  }, [itemGroupId]);

  useEffect(() => {
    getListType();
  }, [currentData]);

  const getListType = async () => {
    try {
      const res = await mainApi("itemGroups/admin/getDetail/types/" + currentData?.id, "GET", null);
      setListTypes(res);
    } catch (error) {
      setListTypes([]);
    }
  };

  const getList = async () => {
    const res = await mainApi("itemGroups/admin/list", "GET", null);
    setList(res);
    if (currentData === null) {
      if (res !== null && res !== undefined && res.length > 0) {
        setCurrentData(res[0]);
      }
    }
  };

  const onClickAdd = () => {
    setAddData({
      id: 0,
      name: "",
      nameEn: "",
      code: "",
      description: "",
      descriptionEn: "",
      imageUrl: "",
      itemsGroupId: currentData?.id,
      steps: [],
    });
    setShowAddModal(true);
  };
  const closeAddModal = () => setShowAddModal(false);

  return (
    <Grid2>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "12px",
          //   flexDirection: isSmallScreen ? "column" : "row",
        }}
      >
        <HeadlineHeadlineMedium color="var(--on-surface-high)">Заавар</HeadlineHeadlineMedium>
        <Button
          variant="contained"
          sx={{
            "backgroundColor": "#2e7d32",
            "borderRadius": "24px",
            "padding": "8px 24px",
            "textTransform": "none",
            "fontWeight": "bold",
            "display": "flex",
            "alignItems": "center",
            "&:hover": {
              backgroundColor: "#1b5e20",
            },
          }}
          startIcon={<Plus />}
          onClick={() => setShowAddModal(true)}
        >
          Ангилах заавар нэмэх
        </Button>
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          padding: "24px 32px 24px 32px",
          marginTop: "24px",
          marginBottom: "24px",
          boxShadow: "none",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "36px",
          }}
        >
          <p className="HeadlineHeadline---Small" style={{ color: "var(--on-surface-high)" }}>
            {currentData?.name}
          </p>
          {/* <Box sx={{ display: "flex", alignItems: "center" }}>
            <ArrowLeftBtn onClick={() => onClickButtonLeft()} />
            <ArrowRightBtn onClick={() => onClickButtonRight()} />
          </Box> */}
        </Box>
        {/* {factoryData ? <CompanyGrid data={factoryData} /> : null} */}
        <GridCardItemGroup data={listTypes} type="type" showAddButton={true} onAddClick={onClickAdd} />
        <ItemGroupModal
          key={"itemGroupModal1"}
          open={showAddModal}
          handleClose={closeAddModal}
          groupType={"ItemGroup"}
          isEditMode={false}
          data={addData}
        />
      </TableContainer>
      <TableContainer
        component={Paper}
        sx={{
          padding: "24px 32px 24px 32px",
          marginTop: "24px",
          marginBottom: "64px",
          boxShadow: "none",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "36px",
          }}
        >
          <p className="HeadlineHeadline---Small" style={{ color: "var(--on-surface-high)" }}>
            Бусад
          </p>
          {/* <Box sx={{ display: "flex", alignItems: "center" }}>
            <ArrowLeftBtn onClick={() => onClickButtonLeft()} />
            <ArrowRightBtn onClick={() => onClickButtonRight()} />
          </Box> */}
        </Box>
        {/* {factoryData ? <CompanyGrid data={factoryData} /> : null} */}
        {list ? <GridCardItemGroup data={list} type="itemGroup" /> : null}
      </TableContainer>
    </Grid2>
  );
};

export default ItemGroupList;
