import React, { useEffect, useState } from "react";
import { Box, Grid2, IconButton, Menu, MenuItem } from "@mui/material";
import { DotsThreeVertical, Pencil, Trash } from "@phosphor-icons/react";
import { useNavigate, useParams } from "react-router-dom";
import { mainApi, getErrorMessage } from "../../../api/main";
import { toast } from "react-toastify";
import { rows } from "../../../utils/method";
import NewsInfo from "./newsInfo";
import { HeadlineHeadlineMedium, BodyBodyMedium } from "../../../utils/styleMethod";

import YesNoDialog from "../../../components/modals/YesNoDialog";

import NewsModal from "./newsModal";

const NewsDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [anchorEl, setAnchorEl] = useState(null);

  const [currentData, setCurrentData] = useState(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [acceptableModalOpen, setAcceptableModalOpen] = useState(false);

  const closeEditModal = (isRefresh) => {
    setShowEditModal(false);
    if (isRefresh) {
      getDetail();
    }
  };

  useEffect(() => {
    if (id) {
      getDetail();
    }
  }, []);

  const getDetail = async () => {
    try {
      const res = await mainApi(`news/admin/detail/${id}`, "GET", null);
      setCurrentData(res);
    } catch (error) {
      toast.warning("Өгөгдөл олдсонгүй");
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const acceptableModalClose = () => {
    setAcceptableModalOpen(false);
    setAnchorEl(false);
  };

  const deleteData = async () => {
    try {
      const res = await mainApi(`news/admin/delete/${currentData?.id}`, "DELETE", null);

      acceptableModalClose();
      toast.success("Амжилттай устгалаа");
      navigate(`/news`);
    } catch (error) {
      let errorMessage = getErrorMessage(error);
      toast.error("Устгах явцад алдаа гарлаа. " + errorMessage);
    }
  };

  return (
    <Grid2>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        <HeadlineHeadlineMedium color="var(--on-surface-high)">Мэдээний дэлгэрэнгүй</HeadlineHeadlineMedium>
        <IconButton
          onClick={handleClick}
          sx={{
            "marginLeft": "auto", // Push IconButton to the right
            "&:hover": { color: "#4CAF50" }, // Change color on hover
          }}
        >
          <DotsThreeVertical size={24} weight="bold" />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        {id ? <NewsInfo data={currentData} /> : "lodading.."}
      </Box>

      <NewsModal
        key={"itemGroupModal1"}
        open={showEditModal}
        handleClose={closeEditModal}
        groupType={"ItemGroup"}
        isEditMode={true}
        data={currentData}
        isNews={true}
      />

      <YesNoDialog
        title={"Та мэдээг устгахдаа итгэлтэй байна уу?"}
        content={""}
        open={acceptableModalOpen}
        handleClose={acceptableModalClose}
        handleConfirm={deleteData}
      />

      {/* Dropdown Menu */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            setShowEditModal(true);
            setAnchorEl(false);
          }}
        >
          {" "}
          <Pencil style={{ marginRight: "12px" }} color="var( --on-surface-high)" size={16} weight="light" />
          <BodyBodyMedium color="var( --on-surface-high)">Засварлах</BodyBodyMedium>
        </MenuItem>
        <MenuItem onClick={() => setAcceptableModalOpen(true)}>
          <Trash style={{ marginRight: "12px" }} color="var( --on-surface-high)" size={16} weight="light" />
          <BodyBodyMedium color="var( --on-surface-high)">Устгах</BodyBodyMedium>
        </MenuItem>
      </Menu>
    </Grid2>
  );
};

export default NewsDetail;
