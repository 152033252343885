import React, { useState } from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import { X } from "@phosphor-icons/react";
import {
    BodyBodyLarge,
    BodyBodySmall,
    LabelLabelLarge,
    TitleTitleLarge,
    TitleTitleMedium,
} from "../../../utils/styleMethod";
import { mainApi } from "../../../api/main";
import SuccessModal from "../../../components/modals/Success";
import { toast } from "react-toastify";
import WarningModal from "../../../components/modals/Warning";

const RequestDetailModal = ({ open, handleClose, data, requestedList }) => {
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [openWarningModal, setOpenWarningModal] = useState(false)

    const onClickApprove = async () => {
        try {
            const res = await mainApi("organization/cancel-confirm", "POST", {
                type: "confirm",
                id: data.organizationId,
            });
            setOpenSuccessModal(true);
            requestedList()
        } catch (error) {
            toast.warning("aldaa")
        }
    };
    const onClickCancel = async () => {
       try {
        const res = await mainApi("organization/cancel-confirm", "POST", {
            type: "cancel",
            id: data.organizationId,
        });
        setOpenWarningModal(true)
        handleClose()
       } catch (error) {
            toast.warning("aldaa")
       }
       
    };
    const closeSuccessModal = () => {
        setOpenSuccessModal(false);
        handleClose()
    };
    return (
        <>
            {data && (
                <Modal open={open} onClose={handleClose}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 550,
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            pl: 4,
                            pr: 4,
                            pb: 4,
                            borderRadius: 2,
                        }}
                    >
                        <Box
                            display="flex"
                            justifyContent="right"
                            alignItems="right"
                            width={"100%"}
                        >
                            <Button
                                onClick={handleClose}
                                sx={{ minWidth: "auto" }}
                            >
                                <X size={24} color="#4caf50" />
                            </Button>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            mb={2}
                            width={"100%"}
                        >
                            <TitleTitleLarge color="var(--on-surface-high)">
                                ААН бүртгүүлэх хүсэлт
                            </TitleTitleLarge>
                        </Box>

                        <Box display="flex" mb={4}>
                            <img
                                src={`${
                                    data.profileImage ||
                                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBcmFc-564fzdVaz-txpjNyohU_YP3S78VAg&s"
                                } `}
                                alt="Recycling"
                                style={{
                                    width: "122px",
                                    height: "auto",
                                    borderRadius: "4px",
                                    marginRight: "16px",
                                }}
                            />

                            <Box display="flex">
                                <TitleTitleMedium color="var(--on-surface-high)">
                                    {data.name}
                                </TitleTitleMedium>
                            </Box>
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <BodyBodySmall color="var(--on-surface-high)">
                                Утас:
                            </BodyBodySmall>
                            <TitleTitleMedium color="var(--on-surface-high)">
                                {data.phoneNumber}
                            </TitleTitleMedium>
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <BodyBodySmall color="var(--on-surface-high)">
                                Хаяг:
                            </BodyBodySmall>
                            <TitleTitleMedium
                                color="var(--on-surface-high)"
                                sx={{ whiteSpace: "pre-wrap" }}
                            >
                                {data.address}
                            </TitleTitleMedium>
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <BodyBodySmall color="var(--on-surface-high)">
                                Орлого хүлээн авах банк:
                            </BodyBodySmall>
                            <TitleTitleMedium color="var(--on-surface-high)">
                                {data.bankName}
                            </TitleTitleMedium>
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <BodyBodySmall color="var(--on-surface-high)">
                                Орлого хүлээн дансны дугаар:
                            </BodyBodySmall>
                            <TitleTitleMedium color="var(--on-surface-high)">
                                {data.bankAccount}
                            </TitleTitleMedium>
                        </Box>

                        <Box
                            display="flex"
                            justifyContent={"space-between"}
                            mb={4}
                        >
                            <Button
                                onClick={() => onClickCancel()}
                                variant="contained"
                                sx={{
                                    backgroundColor: "#C5E7C6",
                                    borderRadius: "8px",
                                    padding: "8px 24px",
                                    textTransform: "none",
                                    fontWeight: "bold",
                                    display: "flex",
                                    alignItems: "center",
                                    "&:hover": {
                                        backgroundColor: "#C5E7C6",
                                    },
                                }}
                            >
                                <LabelLabelLarge color="var(--on-surface-high)">
                                    Татгалзах
                                </LabelLabelLarge>
                            </Button>
                            <Button
                                onClick={() => onClickApprove()}
                                variant="contained"
                                sx={{
                                    backgroundColor: "#2e7d32",
                                    borderRadius: "8px",
                                    padding: "8px 24px",
                                    textTransform: "none",
                                    fontWeight: "bold",
                                    display: "flex",
                                    alignItems: "center",
                                    "&:hover": {
                                        backgroundColor: "#1b5e20",
                                    },
                                }}
                            >
                                <LabelLabelLarge color="white">
                                    Зөвшөөрөх
                                </LabelLabelLarge>
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            )}
            <SuccessModal
                title={``}
                content={`ААН -ийн бүртгэл амжилттай системд бүртгэгдлээ`}
                open={openSuccessModal}
                handleClose={closeSuccessModal}
            />

            <WarningModal
                title={``}
                content={`ААН -ийн бүртгүүлэх хүсэлтыг татгалзлаа`}
                open={openWarningModal}
                handleClose={()=> setOpenWarningModal(false)}
            />
        </>
    );
};

export default RequestDetailModal;
