import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid2 } from "@mui/material";

import { HeadlineHeadlineLarge, TitleTitleLarge, TitleTitleMedium } from "../../../../utils/styleMethod";
import GridCardExam from "../../../../components/grid-card-exam";

export default function ExamInfo({ data }) {
  const [openModal, setOpenModal] = useState(false);

  const [list, setList] = useState([]);

  useEffect(() => {
    if (data) {
      setList([data]);
    }
  }, [data]);

  const handlePlayButtonClick = () => {
    setOpenModal(true); // Open the modal on play button click
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Close the modal
  };

  return (
    <Grid2
      sx={{
        width: "1024px",
      }}
    >
      <Box
        sx={{
          borderRadius: "10px",
          p: "24px",
          backgroundColor: "#FFFFFF",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          mt: 2,
          boxSizing: "border-box",
          // Remove the following styles to align the content to the left
          // margin: "0 auto",
          // maxWidth: "1024px",
        }}
      >
        <Grid container spacing={2}>
          {/* Image Section */}
          <Grid item xs={5}>
            {list && list.length > 0 ? <GridCardExam data={list} type="Exam" noClick={true} /> : null}
          </Grid>

          {/* Content Section */}
          <Grid item xs={7}>
            <TitleTitleMedium>Огноо:</TitleTitleMedium>
            <TitleTitleMedium sx={{ fontWeight: 700, mt: 2, mb: 1 }}>{data?.createdDateStr} </TitleTitleMedium>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start", // Align items to the left
                alignItems: "center", // Vertically align items
                gap: 4, // Space between the sections
                mb: 2, // Add spacing below the row
                marginTop: "24px",
              }}
            >
              <Box>
                <TitleTitleMedium sx={{ mb: 1 }}>Шалгалт өгсөн:</TitleTitleMedium>
                <TitleTitleMedium sx={{ fontWeight: 700 }}>{data?.answerCount ?? 0} хэрэглэгч</TitleTitleMedium>
              </Box>
              <Box>
                <TitleTitleMedium sx={{ mb: 1 }}>Тэнцсэн хэрэглэгч:</TitleTitleMedium>
                <TitleTitleMedium sx={{ fontWeight: 700 }}>{data?.successAnswerCount ?? 0} хэрэглэгч</TitleTitleMedium>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid2>
  );
}
