import React, { useEffect, useState } from "react";
import { Dialog, Box, Button, TextField, MenuItem, Checkbox } from "@mui/material";
import { useForm } from "react-hook-form";
import { X } from "@phosphor-icons/react";

import { BodyBodySmall, HeadlineHeadlineMedium } from "../../../utils/styleMethod";
import ImageUpload from "../../../components/image-upload";
import { mainApi, getErrorMessage } from "../../../api/main";
import { toast } from "react-toastify";

const DonationModal = ({ open, handleClose, isEditMode, data }) => {
  const [bankList, setBankList] = useState([]);

  const {
    control,
    handleSubmit,
    register,
    clearErrors,
    setError,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      nameEn: "",
      description: "",
      descriptionEn: "",
      imageUrl: "",
      bankId: "",
      bankName: "",
      bankAccountNumber: "",
      bankAccountName: "",
      active: false,
    },
  });

  const [title, setTitle] = useState("");

  useEffect(() => {
    if (isEditMode && data !== null && data !== undefined) {
      setValue("name", data?.name);
      setValue("nameEn", data?.nameEn);
      setValue("description", data?.description);
      setValue("descriptionEn", data?.descriptionEn);
      setValue("imageUrl", data?.imageUrl);
      setValue("bankId", data?.bankId);
      setValue("bankName", data?.bankName);
      setValue("bankAccountNumber", data?.bankAccountNumber);
      setValue("bankAccountName", data?.bankAccountName);
      setValue("active", data?.active);
    } else {
      reset();
    }
  }, [data]);

  useEffect(() => {
    if (open) {
      let newTitle = "Хандив";
      newTitle = newTitle + (isEditMode ? " засах" : " нэмэх");
      setTitle(newTitle);

      getBankList();
    }
  }, [open]);

  const getBankList = async () => {
    try {
      const res = await mainApi(`settings/admin/bank/list`, "GET", null);
      setBankList(res.data);
    } catch (error) {
      toast.warning("Өгөгдөл олдсонгүй");
    }
  };

  const changeImageUrl = (url) => {
    setValue("imageUrl", url);
  };

  const onSubmit = async (saveData) => {
    try {
      let bank = bankList.find((item) => item.code === saveData.bankId);
      let params = {
        id: data?.id ?? 0,
        name: saveData.name,
        nameEn: saveData.nameEn,
        description: saveData.description,
        descriptionEn: saveData.descriptionEn,
        imageUrl: saveData.imageUrl,
        bankId: saveData.bankId,
        bankName: bank?.name,
        bankAccountNumber: saveData.bankAccountNumber,
        bankAccountName: saveData.bankAccountName,
        active: saveData.active,
      };

      const res = await mainApi("settings/admin/donation/save", "POST", params);

      reset();
      handleClose(true);
      toast.success("Амжилттай хадгаллаа.");
    } catch (error) {
      let errorMessage = getErrorMessage(error);
      toast.error("Хадгалах явцад алдаа гарлаа. " + errorMessage);
    }
  };

  const closeModal = () => {
    reset();
    handleClose(false);
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          width: "1024px",
          maxWidth: "95vw",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          pl: 6,
          pb: 6,
          pt: 2,
          pr: 6,
        }}
      >
        <Box display="flex" width="100%" pb={2} justifyContent="space-between" alignItems="center">
          <Box display="flex" justifyContent="center" textAlign="center" flexGrow={1}>
            <HeadlineHeadlineMedium color="var(--on-surface-high)">{title}</HeadlineHeadlineMedium>
          </Box>
          <Box>
            <Button onClick={closeModal} sx={{ minWidth: "auto" }}>
              <X size={24} color="#4caf50" />
            </Button>
          </Box>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Box>
              <BodyBodySmall color="var(--on-surface-high)">Хандивын зураг</BodyBodySmall>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <ImageUpload
                  id={"imageUrl"}
                  type="cover"
                  suggestText={"Санал болгох зургийн хэмжээ: 342x209"}
                  onChange={changeImageUrl}
                  imageUrl={watch("imageUrl")}
                />
              </Box>
            </Box>
            <Box>
              <BodyBodySmall color="var(--on-surface-high)">Нэр</BodyBodySmall>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <TextField
                  {...register("name", { required: "Хандивын нэр оруулна уу" })}
                  fullWidth
                  InputProps={{ sx: { height: "45px" } }}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              </Box>
            </Box>
            <Box>
              <BodyBodySmall color="var(--on-surface-high)">Нэр /англи/</BodyBodySmall>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <TextField
                  {...register("nameEn", { required: "Хандивын англи нэр оруулна уу" })}
                  fullWidth
                  InputProps={{ sx: { height: "45px" } }}
                  error={!!errors.nameEn}
                  helperText={errors.nameEn?.message}
                />
              </Box>
            </Box>
            <Box>
              <BodyBodySmall color="var(--on-surface-high)">Тухай</BodyBodySmall>
              <TextField
                {...register("description", { required: "Хандивын тухай мэдээллийг оруулна уу" })}
                fullWidth
                error={!!errors.description}
                helperText={errors.description?.message}
                multiline
                rows={3}
              />
            </Box>
            <Box>
              <BodyBodySmall color="var(--on-surface-high)">Тухай /англи/</BodyBodySmall>
              <TextField
                {...register("descriptionEn", { required: "Хандивын тухай мэдээллийг оруулна уу" })}
                fullWidth
                error={!!errors.descriptionEn}
                helperText={errors.descriptionEn?.message}
                multiline
                rows={3}
              />
            </Box>
            <Box>
              <BodyBodySmall color="var(--on-surface-high)">Хандивын данс</BodyBodySmall>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <BodyBodySmall color="var(--on-surface-high)">Банк</BodyBodySmall>
                <TextField
                  select
                  defaultValue={watch("bankId")}
                  {...register("bankId", { required: "Банк сонгоно уу" })}
                  error={!!errors.bankId}
                  helperText={errors.bankId?.message}
                  sx={{ width: 200 }}
                  InputProps={{ sx: { height: "45px" } }}
                >
                  {bankList.map((bank) => (
                    <MenuItem key={bank.code} value={bank.code}>
                      {bank.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              {/* Date of News (Мэдээний огноо) */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <BodyBodySmall color="var(--on-surface-high)">Дансны дугаар</BodyBodySmall>
                <TextField
                  {...register("bankAccountNumber", { required: "Хандивын дансны дугаар оруулна уу" })}
                  fullWidth
                  InputProps={{ sx: { height: "45px" } }}
                  error={!!errors.bankAccountNumber}
                  helperText={errors.bankAccountNumber?.message}
                />
              </Box>

              {/* Language selector and label (right position) */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <BodyBodySmall color="var(--on-surface-high)">Дансны нэр</BodyBodySmall>
                <TextField
                  {...register("bankAccountName", { required: "Хандивын дансны нэр оруулна уу" })}
                  fullWidth
                  InputProps={{ sx: { height: "45px" } }}
                  error={!!errors.bankAccountName}
                  helperText={errors.bankAccountName?.message}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 2 }}>
              {/* Checkbox and label (left position) */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Checkbox
                  {...register("active")}
                  checked={watch("active")} // Dynamically reflect the value of `isActive`
                  onChange={(e) => setValue("active", e.target.checked)} // Update form state on change
                />
                <BodyBodySmall color="var(--on-surface-high)">Идэвхтэй эсэх</BodyBodySmall>
              </Box>
            </Box>

            {/* Submit Button */}
            <Box display="flex" justifyContent="flex-end" width="100%">
              <Button type="submit" variant="contained" color="primary" fullWidth mt={2}>
                Хадгалах
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
};

export default DonationModal;
