

export const ToggleButton = ({ label, onClickButton, textColor, bgColor, selectedStatus }) => {
  return (
    <button
      className="LabelLabel---Small"
      key={label}
      variant={selectedStatus === label ? "contained" : "outlined"}
      onClick={() => onClickButton(label)}
      style={{
        "color": selectedStatus === label ? "#fff" : "var(--primary-soft-on-primary-soft-h)",
        "backgroundColor": selectedStatus === label ? textColor : bgColor,
        "borderRadius": "24px",
        "border": "0",
        "padding": "4px 16px",
        "textTransform": "none",
        "minWidth": "120px",
        "height": "36px",
        "&:hover": {
          backgroundColor: selectedStatus === label ? "#1b5e20" : bgColor,
        },
      }}
    >
      {label}
    </button>
  );
};