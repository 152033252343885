import { TableContainer, Paper } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import { PencilLine } from "@phosphor-icons/react";
import "./styles.css";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import { useForm } from "react-hook-form";
import { BodyBodySmall, TitleTitleMedium } from "../../../utils/styleMethod";
import { mainApi } from "../../../api/main";
import ContactDetail from "../../../pages/help/detail/contactDetail";

export const ContactInfo = ({}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [contactMN, setContactMN] = useState(null);
  const [contactEN, setContactEN] = useState(null);
  const [data, setData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const getList = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      const res = await mainApi(`settings/mobile/contactInfo/mn`, "GET", null);
      setContactMN(res.data);

      const resEN = await mainApi(`settings/mobile/contactInfo/en`, "GET", null);
      setContactEN(resEN.data);

      setData({
        mn: res.data,
        en: resEN.data,
      });

      setIsLoading(false);
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    if (!isLoaded) getList();
    setIsLoaded(true);
  }, [isLoaded]);

  const closeEditModal = (isRefresh) => {
    setShowEditModal(false);
    if (isRefresh) {
      getList();
    }
  };

  const openEditModal = () => {
    setShowEditModal(true);
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        padding: "24px 32px 24px 32px",
        marginTop: "24px",
        marginBottom: "64px",
        boxShadow: "none",
        borderRadius: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "36px",
        }}
      >
        <p className="HeadlineHeadline---Small" style={{ color: "var(--on-surface-high)" }}>
          Холбоо барих
        </p>
        <button
          className="edit-button"
          onClick={(e) => {
            openEditModal();
          }}
        >
          <PencilLine size={24} color="var(--primary-soft-primary-soft-h)" />
        </button>
      </Box>
      <Box>
        <Box display="flex" alignItems="center">
          <div
            style={{
              display: "flex",
              alignItems: "left",
              textAlign: "lef",
              justifyContent: "left",
              height: "40px",
              marginBottom: "40px",
            }}
          >
            <img src="/images/logo.svg" alt="logo" height={"50px"} style={{ paddingLeft: "24px" }} />
          </div>
        </Box>
      </Box>
      <Box>
        <Box display="flex" alignItems="center">
          {/* Left Section */}
          <Box>
            <BodyBodySmall color="var(--on-surface-high)" sx={{ mb: 2 }}>
              Утас:
            </BodyBodySmall>
            <TitleTitleMedium color="var(--on-surface-high)" sx={{ mb: 2 }}>
              {contactMN?.phoneNumber ?? "-"}
            </TitleTitleMedium>
          </Box>
          {/* Right Section */}
          <Box sx={{ marginLeft: 15 }}>
            <BodyBodySmall color="var(--on-surface-high)" sx={{ mb: 2 }}>
              Имэйл:
            </BodyBodySmall>
            <TitleTitleMedium color="var(--on-surface-high)" sx={{ mb: 2 }}>
              {contactMN?.email ?? "-"}
            </TitleTitleMedium>
          </Box>
          <Box sx={{ marginLeft: 15 }}>
            <BodyBodySmall color="var(--on-surface-high)" sx={{ mb: 2 }}>
              Хаяг:
            </BodyBodySmall>
            <TitleTitleMedium color="var(--on-surface-high)" sx={{ mb: 2 }}>
              {contactMN?.address ?? "-"}
            </TitleTitleMedium>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box display="flex" alignItems="center">
          {/* Left Section */}
          <Box>
            <BodyBodySmall color="var(--on-surface-high)" sx={{ mb: 2 }}>
              Phone:
            </BodyBodySmall>
            <TitleTitleMedium color="var(--on-surface-high)" sx={{ mb: 2 }}>
              {contactEN?.phoneNumber ?? "-"}
            </TitleTitleMedium>
          </Box>
          {/* Right Section */}
          <Box sx={{ marginLeft: 15 }}>
            <BodyBodySmall color="var(--on-surface-high)" sx={{ mb: 2 }}>
              Email:
            </BodyBodySmall>
            <TitleTitleMedium color="var(--on-surface-high)" sx={{ mb: 2 }}>
              {contactEN?.email ?? "-"}
            </TitleTitleMedium>
          </Box>
          <Box sx={{ marginLeft: 15 }}>
            <BodyBodySmall color="var(--on-surface-high)" sx={{ mb: 2 }}>
              Address:
            </BodyBodySmall>
            <TitleTitleMedium color="var(--on-surface-high)" sx={{ mb: 2 }}>
              {contactEN?.address ?? "-"}
            </TitleTitleMedium>
          </Box>
        </Box>
      </Box>

      <ContactDetail open={showEditModal} handleClose={closeEditModal} data={data}></ContactDetail>
    </TableContainer>
  );
};
