import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  IconButton,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { X } from "@phosphor-icons/react";
import { PDFViewer } from "@react-pdf/renderer";
import { CertificatePdf } from "../factory/certificate-pdf";
import { HeadlineHeadlineMedium } from "../../utils/styleMethod";

const PdfModal = ({ open, handleClose , data}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [open]);



  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 900,
          height: 840,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <HeadlineHeadlineMedium color="var(--on-surface-high)">
            Дахивар тушаасан батламж
          </HeadlineHeadlineMedium>
          <IconButton onClick={handleClose}>
            <X size={24} color="green" />
          </IconButton>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          {loading ? (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <CircularProgress />
              <Typography variant="body2" sx={{ marginTop: 1 }}>
                {" "}
                
                уншиж байна ...
              </Typography>
            </Box>
          ) : (
            <PDFViewer style={{ width: "100%", height: "94%" }}>
              <CertificatePdf
                data={data}
              />
            </PDFViewer>
          )}
        </Box>

        <Box display="flex" justifyContent="center" alignItems="center">
          <Button>Илгээх</Button>
          <Button>Татах</Button>
        </Box>

      
      </Box>
    </Modal>
  );
};

export default PdfModal;
