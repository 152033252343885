import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Box,
    useMediaQuery,
    Pagination,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { timeIntervals, callFilter, getColorByStatus } from "../../../utils/method";
import { FormInputDropdown } from "../../form/form-input-dropdown";
import { StatusSpan } from "../../form/span";
import { mainApi } from "../../../api/main";
import {
    tableBodyCellStyle,
    tableHeaderStyle,
} from "../../../utils/styleMethod";

const buttonStyles = {
    color: "#2e7d32", // Green text color
    borderColor: "#2e7d32", // Green border
    borderRadius: "24px", // Rounded corners for pill shape
    padding: "4px 12px", // Padding to make the button wider
    textTransform: "none", // Disable uppercase transformation
    minWidth: "150px", // Set a consistent width
    fontWeight: "bold", // Bold text
    display: "inline-block", // Align the buttons in a row
};

const initQuery = {
    page: 1,
    limit: 8,
    sort: "desc",
    period: "week",
    total: 0,
};
function OrderTable() {
    const { control } = useForm({});
    const isSmallScreen = useMediaQuery("(max-width: 600px)");
    const isMediumScreen = useMediaQuery("(max-width: 960px)");
    const [rows, setRows] = useState([]);
    const [query, setQuery] = useState(initQuery);

    const classessCell = tableBodyCellStyle();
    const classessHeader = tableHeaderStyle();

    useEffect(() => {
        getCalls();
    }, [query]);

    const getCalls = async () => {
        try {
            const res = await mainApi("call/all-status-list", "POST", query);
            setRows(res.content);

            setQuery((prevQuery) => {
                if (prevQuery.total !== res.totalElements) {
                    return { ...prevQuery, total: res.totalElements };
                }
                return prevQuery;
            });
        } catch (error) {}
    };

    const onOrder = (values) => {
        setQuery({ ...query, sort: values });
    };

    const onPeriod = (values) => {
        setQuery({ ...query, period: values });
    };
    const handleChangePage = (event, newPage) => {
        setQuery({ ...query, page: newPage });
    };
    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                padding: "16px 24px 16px 32px",
                marginTop: isSmallScreen ? "16px" : "24px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                width: isSmallScreen
                    ? "350px"
                    : isMediumScreen
                    ? "700px"
                    : "100%",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: isSmallScreen ? "column" : "row",
                }}
            >
                <p
                    className="HeadlineHeadline---Small"
                    style={{
                        color: "var(--on-surface-high)",
                        paddingTop: "8px",
                    }}
                >
                    Дуудлагын дэлгэрэнгүй
                </p>

                <Box>
                    <FormInputDropdown
                        control={control}
                        name={`callFilter`}
                        options={callFilter}
                        sx={{ minWidth: "250px", marginRight: "12px" }}
                        onChange={(selectedValue) => onOrder(selectedValue)}
                    />
                    <FormInputDropdown
                        control={control}
                        name={`timeIntervals`}
                        options={timeIntervals}
                        sx={{ minWidth: "116px", marginRight: "12px" }}
                        onChange={(selectedValue) => onPeriod(selectedValue)}
                    />
                </Box>
            </Box>
            <p className="LabelLabel---Small" style={{ color: "#368137" }}>
                Бүх төлөв
            </p>
            <TableContainer
                component={Paper}
                sx={{
                    paddingTop: "24px",
                    marginTop: "24px",
                    marginBottom: "64px",
                    boxShadow: "none",
                    borderRadius: "12px",
                }}
            >
                <Table aria-label="order table">
                    <TableHead>
                        <TableRow
                            sx={{ backgroundColor: "#f5f5f5" }}
                            className={classessHeader.tableHeader}
                        >
                            {[
                                "Ачигдах огноо",
                                "Дахивар",
                                "Ачилтын хаяг",
                                "Хүргэх үйлдвэр",
                                "Жолооч",
                                "Төлөв",
                            ].map((header) => (
                                <TableCell
                                    key={header}
                                    className={classessCell.tableBodyCell}
                                >
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{
                                    "&:nth-of-type(odd)": {
                                        backgroundColor: "transparent",
                                    },
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell
                                    className={classessCell.tableBodyCell}
                                >
                                    {row.createdDate}
                                </TableCell>
                                <TableCell
                                    className={classessCell.tableBodyCell}
                                >
                                    <Button
                                        variant="outlined"
                                        sx={buttonStyles}
                                    >
                                        {row.itemsGroupName}
                                    </Button>
                                </TableCell>
                                <TableCell
                                    className={classessCell.tableBodyCell}
                                >
                                    {row.address}
                                </TableCell>
                                <TableCell
                                    className={classessCell.tableBodyCell}
                                >
                                    {row.factoryName}
                                </TableCell>
                                <TableCell
                                    className={classessCell.tableBodyCell}
                                >
                                    {row.driverCode}
                                </TableCell>
                                <TableCell
                                    className={classessCell.tableBodyCell}
                                >
                                    <StatusSpan
                                        label={row.status}
                                        statusColor={getColorByStatus(row.status) }
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* Custom Pagination */}
            <Box display="flex" justifyContent="center" mt={2}>
                <Pagination
                    count={Math.ceil(query.total / query.limit)}
                    onChange={handleChangePage}
                    siblingCount={1}
                    boundaryCount={1}
                />
            </Box>
        </Box>
    );
}

export default OrderTable;
