import * as pdfjsLib from "pdfjs-dist";
import { pdf } from "@react-pdf/renderer";
import { mainApi } from "../../../api/main";

// Set workerSrc to local or CDN
// pdfjsLib.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.mjs";   // for local
pdfjsLib.GlobalWorkerOptions.workerSrc = "https://replus.mn/pdf.worker.min.mjs?v=1.0.0";


const PdfToJpgConverter = async (PdfDocument, certificateId) => {
    
    try {
        const blob = await pdf(PdfDocument).toBlob();

        const pdfData = new Uint8Array(await blob.arrayBuffer());

        const pdfDocument = await pdfjsLib.getDocument({ data: pdfData })
            .promise;
        console.log("PDF Document Loaded:", pdfDocument);

        const page = await pdfDocument.getPage(1);
        const viewport = page.getViewport({ scale: 2 });

        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        await page.render({
            canvasContext: context,
            viewport: viewport,
        }).promise;



        const jpgData = canvas.toDataURL("image/jpeg", 1.0);

        await handleSaveImage(jpgData, certificateId);
    } catch (error) {
        console.error("Error converting PDF to JPG:", error);
    }
};

const handleSaveImage = async (jpgData, id) => {

    try {
        const base64Image = jpgData.split(",")[1];
        const response = await mainApi("image/upload/base64", "POST", {
            base64: base64Image,
            imageType: "certificate",
        });

        const res = await mainApi(
            `certificate/update`,
            "PUT",
            {id: id , url: response.data}
        );

    } catch (error) {
        console.error("Error uploading image:", error);
    }
};

export default PdfToJpgConverter;
