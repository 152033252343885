import axios from "axios";
import Cookies from "js-cookie";

export const getMainUrl = () => {
  return `https://replus.mn/ecosolution`;
  //return `http://192.168.1.4:8383/ecosolution`;
  // return `http://localhost:8383/ecosolution`;
};

export async function mainApi(path, method = "GET", data = null) {
  const user = Cookies.get("user");
  if (user) {
    const { token } = JSON.parse(user);

    const options = {
      url: `${getMainUrl()}/${path}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      method,
      withCredentials: false,
    };
    options.headers["accept-language"] = "mn";
    if (method === "GET" && data) {
      // Append query parameters for GET request
      const queryParams = new URLSearchParams(data).toString();
      options.url += `?${queryParams}`;
    } else if (data) {
      options.data = data;
      if (!(data instanceof FormData)) {
        options.headers["Content-type"] = "application/json";
      }
    }
    const res = await axios(options).catch((err) => {
      throw err;
    });
    return res.data;
  }
}

export async function mainWithOutToken(path, method = "GET", data = null) {
  const options = {
    url: `${getMainUrl()}/${path}`,
    headers: {
      "Content-type": "application/json",
      "Accept": "application/json",
    },
    method,
    withCredentials: false,
  };
  if (data) {
    options.data = data;
  }

  const res = await axios(options).catch((err) => {
    console.log(err);
    throw err;
  });
  return res.data;
}

export async function login(username, password) {
  const { data } = await axios.post(
    getMainUrl() + "/auth/login",
    {
      username: username,
      password: password,
    },
    {
      headers: {
        "content-type": "application/json",
        "Accept-Language": "mn",
      },
    }
  );

  return data;
}

export const getErrorMessage = (error) => {
  let errorMessage = error.message;
  if (axios.isAxiosError(error)) {
    if (error.response) {
      errorMessage = error.response?.data?.message;
    } else if (error.request) {
      errorMessage = "No response received";
    }
  }
  return errorMessage;
};
