import React, { useEffect, useState } from "react";
import { Modal, Box, Button, TextField, MenuItem } from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { X, UserCircle, User } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BodyBodyLarge,
  BodyBodyMedium,
  BodyBodySmall,
  DisplayMedium,
  DisplaySmall,
  HeadlineHeadlineSmall,
  TitleTitleLarge,
  TitleTitleMedium,
} from "../../../utils/styleMethod";
import { mainApi, getErrorMessage } from "../../../api/main";
import { getColorByFeedbackStatus } from "../../../utils/method";
import { FeedbackStatusSpan } from "../../../components/form/span/FeedbackStatusSpan";

const FaqDetail = ({ open, handleClose, data, isEditMode }) => {
  const navigate = useNavigate();
  const [feedbackTypes, setFeedbackTypes] = useState([]);

  const {
    control,
    handleSubmit,
    register,
    clearErrors,
    setError,
    reset,
    watch,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      question: "",
      answer: "",
      language: "mn",
      viewOrder: 1,
    },
  });

  useEffect(() => {
    debugger;
    if (isEditMode && data !== null && data !== undefined) {
      setValue("question", data?.question);
      setValue("answer", data?.answer);
      setValue("viewOrder", data?.viewOrder);
      setValue("language", data?.language);
    } else {
      reset();
    }
  }, [data]);

  const handleValidation = async () => {
    try {
      const values = getValues();

      const isValid = await trigger();

      if (!isValid) {
        toast.warning("Мэдээллээ бүрэн зөв бөглөн үү.");
        return;
      }
      debugger;

      let saveData = {
        id: data?.id ?? 0,
        question: values.question,
        answer: values.answer,
        groupText: "",
        viewOrder: values.viewOrder,
        language: values.language,
        isDeleted: false,
      };

      let params = [saveData];
      const res = await mainApi("settings/admin/save/faq", "POST", params);

      reset();
      handleClose(true);
    } catch (error) {
      let errorMessage = getErrorMessage(error);
      toast.error("Хадгалах явцад алдаа гарлаа. " + errorMessage);
    }
  };

  return (
    <>
      {data && (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Box display="flex" justifyContent="center" alignItems="center" mb={2} width={"100%"}>
              <TitleTitleLarge color="var(--on-surface-high)">Түгээмэл асуулт хариулт</TitleTitleLarge>
              <Button onClick={handleClose} sx={{ position: "absolute", right: "16px", minWidth: "auto" }}>
                <X size={24} color="#4caf50" />
              </Button>
            </Box>

            <Box display="flex" flexDirection="column" gap={2}>
              <Box>
                <BodyBodySmall color="var(--on-surface-high)">Асуулт</BodyBodySmall>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <TextField
                    {...register("question", { required: "Асуулт оруулна уу" })}
                    fullWidth
                    InputProps={{ sx: { height: "45px" } }}
                    error={!!errors.question}
                    helperText={errors.question?.message}
                  />
                </Box>
              </Box>
              <Box>
                <BodyBodySmall color="var(--on-surface-high)">Хариулт</BodyBodySmall>
                <TextField
                  {...register("answer", { required: "Хариултыг заавал оруулна уу" })}
                  fullWidth
                  error={!!errors?.answer}
                  helperText={errors?.answer?.message}
                  multiline
                  rows={3}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 2 }}>
              {/* Date of News (Мэдээний огноо) */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <BodyBodySmall color="var(--on-surface-high)">Дараалал</BodyBodySmall>
                <TextField
                  type="number"
                  {...register("viewOrder", { required: "Дарааллыг оруулна уу" })}
                  error={!!errors.viewOrder}
                  helperText={errors.viewOrder?.message}
                  sx={{ width: 120 }}
                />
              </Box>

              {/* Language selector and label (right position) */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <BodyBodySmall color="var(--on-surface-high)">Хэл сонгох</BodyBodySmall>
                <TextField
                  select
                  {...register("language", { required: "Хэл сонгоно уу" })}
                  error={!!errors.language}
                  helperText={errors.language?.message}
                  sx={{ width: 120 }}
                  InputProps={{ sx: { height: "45px" } }}
                  value={watch("language")}
                >
                  <MenuItem value="mn">Монгол</MenuItem>
                  <MenuItem value="en">Англи</MenuItem>
                </TextField>
              </Box>
            </Box>

            {/* Centered Button */}
            <Box display="flex" justifyContent="center" mt={4}>
              <Button
                variant="contained"
                sx={{
                  "backgroundColor": "#2e7d32",
                  "borderRadius": "24px",
                  "padding": "8px 24px",
                  "textTransform": "none",
                  "fontWeight": "bold",
                  "display": "flex",
                  "alignItems": "center",
                  "&:hover": {
                    backgroundColor: "#1b5e20",
                  },
                }}
                // onClick={() => setOpenMapModal(true)}
                onClick={handleValidation}
                // startIcon={<Plus />}
              >
                Хадгалах
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default FaqDetail;
